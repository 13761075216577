import { AxiosInstance } from 'axios';

export class RevalidationService {
  constructor(
    private axios: AxiosInstance,
  ) {
  }

  revalidate(paths: string) {
    const url = `${process.env.REACT_APP_API_URL!.replace("/api", "")}/revalidate-paths`
    return fetch(url, {
      method: 'POST',
      body: paths
    });
  }
}
