import React, { FunctionComponent, useState, useEffect } from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import { UserProfileNavItemProps } from "./UserProfile";

import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import ListContentLoader from "../../components/ListContentLoader";
import { User } from "../../domain/User";

import UserProfileItemLink from "./UserProfileItemLink";

const UserFollowers: FunctionComponent<UserProfileNavItemProps> = ({ userId, updateTotalCount, fixedScrollableContentHeight }) => {
  const usersPerPage = 20;

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [followers, setFollowers] = useState<User[]>([]);

  const [fetchFollows, { isLoading: isFetchingFollows }] = useApiService('user', userService => (
    (page) => (
      userService.fetchUserFollowers(userId, page, usersPerPage).then(followersPage => {
        const { data, pageCount, total } = followersPage;

        setFollowers([...followers, ...data]);
        setPageCount(pageCount);

        updateTotalCount(total);
      })
    )
  ));

  const fetchNextPage = () => setPage(page + 1);

  useEffect(() => {
    page && fetchFollows(page);
  // eslint-disable-next-line
  }, [page]);

  useEffectOnMount(fetchNextPage);

  const loader = <ListContentLoader />;

  return (
    <InfiniteScroll
      next={fetchNextPage}
      hasMore={page < pageCount}
      dataLength={followers.length}
      loader={loader}
      endMessage={isFetchingFollows ? loader : (!followers.length && <p className="text-center">No users found</p>)}
      height={fixedScrollableContentHeight}
    >
      {followers.map(follow => (
        <UserProfileItemLink
          key={follow.id}
          link={`/users/update/${follow.id}`}
          label={follow.display_name}
          image={follow.avatar}
        />
      ))}
    </InfiniteScroll>
  );
};

export default UserFollowers;
