import React, { FunctionComponent, useState } from 'react';

import Select, { InputActionMeta } from 'react-select';

import classNames from 'classnames';
import debounce from 'lodash.debounce';

import { SelectOption } from '../../services/api/responses';

import { useApiService } from '../../hooks/useApiService';
import { PagedRequestFilterCombination } from '../../services/api/requests';
// import { PagedRequestSearch, PagedRequestSimpleFilter, PagedRequestFilterCombination } from "../../services/api/requests";
import { App } from '../../domain/App';
import { SortableMultiSelect } from '../../components/SortableMultiSelect';

type AppsSelectProps = {
  className: string;
  value: SelectOption[];
  onChange: any;
  isDisabled: boolean;
  onlyShowAppsWithFeaturedIcon?: boolean;
  isSortable?: boolean
};

const AppsSelect: FunctionComponent<AppsSelectProps> = ({
  value,
  onChange,
  className,
  isDisabled,
  onlyShowAppsWithFeaturedIcon,
  isSortable
}) => {
  const [appsOptions, setAppsOptions] = useState<SelectOption[]>([]);

  const [fetchApps, { isLoading: isFetchingApps }] = useApiService('app', appService => (
    (name: string) => {
      if (!name) return new Promise(resolve => resolve());

      const searchByName: PagedRequestFilterCombination<App> = {
        operator: 'or',
        filters: [
          { field: 'name', operator: 'like', value: name },
          { field: 'slug', operator: 'like', value: name },
        ],
      };

      // TODO: go back to ternary below when backend fix on search
      // const searchOnlyWithFeaturedIcon: PagedRequestSimpleFilter<App> = {
      //   field: 'featuredIcon', operator: 'not null',
      // };

      // const search: PagedRequestSearch<App> = onlyShowAppsWithFeaturedIcon
      //   ? { operator: 'and', filters: [searchByName, searchOnlyWithFeaturedIcon] }
      //   : searchByName;
      const search = searchByName;

      return appService.fetchAppsPage({ page: 1, limit: 20, search }).then(appsPage => {
        const apps = appsPage.data;
        // TODO: remove this when API endpoint /app/status returns more specific information
        setAppsOptions(apps.map(a => ({
          label: `${a.name}${a.status !== 'Published' ? ` (${a.status})` : ''}`,
          value: a.id
        })));
      });
    }
  ));

  const onAppInputChanged = debounce((inputValue: string, actionMeta: InputActionMeta) => {
    setAppsOptions([]);
    if (actionMeta.action === 'input-change') {
      fetchApps(inputValue);
    }
  }, 300);

  if (isSortable) {
    return (
      <SortableMultiSelect
        onChange={onChange}
        isLoading={isFetchingApps}
        isDisabled={isDisabled}
        options={appsOptions as any}
        value={value}
        className={classNames('react-select-container', className)}
        onInputChange={onAppInputChanged}
      />
    );
  }

  return (
    <Select
      isMulti
      options={appsOptions as any}
      isLoading={isFetchingApps}
      placeholder="Type to search apps..."
      onInputChange={onAppInputChanged}
      className={classNames('react-select-container', className)}
      classNamePrefix="react-select"
      isDisabled={isDisabled}
      value={value}
      onChange={onChange}
    />
  );
};

export default AppsSelect;
