import { AxiosInstance } from "axios";
import JwtDecode from "jwt-decode";
import { User } from "../../../domain/User";
import { createRequestQuery, PagedRequestJoinOption } from "../requests";
import { SignInRequestDTO, SignUpRequestDTO, RequestNewPasswordDTO, ResetPasswordDTO } from "./authRequestTypes";
import { SignInResponseDTO, SignUpResponseDTO } from "./authResponseTypes";

type AccessTokenClaims = {
  id: number,
  email: string,
  username: string,
};

export class AuthService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  signIn(req: SignInRequestDTO) {
    return this.axios.post<SignInResponseDTO>('/auth/login', req).then(res => res.data).then(async (response) => {
      // TODO: remove this when api is able to differentiate between cms and webapp requests
      const decodedJwt = JwtDecode<AccessTokenClaims>(response.access_token);
      const user = await this.fetchUserById(decodedJwt.id, response.access_token)

      if (user.role?.name !== 'ADMIN') {
        // eslint-disable-next-line no-throw-literal
        throw { response: { status: 401 } }
      }

      return response
    });
  }

  signUp(req: SignUpRequestDTO) {
    return this.axios.post<SignUpResponseDTO>('/auth/signUp', req).then(res => res.data);
  }

  requestNewPassword(req: RequestNewPasswordDTO) {
    return this.axios.post('/auth/recovery', { ...req, adminRedirect: true });
  }

  resetPassword({ password, repeat_password, token }: ResetPasswordDTO) {
    return this.axios.patch('/user/password', { password, repeat_password }, { headers: { Authorization: `Bearer ${token}` } });
  }

  fetchUserById(userId: number, access_token: string) {
    const fields: (keyof User)[] = [
      'id',
      'first_name',
      'last_name',
      'username',
      'email',
      'description',
    ];

    const join: PagedRequestJoinOption<User>[] = [{ relation: 'role' }];

    const requestQuery = createRequestQuery({ fields, join });

    return this.axios.get<User>(`/user/${userId}?${requestQuery}`, { headers: { Authorization: `Bearer ${access_token}` } }).then(res => res.data);
  }
}
