export enum ImportLogType {
  USER_BULK = 'User Bulk',
  USER_SEARCH_SYNC = 'User Search Sync',
  APP_SEARCH_SYNC = 'App Search Sync',
}

export enum ImportLogStatus {
  RUNNING = 'Running',
  FINISHED = 'Finished',
  ERROR = 'With Error',
}

export type ImportLog = {
  id: number;
  type: ImportLogType;
  status: ImportLogStatus;
  message: string;
  created_at: Date;
  updated_at: Date;
};
