import {
  Icon,
  Home as HomeIcon,
  User as UserIcon,
  Package as PackageIcon,
  Hash as HashIcon,
  Tag as TagIcon,
  Award as AwardIcon,
  List as ListIcon,
  FileText as FileTextIcon,
  Upload as UploadIcon,
  BarChart2 as CampaignIcon,
  Download as DownloadIcon,
  Mail as NewsletterIcon
} from "react-feather";

type SidebarItem = {
  name: string;
  icon: Icon;
  path: string;
  children?: SidebarItem[];
};

const sidebarConfig: SidebarItem[] = [
  {
    name: 'Home',
    icon: HomeIcon,
    path: '/',
  },
  {
    name: 'User management',
    icon: UserIcon,
    path: '/users',
  },
  {
    name: 'Apps',
    icon: PackageIcon,
    path: '/apps',
    children: [
      {
        name: 'App management',
        icon: PackageIcon,
        path: '/apps',
      },
      {
        name: 'Category management',
        icon: HashIcon,
        path: '/apps/categories',
      },
      {
        name: 'Tag management',
        icon: TagIcon,
        path: '/apps/tags',
      },
      {
        name: 'Home featured apps',
        icon: AwardIcon,
        path: '/apps/homepage-featured',
      },
      {
        name: 'My apps featured apps',
        icon: ListIcon,
        path: '/apps/myapps-featured',
      },
    ],
  },
  {
    name: 'Campaigns',
    icon: CampaignIcon,
    path: '/campaigns',
  },
  {
    name: 'Newsletter',
    icon: NewsletterIcon,
    path: '/newsletter'
  },
  {
    name: 'Custom page management',
    icon: FileTextIcon,
    path: '/custom-page',
  },
  {
    name: 'Export data',
    icon: DownloadIcon,
    path: '/export-data',
  },
  {
    name: 'Import data',
    icon: UploadIcon,
    path: '/import-data',
  },
];

export default sidebarConfig;
