import { RootState } from "../types";

export const selectUser = (state: RootState) => state.user;
export const selectIsUserAuthenticated = (state: RootState) => state.user.isAuthenticated;
export const selectAccessToken = (state: RootState) => state.user.accessToken;
export const selectUserId = (state: RootState) => state.user.id;

export const selectSidebar = (state: RootState) => state.sidebar;

export const selectListingPageTableState = (key: string) => (state: RootState) => state.listingPageTableStates[key];
