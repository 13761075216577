import { SidebarState } from "../types";
import { SidebarActionTypes, SidebarActions } from "../actions/sidebarActions";

const initialState: SidebarState = {
  isOpen: true,
};

export default function reducer(state = initialState, action: SidebarActions): SidebarState {
  switch (action.type) {
    case SidebarActionTypes.SIDEBAR_VISIBILITY_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      };

    default:
      return state;
  }
}
