// action types
export enum ListingPageTableStatesActionTypes {
  UPDATE_TABLE_STATE = 'UPDATE_TABLE_STATE',
  CLEAR_OTHER_TABLE_STATES = 'CLEAR_OTHER_TABLE_STATES',
}

// actions
export type UpdateListingPageTableStateAction<T> = { type: typeof ListingPageTableStatesActionTypes.UPDATE_TABLE_STATE, payload: { key: string, tableState: T } };
export type ClearOtherListingPageTableStateAction = { type: typeof ListingPageTableStatesActionTypes.CLEAR_OTHER_TABLE_STATES, payload: { key: string } };

export type ListingPageTableStateActions = UpdateListingPageTableStateAction<any> | ClearOtherListingPageTableStateAction;

// action creators
export const updateListingPageTableState = <T>(key: string, tableState: T) => ({ type: ListingPageTableStatesActionTypes.UPDATE_TABLE_STATE, payload: { key, tableState } });
export const clearOtherListingPageTableStates = (key: string) => ({ type: ListingPageTableStatesActionTypes.CLEAR_OTHER_TABLE_STATES, payload: { key } });
