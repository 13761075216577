import { AxiosInstance } from "axios";
import { PagedRequestDTO, createRequestQuery } from "../requests";
import { ImportLog } from "../../../domain/ImportLog";
import { PagedResponseDTO } from "../responses";

export class ImportLogService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchImportLogsPage(pagedRequestDTO: PagedRequestDTO<ImportLog>) {
    const fields: (keyof ImportLog)[] = [
      'id',
      'type',
      'status',
      'created_at',
      'updated_at',
    ];

    if (!pagedRequestDTO.sortOptions) {
      pagedRequestDTO.sortOptions = [{ field: 'updated_at', order: 'desc' }];
    }

    const requestQuery = createRequestQuery({ ...pagedRequestDTO, fields });

    return this.axios.get<PagedResponseDTO<ImportLog>>(`/import-logs?${requestQuery}`).then(res => res.data);
  }

  fetchImportLogById(id: number) {
    const fields: (keyof ImportLog)[] = [
      'id',
      'type',
      'status',
      'message',
      'created_at',
      'updated_at',
    ];

    const requestQuery = createRequestQuery({ fields });

    return this.axios.get<ImportLog>(`/import-logs/${id}?${requestQuery}`).then(res => res.data);
  }

}
