import { AxiosInstance } from 'axios';

type NewsletterStatusResponse = boolean | { sent: number, total: number }
export type StartNewsletterCampaignRequest = Partial<{
  subject: string,
  template: string,
  emails: string[]
}>

export class NewsletterService {
  constructor(private axios: AxiosInstance) {}

  getNewsletterStatus() {
    return this.axios.get<NewsletterStatusResponse>('/newsletter/campaign')
  }

  startNewsletterCampaign(newsletterData: StartNewsletterCampaignRequest) {
    return this.axios.post('/newsletter', newsletterData)
  }
}
