import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

type ModalProps = {
  title: string;
  modalBody: (data: any) => string | JSX.Element;
  onConfirm?: (data: any) => any;
  onCancel?: (data: any) => any;
  onEnter?: (data: any) => any;
  onExit?: (data: any) => any;
  onOpened?: (data: any) => any;
  onClosed?: (data: any) => any;
} & Partial<Readonly<typeof defaultProps>>;

const defaultProps = {
  confirmButtonColor: 'primary' as string,
  confirmButtonLabel: 'Confirm' as string,
  confirmButtonDisabled: false as boolean,

  cancelButtonColor: 'light' as string,
  cancelButtonLabel: 'Cancel' as string,
  cancelButtonDisabled: false as boolean,
};

type ModalState = {
  isVisible: boolean;
  data: any;
}

class ConfirmationModal extends React.Component<ModalProps, ModalState> {
  static defaultProps = defaultProps;

  constructor(props: ModalProps) {
    super(props);
    this.state = {
      isVisible: false,
      data: undefined,
    };
  }

  open = (data: any) => {
    this.setState({ isVisible: true, data });
  }

  hide = () => {
    this.setState({ isVisible: false });
  }

  confirm = () => {
    this.props.onConfirm && this.props.onConfirm(this.state.data);
  }

  hideAndCancel = () => {
    this.hide();
    this.props.onCancel && this.props.onCancel(this.state.data);
  }

  render() {
    return (
      <Modal isOpen={this.state.isVisible} toggle={this.hide}
        onEnter={() => this.props.onEnter && this.props.onEnter(this.state.data)}
        onExit={() => this.props.onExit && this.props.onExit(this.state.data)}
        onOpened={() => this.props.onOpened && this.props.onOpened(this.state.data)}
        onClosed={() => this.props.onClosed && this.props.onClosed(this.state.data)}
      >
        <ModalHeader>{this.props.title}</ModalHeader>
        <ModalBody>
          {this.props.modalBody(this.state.data)}
        </ModalBody>
        <ModalFooter>
          <Button color={this.props.confirmButtonColor} disabled={this.props.confirmButtonDisabled} onClick={this.confirm}>
            {this.props.confirmButtonLabel}
          </Button>{' '}
          <Button color={this.props.cancelButtonColor} disabled={this.props.cancelButtonDisabled} onClick={this.hideAndCancel}>
            {this.props.cancelButtonLabel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmationModal;
