import React, { FunctionComponent, useState, useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";
import { toastr } from "react-redux-toastr";

import moment from 'moment';

import CrudList, { CrudTableState, initialTableState } from "../../components/crud/CrudList";
import { CrudTableColumnBuilder } from "../../components/crud/CrudTableColumnBuilder";

import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import { PagedRequestDTO } from "../../services/api/requests";

import ConfirmationModal from "../../components/ConfirmationModal";

import { ImportLog, ImportLogStatus, ImportLogType } from "../../domain/ImportLog";

import ImportDataStatusBadge from "./ImportDataStatusBadge";
import ImportDataSelectCsvFile from "./ImportDataSelectCsvFile";

type ImportLogTableRow = {
  id: number;
  type: string;
  status: ImportLogStatus;
  created_at: string;
  updated_at: string;
};

const ImportDataListPage: FunctionComponent = () => {

  // ImportLogs table

  const [importLogs, setImportLogs] = useState<ImportLogTableRow[]>([]);
  const [tableState, setTableState] = useState<CrudTableState<ImportLogTableRow>>(initialTableState);

  const { url } = useRouteMatch();

  const columns = CrudTableColumnBuilder(tableState)
    .addIndexColumn()
    .addColumn({ text: 'Type', dataField: 'type', sort: true })
    .addColumn({ text: 'Status', dataField: 'status', sort: true, headerStyle: { width: '120px' }, formatter: (status: ImportLogStatus) => (
      <ImportDataStatusBadge status={status} />
    )})
    .addColumn({ text: 'Started at', dataField: 'created_at', sort: true, headerStyle: { width: '260px' } })
    .addColumn({ text: 'Last updated at', dataField: 'updated_at', sort: true, headerStyle: { width: '260px' } })
    .addAction((importLog: ImportLogTableRow) => (
      <Link to={`${url}/details/${importLog.id}`}>
        <SearchIcon size={18} className="mr-1"></SearchIcon>
      </Link>
    ))
    .getColumns();

  // Fetch ImportLogs logic

  const [fetchImportLogs, { isLoading: isFetchingImportLogs }] = useApiService('importLog', importLogService => (
    (tableState: CrudTableState<ImportLogTableRow>) => {
      const pagedRequestDTO = mapTableStateToPagedRequest(tableState);
      return importLogService.fetchImportLogsPage(pagedRequestDTO).then(importLogsPage => {
        const totalSize = importLogsPage.total;

        setImportLogs(importLogsPage.data.map(mapImportLogToImportLogTableRow));
        setTableState({ ...tableState, totalSize });
      });
    })
  );

  const mapImportLogToImportLogTableRow = (importLog: ImportLog) => ({
    id: importLog.id,
    type: importLog.type,
    status: importLog.status,
    created_at: moment(importLog.created_at).format('MMMM Do YYYY, h:mm:ss a'),
    updated_at: moment(importLog.updated_at).format('MMMM Do YYYY, h:mm:ss a'),
  } as ImportLogTableRow);

  const mapTableStateToPagedRequest = (tableState: CrudTableState<ImportLogTableRow>) => {
    const pagedRequestDTO: PagedRequestDTO<ImportLog> = {
      page: tableState.page,
      limit: tableState.sizePerPage,
    };
    if (tableState.sortField && tableState.sortOrder) {
      pagedRequestDTO.sortOptions = [{ field: tableState.sortField, order: tableState.sortOrder }];
    }
    return pagedRequestDTO;
  };

  const onTableChange = (tableState: CrudTableState<ImportLogTableRow>) => fetchImportLogs(tableState);
  useEffectOnMount(() => fetchImportLogs(tableState));

  // Start process buttons

  const [csvFile, setCsvFile] = useState<any>();

  const confirmSearchSyncModalRef = useRef<ConfirmationModal>(null);

  const dropdownButtons = Object.values(ImportLogType).map(type => ({
    label: type,
    onClick: () => confirmSearchSyncModalRef.current!.open(type),
  }));

  const startProcess = (type: ImportLogType) => {
    if (type === ImportLogType.USER_BULK) {
      startProcessUserBulkImport(csvFile);
    }
    if (type === ImportLogType.USER_SEARCH_SYNC) {
      startProcessUserSearchSync();
    }
    if (type === ImportLogType.APP_SEARCH_SYNC) {
      startProcessAppSearchSync();
    }
  };

  const [ startProcessUserBulkImport, { isLoading: isStartingProcessUserBulkImport } ] = useApiService('user', userService => (
    (csvFile: File) => (
      userService.bulkImport(csvFile).then(() => {
        toastr.success('Success', `${ImportLogType.USER_BULK} process started successfully`);
        confirmSearchSyncModalRef.current!.hide();
        fetchImportLogs(tableState);
      })
    )
  ));

  const [ startProcessUserSearchSync, { isLoading: isStartingProcessUserSearchSync } ] = useApiService('user', userService => (
    () => (
      userService.startSearchSync().then(() => {
        toastr.success('Success', `${ImportLogType.USER_SEARCH_SYNC} process started successfully`);
        confirmSearchSyncModalRef.current!.hide();
        fetchImportLogs(tableState);
      })
    )
  ));

  const [ startProcessAppSearchSync, { isLoading: isStartingProcessAppSearchSync } ] = useApiService('app', appService => (
    () => (
      appService.startSearchSync().then(() => {
        toastr.success('Success', `${ImportLogType.APP_SEARCH_SYNC} process started successfully`);
        confirmSearchSyncModalRef.current!.hide();
        fetchImportLogs(tableState);
      })
    )
  ));

  const isStartingProcess = isStartingProcessUserBulkImport || isStartingProcessUserSearchSync || isStartingProcessAppSearchSync;

  return (
    <React.Fragment>
      <CrudList
        title="Import data"
        showAddButton={false}
        showDropdownButton={true}
        dropdownButtonLabel="Start"
        dropdownButtons={dropdownButtons}
        tableData={importLogs}
        tableColumns={columns}
        tableIsLoading={isFetchingImportLogs}
        onTableChange={onTableChange}
        tableState={tableState}
        reduxSyncTableStateKey="importLogs"
      />

      <ConfirmationModal
        ref={confirmSearchSyncModalRef}
        title="Start process"
        modalBody={(type: ImportLogType) => (
          type === ImportLogType.USER_BULK
            ? <ImportDataSelectCsvFile setFile={setCsvFile} />
            : `Do you really want to start processing "${type}" now? Make sure that it is a good time to do so, otherwise it'll impact WeShareApps users' experience.`
        )}
        confirmButtonDisabled={isStartingProcess}
        confirmButtonLabel={isStartingProcess ? 'Starting process' : 'Confirm'}
        onConfirm={(type: ImportLogType) => startProcess(type)}
        onClosed={() => setCsvFile(null)}
      />
    </React.Fragment>
  );
};

export default ImportDataListPage;
