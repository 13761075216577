import React, { FunctionComponent, useState, useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Edit as EditIcon, Trash2 as TrashIcon } from "react-feather";
import { DebounceInput } from "react-debounce-input";
import { Input, Row, Col } from "reactstrap";
import Select, { ValueType } from "react-select";

import CrudList, { CrudTableState, initialTableState, CrudListCustomFilterComponent } from "../../components/crud/CrudList";
import { CrudTableColumnBuilder } from "../../components/crud/CrudTableColumnBuilder";


import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import { PagedRequestDTO, PagedRequestSimpleFilter, PagedRequestFilterCombination } from "../../services/api/requests";
import { SelectOption } from "../../services/api/responses";

import ConfirmationModal from "../../components/ConfirmationModal";

import { App } from "../../domain/App";
import { toastr } from "react-redux-toastr";
import { AppTypesPaths, FrontendAppPage, joinPagesPaths } from '../../constants/frontendAppPages';


type AppTableRow = {
  id: number;
  name: string;
  developer_name: string;
  status: string;
  slug: string;
  categories: string[]
};

const AppsListCustomFilter: CrudListCustomFilterComponent = ({ filter, setFilter }) => {
  const [statusList, setStatusList] = useState<SelectOption<string>[]>([]);

  const [fetchStatusList, { isLoading: isFetchingStatusList }] = useApiService('app', appService => (
    () => (
      appService.fetchAppStatus().then(statusList => setStatusList(statusList.map(s => ({ label: s, value: s }))))
    )
  ));

  const onFilterNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!filter || event.target.value !== filter.name) {
      setFilter({ ...(filter || {}), name: event.target.value });
    }
  };

  const onFilterStatusSelectChange = (value: ValueType<SelectOption<string>>) => {
    const values = (value as SelectOption<string>[]) || [];
    if (!filter || values.length !== (filter.status || []).length) {
      setFilter({ ...(filter || {}), status: values });
    }
  };

  useEffectOnMount(() => fetchStatusList());

  return (
    <Row>
      <Col md="12" lg="8">
        <DebounceInput
          element={Input as any}
          value={filter?.name}
          onChange={onFilterNameInputChange}
          debounceTimeout={300}
          placeholder="Filter by name..."
        />
      </Col>
      <Col md="12" lg="4">
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Filter by status..."
          options={statusList}
          isLoading={isFetchingStatusList}
          value={filter?.status}
          onChange={onFilterStatusSelectChange}
          isMulti
          isClearable={false}
        />
      </Col>
    </Row>
  );
};

const AppsListPage: FunctionComponent = () => {

  // Apps table

  const [apps, setApps] = useState<AppTableRow[]>([]);
  const [tableState, setTableState] = useState<CrudTableState<AppTableRow>>(initialTableState);

  const { url } = useRouteMatch();

  const columns = CrudTableColumnBuilder(tableState)
    .addIndexColumn()
    .addColumn({ text: 'Name', dataField: 'name', sort: true, })
    .addColumn({ text: 'Developer', dataField: 'developer_name', sort: true, })
    .addColumn({ text: 'Status', dataField: 'status', sort: true, })
    .addAction((app: AppTableRow) => (
      <Link to={`${url}/update/${app.id}`}>
        <EditIcon size={18} className="mr-1"></EditIcon>
      </Link>
    ))
    .addAction((app: AppTableRow) => (
      <Link to="#" onClick={() => deleteAppModalRef.current!.open(app)}>
        <TrashIcon size={18} className="ml-1"></TrashIcon>
      </Link>
    ))
    // .addActionsColumn((app: AppTableRow) => (
    //   // TODO: convert to an "addActionColumn" sequence where we pass only the path?: string, icon: Icon, and onClick?: Fn
    //   <div>
    //     <Link to={`${url}/update/${app.id}`}>
    //       <EditIcon size={18} className="mr-1"></EditIcon>
    //     </Link>
    //     <Link to="#" onClick={() => deleteAppModalRef.current!.open(app)}>
    //       <TrashIcon size={18} className="ml-1"></TrashIcon>
    //     </Link>
    //   </div>
    // ))
    .getColumns();

  // Fetch apps logic

  const [fetchApps, { isLoading: isFetchingApps }] = useApiService('app', appService => (
    (tableState: CrudTableState<AppTableRow>) => {
      const pagedRequestDTO = mapTableStateToPagedRequest(tableState);
      return appService.fetchAppsPage(pagedRequestDTO).then(appsPage => {
        const totalSize = appsPage.total;

        setApps(appsPage.data.map(mapAppToAppTableRow));
        setTableState({ ...tableState, totalSize });
      });
    })
  );

  const mapAppToAppTableRow = (app: App) => ({
    id: app.id,
    name: app.name,
    developer_name: (app.developer && app.developer.name) || 'Unknown',
    status: app.status,
    slug: app.slug,
    categories: app.categories.map(category => category.slug)
  } as AppTableRow);

  const mapTableStateToPagedRequest = (tableState: CrudTableState<AppTableRow>) => {
    const pagedRequestDTO: PagedRequestDTO<App> = {
      page: tableState.page,
      limit: tableState.sizePerPage,
    };
    if (tableState.filter) {
      const filter = tableState.filter as { name: string, status: { value: string }[] };

      const statusFilter: PagedRequestSimpleFilter<App> | undefined = (filter.status && filter.status.length)
        ? { field: 'status', operator: 'in', value: filter.status.map(s => s.value) }
        : undefined;

      const nameFilter: PagedRequestFilterCombination<App> | undefined = (filter.name)
        ? { operator: 'or', filters: [
            { field: 'name', operator: 'like', value: filter.name },
            { field: 'slug', operator: 'like', value: filter.name },
          ]
        }
        : undefined;

      if (statusFilter && nameFilter) {
        pagedRequestDTO.search = { operator: 'and', filters: [statusFilter, nameFilter] };
      } else {
        pagedRequestDTO.search = statusFilter || nameFilter;
      }
    }
    if (tableState.sortField && tableState.sortOrder) {
      pagedRequestDTO.sortOptions = tableState.sortField === 'developer_name'
        ? [{ field: 'developer.name', order: tableState.sortOrder! }]
        : [{ field: tableState.sortField, order: tableState.sortOrder! }];
    }
    return pagedRequestDTO;
  };

  const onTableChange = (tableState: CrudTableState<AppTableRow>) => fetchApps(tableState);

  // Delete app logic

  const deleteAppModalRef = useRef<ConfirmationModal>(null);

  const [revalidate] = useApiService('revalidation', revalidationService => (
    (paths: string) => revalidationService.revalidate(paths))
  );

  const getPathsForRevalidation = (app: AppTableRow) => {
    const categoriesPathToRevalidate = joinPagesPaths(app.categories.map(category => FrontendAppPage.SINGLE_CATEGORY(category)));
    return joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS, FrontendAppPage.CATEGORIES, categoriesPathToRevalidate, AppTypesPaths, FrontendAppPage.SINGLE_APP(app.slug)]);
  }

  const [deleteApp, { isLoading: isDeletingApp }] = useApiService('app', appService => (
    (app: AppTableRow) => (
      appService.deleteApp(app.id).then(() => {
        const pathsToRevalidate = getPathsForRevalidation(app);
        revalidate(pathsToRevalidate)
        displayAppDeletedSuccessMessage();
      })
    ))
  );

  const displayAppDeletedSuccessMessage = () => {
    toastr.success('Success', 'App deleted successfully');
    deleteAppModalRef.current!.hide();
    fetchApps(tableState);
  };


  return (
    <React.Fragment>
      <CrudList
        title="Apps"
        addButtonLinkPath="/create"
        addButtonLabel="Add new app"
        tableData={apps}
        tableColumns={columns}
        tableIsLoading={isFetchingApps}
        onTableChange={onTableChange}
        tableState={tableState}
        reduxSyncTableStateKey="apps"
        useCustomFilter
        customFilter={AppsListCustomFilter}
      />

      <ConfirmationModal
        ref={deleteAppModalRef}
        title="Delete app"
        modalBody={(app: AppTableRow) => `Do you really want to delete app "${app?.name}"?`}
        confirmButtonColor="danger"
        confirmButtonDisabled={isDeletingApp}
        confirmButtonLabel={isDeletingApp ? 'Deleting app...' : 'Delete app'}
        cancelButtonDisabled={isDeletingApp}
        onConfirm={(app: AppTableRow) => deleteApp(app)}
      />
    </React.Fragment>
  );
};

export default AppsListPage;
