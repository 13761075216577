import React, { FunctionComponent, CSSProperties } from "react";
import { File } from "../domain/File";
import { FileVariation, FileVariationType } from "../domain/FileVariation";
import classNames from "classnames";


const getVariation = (variations: FileVariation[], type: FileVariationType) => variations.find(v => v.variation === type);

const getFileVariationUrl = (file?: File, ...variationType: FileVariationType[]) => {
  if (!file) {
    return '';
  }
  const variations = variationType.map(type => getVariation(file.variations, type)).filter(v => Boolean(v));
  return variations[0] ? variations[0].url : '';
}

const getSrc = (file?: File) => {
  return getFileVariationUrl(file, FileVariationType.MEDIUM, FileVariationType.SMALL, FileVariationType.ORIGINAL);
};

const getSrcSet = (file?: File) => {
  if (!file) {
    return '';
  }
  const large = getVariation(file.variations, FileVariationType.LARGE);
  const medium = getVariation(file.variations, FileVariationType.MEDIUM);
  const small = getVariation(file.variations, FileVariationType.SMALL);
  const thumb = getVariation(file.variations, FileVariationType.THUMB);

  return [{ variation: large, size: '4x' }, { variation: medium, size: '3x' }, { variation: small, size: '2x' }, { variation: thumb, size: '1x' }]
    .filter(v => v.variation)
    .map(v => `${v.variation!.url} ${v.size}`)
    .join(',');
};


type ImageProps = {
  file?: File;
  width?: string | number;
  height?: string | number;
  size?: string | number;
  alt?: string;
  imageClassName?: string;
  imageStyle?: CSSProperties;
};

const Image: FunctionComponent<ImageProps> = ({ file, width, height, size, alt, imageClassName, imageStyle }) => {
  return (
    file ? (
      <img
        src={getSrc(file)}
        srcSet={getSrcSet(file)}
        style={{ width: width || size || '100px', height: height || size || '100px' }}
        alt={alt}
        className={imageClassName}
      />
    ) : (
        <div
          className={classNames("d-flex justify-content-center align-items-center bg-primary text-white font-weight-bold text-center", imageClassName)}
          style={{ ...(imageStyle || {}), width: size || '100px', height: size || '100px' }}
        >
          <div>
            {alt ? alt.charAt(0) : ''}
          </div>
        </div>
      )
  );
}

export default Image;
