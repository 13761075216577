import React, { FunctionComponent, useState, useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Edit as EditIcon, Trash2 as TrashIcon } from "react-feather";
import { toastr } from "react-redux-toastr";

import moment from 'moment';

import CrudList, { CrudTableState, initialTableState } from "../../components/crud/CrudList";
import { CrudTableColumnBuilder } from "../../components/crud/CrudTableColumnBuilder";

import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import { PagedRequestDTO } from "../../services/api/requests";

import ConfirmationModal from "../../components/ConfirmationModal";

import { Tag } from "../../domain/Tag";

type TagTableRow = {
  id: number;
  name: string;
  updated_at: string;
};

const TagsListPage: FunctionComponent = () => {

  // Tags table

  const [tags, setTags] = useState<TagTableRow[]>([]);
  const [tableState, setTableState] = useState<CrudTableState<TagTableRow>>(initialTableState);

  const { url } = useRouteMatch();

  const columns = CrudTableColumnBuilder(tableState)
    .addIndexColumn()
    .addColumn({ text: 'Name', dataField: 'name', sort: true, })
    .addColumn({ text: 'Updated at', dataField: 'updated_at', sort: true, headerStyle: { width: '250px' } })
    .addActionsColumn((tag: TagTableRow) => (
      // TODO: convert to an "addActionColumn" sequence where we pass only the path?: string, icon: Icon, and onClick?: Fn
      <div>
        <Link to={`${url}/update/${tag.id}`}>
          <EditIcon size={18} className="mr-1"></EditIcon>
        </Link>
        <Link to="#" onClick={() => deleteTagModalRef.current!.open(tag)}>
          <TrashIcon size={18} className="ml-1"></TrashIcon>
        </Link>
      </div>
    ))
    .getColumns();

  // Fetch tags logic

  const [fetchTags, { isLoading: isFetchingTags }] = useApiService('tag', tagService => (
    (tableState: CrudTableState<TagTableRow>) => {
      const pagedRequestDTO = mapTableStateToPagedRequest(tableState);
      return tagService.fetchTagsPage(pagedRequestDTO).then(tagsPage => {
        const totalSize = tagsPage.total;

        setTags(tagsPage.data.map(mapTagToTagTableRow));
        setTableState({ ...tableState, totalSize });
      });
    })
  );

  const mapTagToTagTableRow = (tag: Tag) => ({
    id: tag.id,
    name: tag.name,
    updated_at: moment(tag.updated_at).format('MMMM Do YYYY, h:mm:ss a'),
  } as TagTableRow);

  const mapTableStateToPagedRequest = (tableState: CrudTableState<TagTableRow>) => {
    const pagedRequestDTO: PagedRequestDTO<Tag> = {
      page: tableState.page,
      limit: tableState.sizePerPage,
    };
    if (tableState.filter) {
      pagedRequestDTO.search = {
        operator: 'or',
        filters: [
          { field: 'name', operator: 'like', value: tableState.filter },
          { field: 'slug', operator: 'like', value: tableState.filter },
        ],
      };
    }
    if (tableState.sortField && tableState.sortOrder) {
      pagedRequestDTO.sortOptions = [{ field: tableState.sortField, order: tableState.sortOrder }];
    }
    return pagedRequestDTO;
  };

  const onTableChange = (tableState: CrudTableState<TagTableRow>) => fetchTags(tableState);
  useEffectOnMount(() => fetchTags(tableState));

  // Delete tag logic

  const deleteTagModalRef = useRef<ConfirmationModal>(null);

  const [deleteTag, { isLoading: isDeletingTag }] = useApiService('tag', tagService => (
    (tag: TagTableRow) => (
      tagService.deleteTag(tag.id).then(displayTagDeletedSuccessMessage)
    ))
  );

  const displayTagDeletedSuccessMessage = () => {
    toastr.success('Success', 'Tag deleted successfully');
    deleteTagModalRef.current!.hide();
    fetchTags(tableState);
  };


  return (
    <React.Fragment>
      <CrudList
        title="Tags"
        addButtonLinkPath="/create"
        addButtonLabel="Add new tag"
        tableData={tags}
        tableColumns={columns}
        tableIsLoading={isFetchingTags}
        onTableChange={onTableChange}
        tableState={tableState}
        reduxSyncTableStateKey="tags"
        useSimpleFilter
        simpleFilterPlaceholder="Filter by name..."
      />

      <ConfirmationModal
        ref={deleteTagModalRef}
        title="Delete tag"
        modalBody={(tag: TagTableRow) => `Do you really want to delete tag "${tag?.name}"?`}
        confirmButtonColor="danger"
        confirmButtonDisabled={isDeletingTag}
        confirmButtonLabel={isDeletingTag ? 'Deleting tag...' : 'Delete tag'}
        cancelButtonDisabled={isDeletingTag}
        onConfirm={(tag: TagTableRow) => deleteTag(tag)}
      />
    </React.Fragment>
  );
};

export default TagsListPage;
