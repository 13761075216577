import { AxiosInstance } from "axios";
import { PagedResponseDTO } from "../responses";
import { Role } from "../../../domain/Role";

type RolePageResponseDTO = PagedResponseDTO<Role>;

export class RoleService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchRoles(): Promise<Role[]> {
    return this.axios.get<RolePageResponseDTO>('/role?limit=100&fields=[id,name,]')
      .then(res => res.data.data);
  }

}
