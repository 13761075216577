import React, { FunctionComponent, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import { Row, Col, Label } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { useSafeRedirect } from "../../hooks/useSafeRedirect";
import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import CrudForm from "../../components/crud/CrudForm";
import CrudFormButtonBar from "../../components/crud/CrudFormButtonBar";

import { SelectOption } from "../../services/api/responses";

import { List, ListType } from "../../domain/List";
import AppsSelect from "./AppsSelect";
import { FrontendAppPage, joinPagesPaths } from '../../constants/frontendAppPages';

type ListForm = {
  name: string;
  type: string;
  description: string;
  apps?: SelectOption[];
};

const MyAppsFeaturedAppsFormPage: FunctionComponent = () => {
  const { id } = useParams();
  const redirectTo = useSafeRedirect();

  const isUpdatingList = Boolean(id);

  const title = isUpdatingList ? 'Update list' : 'Create list';

  const avForm = useRef<{ reset: Function }>(); // TODO: use AvForm type
  const [oldListData, setOldListData] = useState<ListForm>();

  const types: SelectOption<string>[] = [
    { label: 'Show on both Homepage and My Apps page', value: ListType.HYBRID },
    { label: 'Show only on My Apps page', value: ListType.MY_APPS },
  ];

  const [selectedApps, setSelectedApps] = useState<SelectOption[]>([]);

  // Fetch list logic

  const [fetchListById, { isLoading: isFetchingList }] = useApiService('list', listService => (
    (listId: number) => (
      listService.fetchListByIdOrSlug(listId).then(resetFormWithListData)
    ))
  );

  const [revalidate] = useApiService('revalidation', revalidationService => (
    (paths: string) => revalidationService.revalidate(paths))
  );

  useEffectOnMount(() => {
    if (id) {
      fetchListById(id);
    }
  });

  const resetFormWithListData = (list: List) => {
    const { apps, ...listValues } = list;
    const appsSelectOptions = apps.map(a => ({ label: `${a.name}${a.status !== 'Published' ? ` (${a.status})` : ''}`, value: a.id }))

    const listForm = {
      ...listValues,
      apps: appsSelectOptions,
    };

    setOldListData(listForm);
    setSelectedApps(appsSelectOptions);
    avForm.current!.reset(); // we need to reset the form so AvForm will accept the data we pass into its 'model' prop
  };

  // Save list logic

  const [saveList, { isLoading: isSaving }] = useApiService('list', listService => (
    (listForm: ListForm) => {
      const apps = selectedApps.map(a => ({ id: a.value }));

      return isUpdatingList
        ? listService.updateList(id, { ...listForm, orderedEntities: apps.map(a => a.id)  }).then(() => {
          const pathsToRevalidate = joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS])
          revalidate(pathsToRevalidate);
          displaySuccessMessageAndGoBack()
        })
        : listService.createList({ ...listForm, apps }).then(() => {
          const pathsToRevalidate = joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS])
          revalidate(pathsToRevalidate);
          displaySuccessMessageAndGoBack()
        });
    })
  );

  const displaySuccessMessageAndGoBack = () => {
    toastr.success('Success', `List ${id ? 'updated' : 'created'} successfully`);
    redirectTo('/apps/myapps-featured');
  };

  const onSubmit = (_: React.FormEvent<HTMLFormElement>, listForm: ListForm) => {
    if (selectedApps.length < 2 || selectedApps.length > 20) {
      toastr.warning('Attention', 'Please select between 2 and 20 apps');
    } else {
      saveList(listForm);
    }
  };

  const isBusy = isFetchingList || isSaving;

  return (
    <CrudForm title={title} showSpinner={isFetchingList}>
      <AvForm onValidSubmit={onSubmit} ref={avForm} model={oldListData}>

        <Row form>
          <Col md="12" lg="4">
            <AvField label="Name" name="name" type="text" validate={{
              required: { value: true, errorMessage: 'The name is required' },
              maxLength: { value: 255, errorMessage: 'The name must be less than 255 characters' },
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="4">
            <AvField type="select" name="type" label="Type" required errorMessage="The type is required">
              <option value="">Pick a type...</option>
              {types.map(type => (
                <option key={type.value} value={type.value}>{type.label}</option>
              ))}
            </AvField>
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="5">
            <AvField label="Description" name="description" type="textarea" rows="5" validate={{
              maxLength: { value: 65536, errorMessage: 'The description must be less than 65536 characters' },
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="5">
            <Label>Apps</Label>
            <AppsSelect
              className="flex-grow-1"
              value={selectedApps}
              onChange={(apps: SelectOption[]) => setSelectedApps(apps)}
              isDisabled={isBusy}
              isSortable
            />
          </Col>
        </Row>

        <CrudFormButtonBar
          isSaveButtonDisabled={isBusy}
          isCancelButtonDisabled={isBusy}
          saveButtonLabel={isSaving ? 'Saving...' : 'Save'}
          cancelButtonRoutePath={'/apps/myapps-featured'}
        />
      </AvForm>
    </CrudForm>
  );
}

export default MyAppsFeaturedAppsFormPage;
