import React, { FunctionComponent, useRef, useEffect, useState } from "react";

import { Label } from "reactstrap";
import Select, { Props as ReactSelectProps } from "react-select";
import CreatableSelect, { Props as ReactSelectCreatableProps } from "react-select/creatable";

import classNames from "classnames";

import { AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";

import { useEffectOnMount } from "../hooks/useEffectOnMount";

type AvSelectSpecificProps = {
  name: string,
  label?: string;
  required?: boolean,
  errorMessage?: string,
};

type SelectProps = ReactSelectProps<any> & AvSelectSpecificProps;
type CreatableSelectProps = ReactSelectCreatableProps<any> & AvSelectSpecificProps & { isCreatable: true };

type AvSelectProps = SelectProps | CreatableSelectProps;


const AvSelect: FunctionComponent<AvSelectProps> = ({ onChange: parentOnChange, name, label, required, errorMessage, isCreatable, ...attributes }) => {

  const inputRef = useRef<typeof AvInput>();
  const selectRef = useRef<typeof React.Component>(isCreatable ? CreatableSelect : Select);

  const [hiddenInputValue, setHiddenInputValue] = useState<any>();
  const [selectValue, setSelectValue]  = useState<any>();

  const onChange = (newValue: any, actionMeta: any) => {
    // FIX: when <CreatableSelect> calls onChange with a created value, it's label is incorrectly passed as 'Created "value"'
    if (newValue && newValue.__isNew__) {
      newValue.label = newValue.value;
    }

    setSelectValue(newValue);
    setHiddenInputValue(calculateHiddenInputValue(newValue));

    parentOnChange && parentOnChange(newValue, actionMeta);
  }

  const onFocus = (e: React.SyntheticEvent<HTMLElement>) => {
    inputRef.current.onFocusHandler(createFakeEvent(hiddenInputValue));
  }

  const onBlur = (e: React.SyntheticEvent<HTMLElement>) => {
    inputRef.current.onBlurHandler(createFakeEvent(hiddenInputValue));
  }

  const createFakeEvent = (value: any) => ({ target: { value: value || '' } });

  const calculateHiddenInputValue = (value: any) => {
    if (value === null || value === undefined) {
      return '';
    }
    return value || '';
  }

  const onResetHiddenInputValue = (newHiddenInputValue: any) => {
    setSelectValue(newHiddenInputValue);
    setHiddenInputValue(newHiddenInputValue);
  };

  useEffect(() => {
    selectRef.current = isCreatable ? CreatableSelect : Select;
  }, [isCreatable]);

  useEffect(() => {
    inputRef.current.onChangeHandler(createFakeEvent(hiddenInputValue));
  }, [hiddenInputValue]);

  useEffectOnMount(() => {
    setSelectValue(inputRef.current.getDefaultValue());
  });

  const SelectTag = selectRef.current;

  return (
    <AvGroup>
      {label && (<Label>{label}</Label>)}

      <AvInput
        ref={inputRef}
        type="hidden"
        name={name}
        required={required}
        autoComplete="off"
        className="av-react-select-input"
        value={hiddenInputValue}
        onReset={onResetHiddenInputValue}
      />
      <SelectTag
        name={name}

        value={selectValue}

        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}

        className={classNames({ 'react-select-container': true })}
        classNamePrefix="react-select"

        {...attributes}
      />
      {errorMessage && (<AvFeedback>{errorMessage}</AvFeedback>)}
    </AvGroup>
  );
}

export type AvSelectOption<T = any> = {
  slug?: string,
  label: string;
  value: T;
  __isNew__?: boolean;
};

export default AvSelect;
