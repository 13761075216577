import React, { FunctionComponent, useState, useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Edit as EditIcon, Trash2 as TrashIcon } from "react-feather";
import { DebounceInput } from "react-debounce-input";
import { Input, Row, Col } from "reactstrap";

import CrudList, { CrudTableState, initialTableState, CrudListCustomFilterComponent } from "../../components/crud/CrudList";
import { CrudTableColumnBuilder } from "../../components/crud/CrudTableColumnBuilder";


import { useApiService } from "../../hooks/useApiService";

import { PagedRequestDTO, PagedRequestSimpleFilter, PagedRequestFilterCombination } from "../../services/api/requests";

import ConfirmationModal from "../../components/ConfirmationModal";

import { Campaign } from "../../domain/Campaign";
import { toastr } from "react-redux-toastr";
import {App} from "../../domain/App";
import {Payment} from "../../domain/Payment";
import moment from "moment";


type CampaignTableRow = {
  id: number;
  name: string;
  banner: boolean;
  search_result: boolean;
  news_letter: boolean;
  start_date: Date;
  finish_date: Date;
  created_at: Date;
  app: App;
  payment: Payment;
};

const CampaignsListCustomFilter: CrudListCustomFilterComponent = ({ filter, setFilter }) => {

  const onFilterNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!filter || event.target.value !== filter.name) {
      setFilter({ ...(filter || {}), name: event.target.value });
    }
  };
  return (
    <Row>
      <Col md="12" lg="8">
        <DebounceInput
          element={Input as any}
          value={filter?.name}
          onChange={onFilterNameInputChange}
          debounceTimeout={300}
          placeholder="Filter by app name..."
        />
      </Col>
    </Row>
  );
};

const CampaignsListPage: FunctionComponent = () => {

  // campaign table

  const [campaigns, setCampaigns] = useState<CampaignTableRow[]>([]);
  const [tableState, setTableState] = useState<CrudTableState<CampaignTableRow>>(initialTableState);

  const { url } = useRouteMatch();

  const columns = CrudTableColumnBuilder(tableState)
    .addIndexColumn()
    .addColumn({ text: 'App name', dataField: 'name', sort: true, })
    .addColumn({ text: 'Newsletter', dataField: 'news_letter', sort: true, })
    .addColumn({ text: 'Search', dataField: 'search_result', sort: true, })
    .addColumn({ text: 'Banner', dataField: 'banner', sort: true, })
    .addColumn({ text: 'Start date', dataField: 'start_date', sort: true, })
    .addColumn({ text: 'End date', dataField: 'finish_date', sort: true, })
    .addColumn({ text: 'Price', dataField: 'payment', sort: true, })
    .addColumn({ text: 'Created At', dataField: 'created_at', sort: true, })
    .addAction((campaign: CampaignTableRow) => (
      <Link to={`${url}/update/${campaign.id}`}>
        <EditIcon size={18} className="mr-1"></EditIcon>
      </Link>
    ))
    .addAction((campaign: CampaignTableRow) => (
      <Link to="#" onClick={() => deleteCampaignModalRef.current!.open(campaign)}>
        <TrashIcon size={18} className="ml-1"></TrashIcon>
      </Link>
    ))
    .getColumns();

  const [fetchCampaigns, { isLoading: isFetchingCampaigns }] = useApiService('campaign', campaignService => (
    (tableState: CrudTableState<CampaignTableRow>) => {
      const pagedRequestDTO = mapTableStateToPagedRequest(tableState);
      return campaignService.fetchCampaignsPage(pagedRequestDTO).then(campaignsPage => {
        const totalSize = campaignsPage.total;

        setCampaigns(campaignsPage.data.map(mapCampaignToCampaignTableRow));
        setTableState({ ...tableState, totalSize });
      });
    })
  );

  const mapCampaignToCampaignTableRow = (campaign: Campaign) => ({
    id: campaign.id,
    name: campaign.app.name,
    banner: campaign.banner,
    search_result: campaign.search_result,
    news_letter: campaign.news_letter,
    start_date: moment(campaign.start_date).format('MMMM Do YYYY, h:mm:ss a'),
    finish_date: moment(campaign.finish_date).format('MMMM Do YYYY, h:mm:ss a'),
    created_at: moment(campaign.created_at).format('MMMM Do YYYY, h:mm:ss a'),
    payment: campaign.payment.amount,
  } as unknown as CampaignTableRow);

  const mapTableStateToPagedRequest = (tableState: CrudTableState<CampaignTableRow>) => {
    const pagedRequestDTO: PagedRequestDTO<Campaign> = {
      page: tableState.page,
      limit: tableState.sizePerPage,
    };
    if (tableState.sortField && tableState.sortOrder) {
      pagedRequestDTO.sortOptions = tableState.sortField === 'payment'
          ? [{ field: 'payment.amount', order: tableState.sortOrder! }]
          : [{ field: tableState.sortField === 'name' ? 'app.name' : tableState.sortField, order: tableState.sortOrder! }];
    }
    if (tableState.filter) {
      const filter = tableState.filter as { name: string, status: { value: string }[] };

      const statusFilter: PagedRequestSimpleFilter<Campaign> | undefined = (filter.status && filter.status.length)
        ? { field: 'status', operator: 'in', value: filter.status.map(s => s.value) }
        : undefined;

      const nameFilter: PagedRequestFilterCombination<Campaign> | undefined = (filter.name)
        ? { operator: 'or', filters: [
            { field: 'app.name', operator: 'like', value: filter.name },
          ]
        }
        : undefined;

      if (statusFilter && nameFilter) {
        pagedRequestDTO.search = { operator: 'and', filters: [statusFilter, nameFilter] };
      } else {
        pagedRequestDTO.search = statusFilter || nameFilter;
      }

      if (tableState.filter) {
        const filter = tableState.filter as { name: string, status: { value: string }[] };

        const statusFilter: PagedRequestSimpleFilter<App> | undefined = (filter.status && filter.status.length)
            ? { field: 'status', operator: 'in', value: filter.status.map(s => s.value) }
            : undefined;

        const nameFilter: PagedRequestFilterCombination<App> | undefined = (filter.name)
            ? { operator: 'or', filters: [
                { field: 'app.name', operator: 'like', value: filter.name },
              ]
            }
            : undefined;

        if (statusFilter && nameFilter) {
          pagedRequestDTO.search = { operator: 'and', filters: [statusFilter, nameFilter] };
        } else {
          pagedRequestDTO.search = statusFilter || nameFilter;
        }
      }
    }
    return pagedRequestDTO;
  };

  const onTableChange = (tableState: CrudTableState<CampaignTableRow>) => fetchCampaigns(tableState);

  // Delete campaign logic

  const deleteCampaignModalRef = useRef<ConfirmationModal>(null);

  const [deleteCampaign, { isLoading: isDeletingCampaign }] = useApiService('campaign', campaignService => (
    (campaign: CampaignTableRow) => (
        campaignService.deleteCampaign(campaign.id).then(displayCampaignDeletedSuccessMessage)
    ))
  );

  const displayCampaignDeletedSuccessMessage = () => {
    toastr.success('Success', 'Campaign deleted successfully');
    deleteCampaignModalRef.current!.hide();
    fetchCampaigns(tableState);
  };


  return (
    <React.Fragment>
      <CrudList
        title="Campaigns"
        showAddButton={false}
        tableData={campaigns}
        tableColumns={columns}
        tableIsLoading={isFetchingCampaigns}
        onTableChange={onTableChange}
        tableState={tableState}
        reduxSyncTableStateKey="campaigns"
        useCustomFilter
        customFilter={CampaignsListCustomFilter}
      />

      <ConfirmationModal
        ref={deleteCampaignModalRef}
        title="Delete campaign"
        modalBody={(campaign: CampaignTableRow) => `Do you really want to delete campaign "${campaign?.name}"?`}
        confirmButtonColor="danger"
        confirmButtonDisabled={isDeletingCampaign}
        confirmButtonLabel={isDeletingCampaign ? 'Deleting campaign...' : 'Delete campaign'}
        cancelButtonDisabled={isDeletingCampaign}
        onConfirm={(campaign: CampaignTableRow) => deleteCampaign(campaign)}
      />
    </React.Fragment>
  );
};

export default CampaignsListPage;
