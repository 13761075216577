import React, { FunctionComponent, useState, useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import {
  Edit as EditIcon,
  Trash2 as TrashIcon,
} from "react-feather";

import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import { useApiService } from "../../hooks/useApiService";

import { User } from "../../domain/User";

import { selectUserId } from "../../redux/selectors";
import { userSignOut } from "../../redux/actions/userActions";
import CrudList, { CrudTableState, initialTableState as crudListInitialTableState } from "../../components/crud/CrudList";
import { CrudTableColumnBuilder } from "../../components/crud/CrudTableColumnBuilder";
import ConfirmationModal from "../../components/ConfirmationModal";
import { PagedRequestDTO } from "../../services/api/requests";

const initialTableState: CrudTableState<any> = {
  ...crudListInitialTableState,
  sizePerPageList: [10, 20],
};

type UserTableRow = {
  id: number;
  fullName: string;
  username: string;
  email: string;
  created_at: string;
};

const UsersListPage: FunctionComponent = () => {

  // Users table

  const [users, setUsers] = useState<UserTableRow[]>([]);
  const [tableState, setTableState] = useState<CrudTableState<UserTableRow>>(initialTableState);

  const { url } = useRouteMatch();

  const columns = CrudTableColumnBuilder(tableState)
    .addIndexColumn()
    .addColumn({ text: 'Name', dataField: 'fullName', sort: true, })
    .addColumn({ text: 'Username', dataField: 'username', sort: true })
    .addColumn({ text: 'Email', dataField: 'email', sort: true })
    .addColumn({ text: 'Created at', dataField: 'created_at', sort: true, headerStyle: { width: '250px' } })
    .addActionsColumn((user: UserTableRow) => (
      // TODO: convert to an "addActionColumn" sequence where we pass only the path?: string, icon: Icon, and onClick?: Fn
      <div>
        <Link to={`${url}/update/${user.id}`}>
          <EditIcon size={18} className="mr-1"></EditIcon>
        </Link>
        <Link to="#" onClick={() => deleteUserModalRef.current!.open(user)}>
          <TrashIcon size={18} className="ml-1"></TrashIcon>
        </Link>
      </div>
    ))
    .getColumns();

  // Fetch users logic

  const [fetchUsers, { isLoading: isFetchingUsers }] = useApiService('user', userService => (
    (tableState: CrudTableState<UserTableRow>) => {
      const pagedRequestDTO = mapTableStateToPagedRequest(tableState);
      return userService.fetchUsersPage(pagedRequestDTO).then(usersPage => {
        const totalSize = usersPage.total;

        setUsers(usersPage.data.map(mapUserToUserTableRow));
        setTableState({ ...tableState, totalSize });
      });
    })
  );

  const mapUserToUserTableRow = (user: User) => ({
    id: user.id,
    fullName: user.display_name,
    username: user.username,
    email: user.email,
    created_at: moment(user.created_at).format('MMMM Do YYYY, h:mm:ss a'),
  } as UserTableRow);

  const mapTableStateToPagedRequest = (tableState: CrudTableState<UserTableRow>) => {
    const pagedRequestDTO: PagedRequestDTO<User> = {
      page: tableState.page,
      limit: tableState.sizePerPage,
    };

    if (tableState.sortField && tableState.sortOrder) {
      pagedRequestDTO.sortOptions = tableState.sortField === 'fullName'
        ? [{ field: 'first_name', order: tableState.sortOrder! }, { field: 'last_name', order: tableState.sortOrder! }]
        : [{ field: tableState.sortField, order: tableState.sortOrder! }];
    } else {
      pagedRequestDTO.sortOptions = [{ field: 'id', order: 'desc' }];
    }

    if (tableState.filter) {
      pagedRequestDTO.search = {
        operator: 'or',
        filters: [
          { field: 'first_name', operator: 'like', value: tableState.filter },
          { field: 'last_name', operator: 'like', value: tableState.filter },
          { field: 'username', operator: 'like', value: tableState.filter },
          { field: 'email', operator: 'like', value: tableState.filter },
        ],
      };
    }

    return pagedRequestDTO;
  };

  const onTableChange = (tableState: CrudTableState<UserTableRow>) => fetchUsers(tableState);

  // Delete user logic

  const deleteUserModalRef = useRef<ConfirmationModal>(null);
  const dispatch = useDispatch();
  const authenticatedUserId = useSelector(selectUserId);

  const [deleteUser, { isLoading: isDeletingUser }] = useApiService('user', userService => (
    (user: UserTableRow) => (
      userService.deleteUser(user.id).then(() => displayUserDeletedSuccessMessage(user.id))
    ))
  );

  const displayUserDeletedSuccessMessage = (deletedUserId: number) => {
    if (deletedUserId === authenticatedUserId) {
      toastr.success('Success', 'You were deleted successfully');
      dispatch(userSignOut());
    } else {
      toastr.success('Success', 'User deleted successfully');
      deleteUserModalRef.current!.hide();
      fetchUsers(tableState);
    }
  };

  return (
    <React.Fragment>
      <CrudList
        title="Users"
        addButtonLinkPath="/create"
        addButtonLabel="Add new user"
        tableData={users}
        tableColumns={columns}
        tableIsLoading={isFetchingUsers}
        onTableChange={onTableChange}
        tableState={tableState}
        reduxSyncTableStateKey="users"
        useSimpleFilter
        simpleFilterPlaceholder="Search user..."
      />

      <ConfirmationModal
        ref={deleteUserModalRef}
        title="Delete user"
        modalBody={(user: UserTableRow) => `Do you really want to delete user "${user?.fullName}"?`}
        confirmButtonColor="danger"
        confirmButtonDisabled={isDeletingUser}
        confirmButtonLabel={isDeletingUser ? 'Deleting user...' : 'Delete user'}
        cancelButtonDisabled={isDeletingUser}
        onConfirm={(user: UserTableRow) => deleteUser(user)}
      />
    </React.Fragment>
  );
};

export default UsersListPage;
