import { AxiosInstance } from "axios";

export class ExportService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  exportData() {
    return this.axios.get('/user/export', {
      responseType: 'blob'
    }).then(res => res.data);
  }

}
