import React, { FunctionComponent } from "react";
import classNames from "classnames";

const Wrapper: FunctionComponent = ({ children }) => (
  <div className={classNames({ 'wrapper': true })}>
    {children}
  </div>
);

export default Wrapper;
