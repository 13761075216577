import React, { FunctionComponent, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { Col, Label, Row } from 'reactstrap';

import { InputActionMeta } from 'react-select';

import ImageUploader from 'react-images-upload';

import { AvField, AvForm } from 'availity-reactstrap-validation';

import debounce from 'lodash.debounce';

import { useApiService } from '../../hooks/useApiService';
import { useSafeRedirect } from '../../hooks/useSafeRedirect';
import { useEffectOnMount } from '../../hooks/useEffectOnMount';

import { App } from '../../domain/App';
import { Developer } from '../../domain/Developer';

import { SelectOption } from '../../services/api/responses';

import CrudForm from '../../components/crud/CrudForm';
import CrudFormButtonBar from '../../components/crud/CrudFormButtonBar';
import AvSelect, { AvSelectOption } from '../../components/AvSelect';
import { SaveAppRequestDTO } from '../../services/api/domain/AppService';
import { RecursivePartial } from '../../helpers/recursivePartialType';
import { FileVariationType } from '../../domain/FileVariation';
import { AppTypesPaths, FrontendAppPage, joinPagesPaths } from '../../constants/frontendAppPages';


type AppForm = {
  name: string;
  url: string;
  description: string;
  color: string;
  pwaScore: number;
  likes: number;
  dislikes: number;
  shares: number;
  status: string;

  developer?: AvSelectOption<UserDeveloperListOptionValue | string>;
  categories?: AvSelectOption<number>[];
  tags?: AvSelectOption<number>[];
};
type UserDeveloperListOptionValue = {
  type: string;
  id: number;
};

type UserDeveloperListOption = {
  value: UserDeveloperListOptionValue;
  label: string;
};

const AppsFormPage: FunctionComponent = () => {
  const {id} = useParams();
  const redirectTo = useSafeRedirect();

  const isUpdatingApp = Boolean(id);

  const title = isUpdatingApp ? 'Update app' : 'Create app';

  // App icon logic

  const [icon, setIcon] = useState<File>();
  const [defaultIcon, setDefaultIcon] = useState<string[]>();
  const [iconChanged, setIconChanged] = useState(false);
  const [featuredIconChanged, setFeaturedIconChanged] = useState(false);

  const onDropAppIcon = (files: File[]) => {
    const icon = files[0];
    setIcon(icon);
    setIconChanged(true);
  };

  // App featured icon logic

  const [featuredIcon, setFeaturedIcon] = useState<File>();
  const [defaultFeaturedIcon, setDefaultFeaturedIcon] = useState<string[]>();

  const onDropAppFeaturedIcon = (files: File[]) => {
    const featuredIcon = files[0];
    setFeaturedIcon(featuredIcon);
    setFeaturedIconChanged(true);
  };

  // OBS: App screenshots functionality disabled for now

  // App screenshots logic

  // const [screenshots, setScreenshots] = useState<File[]>([]);
  // const [defaultScreenshots, setDefaultScreenshots] = useState<string[]>();

  // const onDropAppScreenshots = (files: File[]) => {
  //   setScreenshots([...screenshots, ...files]);
  // };

  // Fetch app logic

  const avForm = useRef<{ reset: Function }>(); // TODO: use AvForm type
  const [oldAppData, setOldAppData] = useState<Partial<AppForm>>();
  const [revalidate] = useApiService('revalidation', revalidationService => (
    (paths: string) => revalidationService.revalidate(paths))
  );

  const [fetchAppById, {isLoading: isFetchingApp}] = useApiService('app', appService => (
    (appId: number) => (
      appService.fetchAppById(appId).then(resetFormWithAppData)
    ))
  );

  const resetFormWithAppData = (app: App) => {
    const {description, developer, categories, tags, icon, featuredIcon, screenshots, ...appValues} = app;

    const appForm: AppForm = {
      ...appValues,
      description: description || '',
      developer: developer ? {label: developer.name, value: {type: 'developer', id: developer.id}} : undefined,
      categories: categories.map(c => ({label: c.name, value: c.id, slug: c.slug})),
      tags: tags.map(c => ({label: c.name, value: c.id})),
    };

    if (icon) {
      const iconUrl = icon.variations.find(v => v.variation === FileVariationType.ORIGINAL)!.url;
      setDefaultIcon([iconUrl]);
    }

    if (featuredIcon) {
      const featuredIconUrl = featuredIcon.variations.find(v => v.variation === FileVariationType.ORIGINAL)!.url;
      setDefaultFeaturedIcon([featuredIconUrl]);
    }

    // OBS: App screenshots functionality disabled for now

    // if (screenshots && screenshots.length) {
    //   const screenshotsUrls = screenshots.map(s => s.variations.find(v => v.variation === FileVariationType.ORIGINAL)!.url);
    //   setDefaultScreenshots(screenshotsUrls);
    // }

    setOldAppData(appForm);
    avForm.current!.reset(); // we need to reset the form so AvForm will accept the data we pass into its 'model' prop
  };

  // Fetch status logic

  const [statusList, setStatusList] = useState<SelectOption<string>[]>([]);

  const [fetchStatusList, {isLoading: isFetchingStatusList}] = useApiService('app', appService => (
    () => (
      appService.fetchAppStatus().then(statusList => setStatusList(statusList.map(s => ({label: s, value: s}))))
    ))
  );

  // Fetch users and developers logic

  const [usersList, setUsersList] = useState<UserDeveloperListOption[]>([]);
  const [developersList, setDevelopersList] = useState<UserDeveloperListOption[]>([]);

  const [fetchUsersByName, {isLoading: isFetchingUsers}] = useApiService('user', userService => (
    (name: string) => (
      userService.fetchUsersByName(name).then(users => {
        const usersList = users.map(u => ({
          label: u.display_name,
          value: {type: 'user', id: u.id},
        }));
        setUsersList(usersList);
      })
    )
  ));

  const [fetchDevelopersByName, {isLoading: isFetchingDevelopers}] = useApiService('developer', developerService => (
    (name: string) => (
      developerService.fetchDevelopersByName(name).then(developers => {
        const developersList = developers.map(d => ({
          label: d.name,
          value: {type: 'developer', id: d.id},
        }));
        setDevelopersList(developersList);
      })
    )
  ));

  const onDeveloperInputChanged = debounce((inputValue: string, actionMeta: InputActionMeta) => {
    setUsersList([]);
    setDevelopersList([]);
    if (actionMeta.action === 'input-change') {
      fetchUsersByName(inputValue);
      fetchDevelopersByName(inputValue);
    }
  }, 1000);

  const developerOptions = [{
    label: 'Developers',
    options: developersList
  }, {
    label: 'Users',
    options: usersList,
  }];

  // Fetch categories logic

  const [categories, setCategories] = useState<SelectOption[]>([]);

  const [fetchCategories, {isLoading: isFetchingCategories}] = useApiService('category', categoryService => (
    () => categoryService.fetchCategoriesSelectOptions().then(categories => setCategories(categories))
  ));

  // Fetch tags logic

  const [tags, setTags] = useState<SelectOption[]>([]);

  const [fetchTags, {isLoading: isFetchingTags}] = useApiService('tag', tagService => (
    (name: string) => tagService.fetchTagsSelectOptions(name).then(tags => setTags(tags))
  ));

  const onTagInputChanged = debounce((inputValue: string, actionMeta: InputActionMeta) => {
    setTags([]);
    if (actionMeta.action === 'input-change') {
      fetchTags(inputValue);
    }
  }, 1000);

  // Fetch on mount logic

  useEffectOnMount(() => {
    if (id) {
      fetchAppById(id);
    }
    fetchStatusList();
    fetchCategories();
  });

  // Save app logic

  const [saveApp, {isLoading: isSaving}] = useApiService('app', appService => (
    (appForm: AppForm) => {
      // OBS: App screenshots functionality disabled for now
      // const saveAppRequest = mapAppFormToSaveAppRequest(appForm, icon, screenshots);
      const saveAppRequest = mapAppFormToSaveAppRequest(appForm, icon, featuredIcon, []);
      return isUpdatingApp
        ? appService.updateApp(id, saveAppRequest).then((updatedApp: any) => {
          const appSlug = updatedApp.data.slug;
          const pathsToRevalidate = getPathsForRevalidation(appForm, appSlug);
          revalidate(pathsToRevalidate);
          displaySuccessMessageAndGoBack();
        })
        : appService.createApp(saveAppRequest).then((createdApp: any) => {
          const appSlug = createdApp.data.slug;
          const pathsToRevalidate = getPathsForRevalidation(appForm, appSlug);

          revalidate(pathsToRevalidate);
          displaySuccessMessageAndGoBack();
        });
    })
  );

  const getPathsForRevalidation = (appForm: AppForm, appSlug: string) => {
    const appCategories = appForm?.categories?.map(category => category.slug) || [];
    const oldAppCategories = oldAppData?.categories?.map(category => category.slug) || [];
    // @ts-ignore
    const categoriesToRevalidate = [...new Set([...appCategories, ...oldAppCategories])];

    const categoriesPathToRevalidate = joinPagesPaths(categoriesToRevalidate.map(category => FrontendAppPage.SINGLE_CATEGORY(category)));

    return joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS, FrontendAppPage.CATEGORIES, categoriesPathToRevalidate, AppTypesPaths, FrontendAppPage.SINGLE_APP(appSlug)]);
  }

  const mapAppFormToSaveAppRequest = (appForm: AppForm, icon?: File, featuredIcon?: File, screenshots?: File[]): SaveAppRequestDTO => {
    const {developer, categories, tags, pwaScore, likes, dislikes, shares, ...appFormValues} = appForm;

    return {
      ...appFormValues,
      pwaScore: Number(pwaScore),
      likes: Number(likes),
      dislikes: Number(dislikes),
      shares: Number(shares),
      developer: getDeveloperFromDeveloperField(appForm.developer!),
      categories: (categories && categories.map(c => ({id: c.value}))) || [],
      tags: (tags && tags.map(c => ({id: c.value}))) || [],
      icon,
      featuredIcon,
      screenshots,
      featured_icon_removed: (isUpdatingApp && featuredIconChanged && !featuredIcon) ?? undefined
    };
  };

  const getDeveloperFromDeveloperField = (developerField: AvSelectOption<UserDeveloperListOptionValue | string>): RecursivePartial<Developer> => {
    if (developerField.__isNew__) {
      return {
        name: String(developerField.value),
      };
    }

    const developerFieldLabel = developerField.label;
    const developerFieldValue = developerField.value as UserDeveloperListOptionValue;

    return developerFieldValue.type === 'developer'
      ? {
        name: developerFieldLabel,
        id: developerFieldValue.id,
      }
      : {
        name: developerFieldLabel,
        user: {id: developerFieldValue.id},
      };
  };

  const displaySuccessMessageAndGoBack = () => {
    toastr.success('Success', `App ${id ? 'updated' : 'created'} successfully`);
    redirectTo('/apps');
  };

  const onSubmit = (_: React.FormEvent<HTMLFormElement>, appForm: AppForm) => {
    const userHasntCreatedAppIcon = !isUpdatingApp && !icon;
    const userHasRemovedAppIcon = isUpdatingApp && iconChanged && !icon;

    if (userHasntCreatedAppIcon || userHasRemovedAppIcon) {
      toastr.warning('Attention', 'Please choose an app icon');
    } else {
      saveApp(appForm);
    }
  };

  const isFetching = isFetchingApp || isFetchingStatusList;
  const isBusy = isFetching || isSaving;

  return (
    <CrudForm title={title} showSpinner={isFetching}>
      <AvForm onValidSubmit={onSubmit} ref={avForm} model={oldAppData}>
        <Row form>
          <Col md="12" lg="6">
            <Label>App icon</Label>
            <ImageUploader
              withIcon={false}
              withPreview
              singleImage
              onChange={onDropAppIcon}
              imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
              maxFileSize={5242880}
              buttonText="Choose icon"
              label="Max file size: 5mb, accepted: jpg | gif | png"
              defaultImages={defaultIcon}
              className="circular"
            />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="4">
            <AvField label="Name" name="name" type="text" validate={{
              required: {value: true, errorMessage: 'The name is required'},
              maxLength: {value: 255, errorMessage: 'The name must be less than 255 characters'},
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="4">
            <AvSelect
              isCreatable
              name="developer"
              label="Developer"
              isClearable
              options={developerOptions}
              isLoading={isFetchingUsers || isFetchingDevelopers}
              placeholder="Type to search..."
              onInputChange={onDeveloperInputChanged}
              required
              errorMessage="The developer is required"
            />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="3">
            <AvField type="select" name="status" label="Status" required errorMessage="The status is required">
              <option value="">Pick a status...</option>
              {statusList.map(status => (
                <option key={status.value} value={status.value}>{status.label}</option>
              ))}
            </AvField>
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="6">
            <AvField label="URL" name="url" type="text" validate={{
              required: {value: true, errorMessage: 'The url is required'},
              pattern: {
                value: '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
                errorMessage: 'The url must be in a valid format'
              },
              maxLength: {value: 1024, errorMessage: 'The url must be less than 1024 characters'},
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="6">
            <AvField label="Description" name="description" type="textarea" rows="5" validate={{
              maxLength: {value: 65536, errorMessage: 'The description must be less than 65536 characters'},
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="3">
            <AvField label="PWA Score" name="pwaScore" type="number" validate={{
              required: {value: true, errorMessage: 'The PWA score is required'},
              min: {value: 0, errorMessage: 'The PWA score must be greater than zero'},
            }} />
          </Col>
          <Col md="12" lg="3">
            <AvField label="Likes" name="likes" type="number" validate={{
              required: {value: true, errorMessage: 'The number of likes is required'},
              min: {value: 0, errorMessage: 'The number of likes must be greater than zero'},
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="3">
            <AvField label="Dislikes" name="dislikes" type="number" validate={{
              required: {value: true, errorMessage: 'The number of dislikes is required'},
              min: {value: 0, errorMessage: 'The number of dislikes must be greater than zero'},
            }} />
          </Col>
          <Col md="12" lg="3">
            <AvField label="Shares" name="shares" type="number" validate={{
              required: {value: true, errorMessage: 'The number of shares is required'},
              min: {value: 0, errorMessage: 'The number of shares must be greater than zero'},
            }} />
          </Col>
        </Row>


        <Row form>
          <Col md="12" lg="6">
            <Label>Featured icon</Label>
            <ImageUploader
              withIcon={false}
              withPreview
              singleImage
              onChange={onDropAppFeaturedIcon}
              imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
              maxFileSize={5242880}
              buttonText="Choose featured icon"
              label="Max file size: 5mb, accepted: jpg | gif | png"
              defaultImages={defaultFeaturedIcon}
              className="fullWidth"
            />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="2">
            <AvField label="Featured title color" name="color" type="color" validate={{
              required: {value: false, errorMessage: 'The color is required'},
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="6">
            <AvSelect
              name="categories"
              label="Categories"
              isMulti
              options={categories}
              isLoading={isFetchingCategories}
            />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="6">
            <AvSelect
              name="tags"
              label="Tags"
              isMulti
              options={tags}
              isLoading={isFetchingTags}
              placeholder="Type to search..."
              onInputChange={onTagInputChanged}
              noOptionsMessage={({inputValue}) => inputValue ? 'No options' : 'Type to search...'}
            />
          </Col>
        </Row>

        {/* OBS: App screenshots functionality disabled for now */}
        {/* <Row form>
          <Col md="12" lg="8">
            <Label>App screenshots</Label>
            <ImageUploader
              withIcon={false}
              withPreview
              onChange={onDropAppScreenshots}
              imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
              maxFileSize={5242880}
              buttonText="Choose screenshots"
              label="Max file size: 5mb, accepted: jpg | gif | png"
              defaultImages={defaultScreenshots}
            />
          </Col>
        </Row> */}

        <CrudFormButtonBar
          isSaveButtonDisabled={isBusy}
          isCancelButtonDisabled={isBusy}
          saveButtonLabel={isSaving ? 'Saving...' : 'Save'}
          cancelButtonRoutePath={'/apps'}
        />
      </AvForm>
    </CrudForm>
  );
};

export default AppsFormPage;
