import React from "react";
import { CrudTableState } from "./CrudList";

export const CrudTableColumnBuilder = (tableState: CrudTableState<any>) => {
  const columns: any[] = []; // TODO: use high-quality typed react-bootstrap-table-next
  const currentPageIndex = (tableState.page - 1) * tableState.sizePerPage;
  const compactStyle = { width: '1px', whiteSpace: 'nowrap', textAlign: 'center' };
  let actions: any[] = [];
  let actionColumn: any;

  const crudTableColumnDecorator = {

    addIndexColumn() {
      columns.push({
        text: '#',
        dataField: 'id',
        formatter: (_: any, __: any, i: number, currentPageIndex: any) => currentPageIndex + i + 1,
        formatExtraData: currentPageIndex,
        align: 'right',
        headerStyle: compactStyle,
      })
      return crudTableColumnDecorator;
    },

    addColumn(column: any) { // TODO: use high-quality typed react-bootstrap-table-next
      columns.push(column);
      return crudTableColumnDecorator;
    },

    addAction<T>(formatter: (row: T) => JSX.Element) { // TODO: use high-quality typed react-bootstrap-table-next
      actions.push(formatter);
      return crudTableColumnDecorator;
    },

    addActionsColumn<T>(formatter: (row: T) => JSX.Element, width = '1px') {
      actionColumn = {
        text: 'Actions',
        dataField: '__unusedActionField__',
        isDummyField: true,
        formatter: (cell: any, row: T) => formatter(row),
        headerStyle: { ...compactStyle, width },
        style: { textAlign: 'center' },
      };
      return crudTableColumnDecorator;
    },

    getColumns() {
      if (actionColumn) {
        return [...columns, actionColumn];
      }

      if (actions.length) {
        const actionColumn = {
          text: 'Actions',
          dataField: '__unusedActionField__',
          isDummyField: true,
          formatter: (cell: any, row: any) => actions.map((actionFormatter, i) => (
            <React.Fragment key={i}>
              {actionFormatter(row)}
            </React.Fragment>
          )),
          headerStyle: { ...compactStyle }, // TODO: what about custom widths?
          style: { textAlign: 'center' },
        };
        return [...columns, actionColumn];
      }

      return columns;
    },
  };

  return crudTableColumnDecorator;
};

