import React from 'react';
import { CardBody, CardTitle, Spinner } from 'reactstrap';
import { SendingProgressType } from 'pages/newsletter/NewsletterPage';

export const SendingNewsletterProgress = ({ sent, total }: SendingProgressType) => {
  return <CardBody className="d-flex flex-column align-items-center justify-content-center">
    <CardTitle>Send out in progress</CardTitle>
    <Spinner size="lg" />
    <CardTitle>{sent} sent out of {total} total</CardTitle>
  </CardBody>;
};
