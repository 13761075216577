
import { File } from "../../domain/File";

// action types
export enum UserActionTypes {
  USER_SIGN_IN = "USER_SIGN_IN",
  USER_SIGN_OUT = "USER_SIGN_OUT",
  USER_FETCH_AVATAR = "USER_FETCH_AVATAR",
}

// action payload
export type SignInActionPayload = {
  access_token: string;
};

export type SignInAction = { type: typeof UserActionTypes.USER_SIGN_IN, payload: SignInActionPayload };
export type SignOutAction = { type: typeof UserActionTypes.USER_SIGN_OUT, };
export type FetchAvatarAction = { type: typeof UserActionTypes.USER_FETCH_AVATAR, payload: File };

export type UserActions = SignInAction | SignOutAction | FetchAvatarAction;

// action creators
export const userSignIn = (user: SignInActionPayload) => ({ type: UserActionTypes.USER_SIGN_IN, payload: { ...user } });
export const userSignOut = () => ({ type: UserActionTypes.USER_SIGN_OUT });
export const userFetchAvatar = (avatar: File) => ({ type: UserActionTypes.USER_FETCH_AVATAR, payload: avatar });
