import React, { FunctionComponent, useState, useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Edit as EditIcon, Trash2 as TrashIcon } from "react-feather";
import { toastr } from "react-redux-toastr";

import moment from 'moment';

import CrudList, { CrudTableState, initialTableState } from "../../components/crud/CrudList";
import { CrudTableColumnBuilder } from "../../components/crud/CrudTableColumnBuilder";

import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import { PagedRequestDTO } from "../../services/api/requests";

import ConfirmationModal from "../../components/ConfirmationModal";

import { Category } from "../../domain/Category";
import { FrontendAppPage, joinPagesPaths } from '../../constants/frontendAppPages';

type CategoryTableRow = {
  id: number;
  name: string;
  updated_at: string;
  slug: string;
};

const CategoriesListPage: FunctionComponent = () => {

  // Categories table

  const [categories, setCategories] = useState<CategoryTableRow[]>([]);
  const [tableState, setTableState] = useState<CrudTableState<CategoryTableRow>>(initialTableState);

  const { url } = useRouteMatch();

  const columns = CrudTableColumnBuilder(tableState)
    .addIndexColumn()
    .addColumn({ text: 'Name', dataField: 'name', sort: true, })
    .addColumn({ text: 'Updated at', dataField: 'updated_at', sort: true, headerStyle: { width: '250px' } })
    .addActionsColumn((category: CategoryTableRow) => (
      // TODO: convert to an "addActionColumn" sequence where we pass only the path?: string, icon: Icon, and onClick?: Fn
      <div>
        <Link to={`${url}/update/${category.id}`}>
          <EditIcon size={18} className="mr-1"></EditIcon>
        </Link>
        <Link to="#" onClick={() => deleteCategoryModalRef.current!.open(category)}>
          <TrashIcon size={18} className="ml-1"></TrashIcon>
        </Link>
      </div>
    ))
    .getColumns();

  // Fetch categories logic

  const [fetchCategories, { isLoading: isFetchingCategories }] = useApiService('category', categoryService => (
    (tableState: CrudTableState<CategoryTableRow>) => {
      const pagedRequestDTO = mapTableStateToPagedRequest(tableState);
      return categoryService.fetchCategoriesPage(pagedRequestDTO).then(categoriesPage => {
        const totalSize = categoriesPage.total;
        setCategories(categoriesPage.data.map(mapCategoryToCategoryTableRow));
        setTableState({ ...tableState, totalSize });
      });
    })
  );

  const [revalidate] = useApiService('revalidation', revalidationService => (
    (paths: string) => revalidationService.revalidate(paths))
  );

  const mapCategoryToCategoryTableRow = (category: Category) => ({
    id: category.id,
    name: category.name,
    updated_at: moment(category.updated_at).format('MMMM Do YYYY, h:mm:ss a'),
    slug: category.slug
  } as CategoryTableRow);

  const mapTableStateToPagedRequest = (tableState: CrudTableState<CategoryTableRow>) => {
    const pagedRequestDTO: PagedRequestDTO<Category> = {
      page: tableState.page,
      limit: tableState.sizePerPage,
    };
    if (tableState.filter) {
      pagedRequestDTO.search = {
        operator: 'or',
        filters: [
          { field: 'name', operator: 'like', value: tableState.filter },
          { field: 'slug', operator: 'like', value: tableState.filter },
        ],
      };
    }
    if (tableState.sortField && tableState.sortOrder) {
      pagedRequestDTO.sortOptions = [{ field: tableState.sortField, order: tableState.sortOrder }];
    }
    return pagedRequestDTO;
  };

  const onTableChange = (tableState: CrudTableState<CategoryTableRow>) => fetchCategories(tableState);
  useEffectOnMount(() => fetchCategories(tableState));

  // Delete category logic

  const deleteCategoryModalRef = useRef<ConfirmationModal>(null);

  const [deleteCategory, { isLoading: isDeletingCategory }] = useApiService('category', categoryService => (
    (category: CategoryTableRow) => (
      categoryService.deleteCategory(category.id).then(() => {
        const categorySlug = category.slug
        const pathsToRevalidate = joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS, FrontendAppPage.CATEGORIES, FrontendAppPage.SINGLE_CATEGORY(categorySlug)]);
        revalidate(pathsToRevalidate);
        displayCategoryDeletedSuccessMessage()
      })
    ))
  );

  const displayCategoryDeletedSuccessMessage = () => {
    toastr.success('Success', 'Category deleted successfully');
    deleteCategoryModalRef.current!.hide();
    fetchCategories(tableState);
  };

  return (
    <React.Fragment>
      <CrudList
        title="Categories"
        addButtonLinkPath="/create"
        addButtonLabel="Add new category"
        tableData={categories}
        tableColumns={columns}
        tableIsLoading={isFetchingCategories}
        onTableChange={onTableChange}
        tableState={tableState}
        reduxSyncTableStateKey="categories"
        useSimpleFilter
        simpleFilterPlaceholder="Filter by name..."
      />

      <ConfirmationModal
        ref={deleteCategoryModalRef}
        title="Delete category"
        modalBody={(category: CategoryTableRow) => `Do you really want to delete category "${category?.name}"?`}
        confirmButtonColor="danger"
        confirmButtonDisabled={isDeletingCategory}
        confirmButtonLabel={isDeletingCategory ? 'Deleting category...' : 'Delete category'}
        cancelButtonDisabled={isDeletingCategory}
        onConfirm={(category: CategoryTableRow) => deleteCategory(category)}
      />
    </React.Fragment>
  )
};

export default CategoriesListPage;
