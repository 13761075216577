import React, { FunctionComponent, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import { Row, Col } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { useSafeRedirect } from "../../hooks/useSafeRedirect";
import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import CrudForm from "../../components/crud/CrudForm";
import CrudFormButtonBar from "../../components/crud/CrudFormButtonBar";

import { Tag } from "../../domain/Tag";

type TagForm = {
  name: string;
  description: string;
};

const TagFormPage: FunctionComponent = () => {
  const { id } = useParams();
  const redirectTo = useSafeRedirect();

  const isUpdatingTag = Boolean(id);

  const title = isUpdatingTag ? 'Update tag' : 'Create tag';

  const avForm = useRef<{ reset: Function }>(); // TODO: use AvForm type
  const [oldTagData, setOldTagData] = useState<TagForm>();

  // Fetch tag logic

  const [fetchTagById, { isLoading: isFetchingTag }] = useApiService('tag', tagService => (
    (tagId: number) => (
      tagService.fetchTagById(tagId).then(resetFormWithTagData)
    ))
  );

  useEffectOnMount(() => {
    if (id) {
      fetchTagById(id);
    }
  });

  const resetFormWithTagData = (tag: Tag) => {
    const tagForm = {
      ...tag,
    };
    setOldTagData(tagForm);
    avForm.current!.reset(); // we need to reset the form so AvForm will accept the data we pass into its 'model' prop
  };

  // Save tag logic

  const [saveTag, { isLoading: isSaving }] = useApiService('tag', tagService => (
    (tagForm: TagForm) => {
      return isUpdatingTag
        ? tagService.updateTag(id, tagForm).then(displaySuccessMessageAndGoBack)
        : tagService.createTag(tagForm).then(displaySuccessMessageAndGoBack);
    })
  );

  const displaySuccessMessageAndGoBack = () => {
    toastr.success('Success', `Tag ${id ? 'updated' : 'created'} successfully`);
    redirectTo('/apps/tags');
  };

  const onSubmit = (_: React.FormEvent<HTMLFormElement>, tagForm: TagForm) => {
    saveTag(tagForm);
  };

  const isBusy = isFetchingTag || isSaving;

  return (
    <CrudForm title={title} showSpinner={isFetchingTag}>
      <AvForm onValidSubmit={onSubmit} ref={avForm} model={oldTagData}>

        <Row form>
          <Col md="12" lg="3">
            <AvField label="Name" name="name" type="text" validate={{
              required: { value: true, errorMessage: 'The name is required' },
              maxLength: { value: 255, errorMessage: 'The name must be less than 255 characters' },
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="5">
            <AvField label="Description" name="description" type="textarea" rows="5" validate={{
              maxLength: { value: 65536, errorMessage: 'The description must be less than 65536 characters' },
            }} />
          </Col>
        </Row>

        <CrudFormButtonBar
          isSaveButtonDisabled={isBusy}
          isCancelButtonDisabled={isBusy}
          saveButtonLabel={isSaving ? 'Saving...' : 'Save'}
          cancelButtonRoutePath={'/apps/tags'}
        />
      </AvForm>
    </CrudForm>
  );
};

export default TagFormPage;
