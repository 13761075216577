// action types
export enum SidebarActionTypes {
  SIDEBAR_VISIBILITY_TOGGLE = 'SIDEBAR_VISIBILITY_TOGGLE',
}

// actions
export type ToggleSidebarAction = { type: typeof SidebarActionTypes.SIDEBAR_VISIBILITY_TOGGLE };

export type SidebarActions = ToggleSidebarAction;

// action creators
export const toggleSidebar = () => ({ type: SidebarActionTypes.SIDEBAR_VISIBILITY_TOGGLE });
