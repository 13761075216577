import React, { FunctionComponent } from "react";
import { Container, Card, CardHeader, CardTitle, Spinner, CardBody } from "reactstrap";

type CrudFormProps = {
  title: string;
  showSpinner: boolean;
};

const CrudForm: FunctionComponent<CrudFormProps> = ({ title, showSpinner, children }) => {
  return (
    <Container className="p-0">
      <Card>
        <CardHeader className="d-flex align-items-center mt-3">
          <CardTitle tag="h2" className="mb-0">{title}</CardTitle>
          {showSpinner && <Spinner color="primary" size="sm" className="ml-2" />}
        </CardHeader>

        <CardBody>
          {children}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CrudForm;
