import React, { MouseEventHandler } from 'react';

import Select, {
  components, InputActionMeta,
  MultiValueProps,
  Props,
} from 'react-select';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc';
import { SelectOption } from 'services/api/responses';


function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<SelectOption>) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  }
);

const SortableMultiValueLabel = SortableHandle(
  (props: any) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<Props<SelectOption> & SortableContainerProps>;

type AppsSelectProps = {
  className: string;
  isLoading: boolean,
  isDisabled: boolean,
  value: SelectOption[];
  options: SelectOption[];
  onChange: any,
  onInputChange: (inputValue: string, actionMeta: InputActionMeta) => void
};
export const SortableMultiSelect = ({ className, options, value, onChange, onInputChange, isLoading, isDisabled }: AppsSelectProps) => {
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const sortedValue = arrayMove(value, oldIndex, newIndex);
    onChange(sortedValue);
  };

  return (
    <SortableSelect
      className={className}
      classNamePrefix="react-select"
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      isLoading={isLoading}
      isDisabled={isDisabled}
      options={options}
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      components={{
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      closeMenuOnSelect={false}
    />
  );
}
