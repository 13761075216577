import { AxiosInstance } from "axios";
import { createRequestQuery, PagedRequestDTO, PagedRequestJoinOption } from "../requests";
import { PagedResponseDTO } from "../responses";

import { Campaign } from "../../../domain/Campaign";

import { RecursivePartial } from "../../../helpers/recursivePartialType";

export type SaveAppRequestDTO = RecursivePartial<Omit<Campaign, 'icon' | 'featuredIcon' | 'screenshots' | 'slug' | 'installed' | 'computed' | 'created_at' | 'updated_at'> & { icon: File, featuredIcon: File, screenshots: File[], featured_icon_removed?: Boolean }>;

export class CampaignService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchCampaignsPage(pagedRequestDTO: PagedRequestDTO<Campaign>): Promise<PagedResponseDTO<Campaign>> {

    const fields: (keyof Campaign)[] = ['banner', 'start_date', 'finish_date', 'created_at', 'search_result', 'news_letter'];

    const join: PagedRequestJoinOption<Campaign>[] = [{ relation: 'payment' }];

    const requestQuery = createRequestQuery({ ...pagedRequestDTO, fields, join });

    return this.axios.get<PagedResponseDTO<Campaign>>(`/campaign?${requestQuery}`).then(res => res.data);
  }

  fetchCampaignStatus(): Promise<string[]> {
    return this.axios.get<string[]>(`/campaign/status`).then(res => res.data);
  }

  fetchCampaignById(appId: number): Promise<Campaign> {
    const join: PagedRequestJoinOption<Campaign>[] = [
      { relation: 'developer' },
      { relation: 'categories' },
      { relation: 'tags' },
      { relation: 'screenshots' },
      { relation: 'featuredIcon' },
    ];

    const requestQuery = createRequestQuery({ join });

    return this.axios.get<Campaign>(`/app/${appId}?${requestQuery}`).then(res => res.data);
  }

  updateCampaign(appId: number, app: SaveAppRequestDTO) {
    const { icon, featuredIcon, screenshots, ...appValues } = app;

    const formData = new FormData();
    Object.entries(appValues).forEach(([ key, value ]) => {
      if (value) {
        if (typeof value === 'object' || Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value as string | File);
        }
      }
    });

    if (icon) {
      formData.append('icon', icon as File);
    }

    if (featuredIcon) {
      formData.append('featuredIcon', featuredIcon as File);
    }

    if (screenshots) {
      screenshots.forEach(s => formData.append('screenshots', s as File));
    }

    return this.axios.patch(`/app/${appId}`, formData);
  }

  deleteCampaign(appId: number) {
    return this.axios.delete(`/campaign/${appId}`);
  }

}
