import FileSaver from 'file-saver';
import React, { FunctionComponent }  from "react";

import { useApiService } from "../../hooks/useApiService";

import { Container, Card, CardHeader, CardTitle, CardBody, CardText, Button, Spinner } from "reactstrap";

const ExportDataPage: FunctionComponent = () => {

  const [exportUsers, { isLoading: isFetchingExport }] = useApiService('export', (exportService) => (
    () => {
      return exportService.exportData().then(exportedData => {
        FileSaver.saveAs(exportedData, 'export.csv');
      });
    })
  );

  function onExport () {
    exportUsers();
  }

  return (
    <Container className="p-0">
      <Card>
        <CardHeader className="d-flex align-items-center mt-3">
          <CardTitle tag="h2" className="mb-0">Export Data</CardTitle>
        </CardHeader>

        <CardBody>
          <CardText>
            You can export the following data in CSV file format. <br/>
            It is not recommended to export users during high usage period and/or multiple exports at the same time.
          </CardText>
          <Button className={'d-flex align-items-center'} color={'primary'} disabled={isFetchingExport} onClick={onExport}>
            { isFetchingExport ? <Spinner size="sm" color="light" style={{ marginBottom: '2px' }}/> : null}
            &nbsp;
            { isFetchingExport ? 'Exporting Users...' : 'Export Users' }
          </Button>
        </CardBody>
      </Card>
    </Container>
  );
}

export default ExportDataPage;
