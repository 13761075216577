import axios from "axios";
import store from "../../redux/store";
import { selectAccessToken } from "../../redux/selectors";
import { toastr } from "react-redux-toastr";
import { userSignOut } from "../../redux/actions/userActions";

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request Interceptors
axiosConfig.interceptors.request.use(
  config => {
    const accessToken = selectAccessToken(store.getState());

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

// Response Interceptors
axiosConfig.interceptors.response.use(
  response => response,
  error => {
    const isLoginRequest = error.request && error.request.responseURL.endsWith('/auth/login');
    const isResetPasswordRequest = error.request && error.request.responseURL.endsWith('/user/password');

    // don't handle error if user is on login or reset password request
    if (isLoginRequest || isResetPasswordRequest) {
      return Promise.reject(error);
    }

    const isConnectionOffline = error.toString() === 'Error: Network Error';

    if (isConnectionOffline) {
      toastr.error('Error', 'Can\'t connect with application servers. Please contact systems admin.', { timeOut: 0 });
      return Promise.reject(error);
    }

    const isUserUnauthorized = error.response && error.response.status === 401;

    if (isUserUnauthorized) {
      toastr.error('Session', 'Session expired, please sign in again.', {
        removeOnHover: false,
        onHideComplete: () => {
          store.dispatch(userSignOut());
        }
      });
      return Promise.reject(error);
    }

    const isErrorMessageArray = Array.isArray(error.response.data && error.response.data.message);

    if (isErrorMessageArray) {
      error.response.data.message.forEach((errorMessage: string) => {
        toastr.error('Error', errorMessage);
      });
    } else {
      const errorMessage = (error.response.data && error.response.data.message) || 'Unknown error';
      toastr.error('Error', errorMessage);
    }

    console.log('Axios request error: ', error);
    console.log('Axios request error.response: ', error.response);

    return Promise.reject(error);
  },
);

export default axiosConfig;
