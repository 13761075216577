import { App } from "./App";

export enum ListType {
  HOME = 'Home',
  HYBRID = 'Hybrid',
  MY_APPS = 'My Apps',
}

export type List = {
  id: number;
  name: string;
  slug: string;
  type: ListType;
  description: string;
  created_at: Date;
  updated_at: Date;
  apps: App[];
};
