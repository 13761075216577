import React, { FunctionComponent }  from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "../../hooks/usePrevious";

const ScrollToTop: FunctionComponent = ({ children }) => {
  const currentLocation = useLocation();
  const prevLocation = usePrevious(currentLocation);

  if (prevLocation !== currentLocation) {
    window.scrollTo(0, 0);
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default ScrollToTop;
