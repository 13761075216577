import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, Container, Spinner } from 'reactstrap';
import { useApiService } from 'hooks/useApiService';
import { NoActiveNewsletter } from 'pages/newsletter/NoActiveNewsletter';
import { SendingNewsletterProgress } from 'pages/newsletter/SendingNewsletterProgress';

export type SendingProgressType = {
  sent: number,
  total: number
}

const NewsletterPage = () => {
  const [isSendingNewsletter, setIsSendingNewsletter] = useState(false);
  const [sendingProgress, setSendingProgress] = useState<SendingProgressType>({ sent: 0, total: 0 });
  const [getNewsletterStatus, { isLoading: isLoadingNewsletterStatus }] = useApiService('newsletter', (newsletterService) => () => {
    return newsletterService.getNewsletterStatus().then(newsletterStatusResponse => {
      if (!newsletterStatusResponse.data) {
        setIsSendingNewsletter(false);
        return;
      }
      setSendingProgress(newsletterStatusResponse.data as SendingProgressType);
      setIsSendingNewsletter(true);
    });
  });

  useEffect(() => {
    getNewsletterStatus();
  }, []);

  return <Container className="p-0">
    <Card>
      <CardHeader className="d-flex align-items-center mt-3">
        <CardTitle tag="h2" className="mb-0">Newsletter</CardTitle>
        {isLoadingNewsletterStatus &&
          <Spinner color="primary" size="sm" className="ml-2" />}
      </CardHeader>
      {!isLoadingNewsletterStatus && (
        isSendingNewsletter ? <SendingNewsletterProgress sent={sendingProgress.sent} total={sendingProgress.total} /> :
          <NoActiveNewsletter getNewsletterStatus={getNewsletterStatus} />
      )}
    </Card>
  </Container>;
};

export default NewsletterPage;
