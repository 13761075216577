import { RequestQueryBuilder, CondOperator, QuerySortOperator, QueryJoin, SConditionKey, SCondition } from "@nestjsx/crud-request";

// TODO: separate all this into its own individual files

export type PagedRequestSortOrder = 'asc' | 'desc';
export type PagedRequestFilterCombinationOperator = 'and' | 'or';

type PagedRequestFilterOperatorWithValue = 'equals' | 'like' | 'in';
type PagedRequestFilterOperatorWithoutValue = 'not null';

export type PagedRequestFilterOperator = PagedRequestFilterOperatorWithValue | PagedRequestFilterOperatorWithoutValue;

type PagedRequestSimpleFilterWithValue<T> = {
  field: string; // TODO: try to revert to keyof T (what about join=relation.id?)
  operator: PagedRequestFilterOperatorWithValue;
  value: any;
}

type PagedRequestSimpleFilterWithoutValue<T> = {
  field: string; // TODO: try to revert to keyof T (what about join=relation.id?)
  operator: PagedRequestFilterOperatorWithoutValue;
}

export type PagedRequestSimpleFilter<T> = PagedRequestSimpleFilterWithValue<T> | PagedRequestSimpleFilterWithoutValue<T>;

export type PagedRequestFilterCombination<T> = {
  operator: PagedRequestFilterCombinationOperator;
  filters: PagedRequestSearch<T>[];
};

export type PagedRequestSortOption<T> = {
  field: string; // TODO: try to revert to keyof T (what about sort=relation.name?)
  order: PagedRequestSortOrder;
}

export type PagedRequestJoinOption<T> = {
  relation: string; // TODO: try to revert to keyof T (what about join=relation.otherRelaion?)
  fields?: string[]; // TODO: try to revert to keyof T[keyof T]
}

export type PagedRequestSearch<T> = PagedRequestFilterCombination<T> | PagedRequestSimpleFilter<T>

export type PagedRequestDTO<T> = {
  fields?: string[]; // TODO: try to revert to keyof T (what about join=relation.id?)
  page?: number;
  limit?: number;
  sortOptions?: PagedRequestSortOption<T>[];
  filter?: PagedRequestSimpleFilter<T>[];
  search?: PagedRequestSearch<T>;
  join?: PagedRequestJoinOption<T>[];
}

// TODO: separate this into its own file

const transformSortOrder = (sortOrder: PagedRequestSortOrder) => {
  if (sortOrder === 'asc')
    return 'ASC' as QuerySortOperator;
  if (sortOrder === 'desc')
    return 'DESC' as QuerySortOperator;

  throw new Error(`Invalid sort order: ${sortOrder}`)
}

const transformSimpleFilterOperator = (filterOperator: PagedRequestFilterOperator) => {
  if (filterOperator === 'equals') return CondOperator.EQUALS;
  if (filterOperator === 'like') return CondOperator.CONTAINS;
  if (filterOperator === 'in') return CondOperator.IN;
  if (filterOperator === 'not null') return CondOperator.NOT_NULL;

  throw new Error(`Invalid filter operator: ${filterOperator}`);
}

const transformCombinationFilterOperator = (filterOperator: PagedRequestFilterCombinationOperator): SConditionKey => {
  if (filterOperator === 'and') return '$and';
  if (filterOperator === 'or') return '$or';

  throw new Error(`Invalid combination filter operator: ${filterOperator}`);
}

const isCombinationOperator = (filterOperator: PagedRequestFilterOperator | PagedRequestFilterCombinationOperator): filterOperator is PagedRequestFilterCombinationOperator => {
  return filterOperator === 'and' || filterOperator === 'or';
}
const isCombinationFilter = <T>(search: PagedRequestSearch<T>): search is PagedRequestFilterCombination<T> => {
  return isCombinationOperator(search.operator);
}
const isFilterOperatorWithValue = (filterOperator: PagedRequestFilterOperator): filterOperator is PagedRequestFilterOperatorWithValue => {
  return filterOperator !== 'not null';
}
const filterHasValue = <T>(filter: PagedRequestSimpleFilter<T>): filter is PagedRequestSimpleFilterWithValue<T> => {
  return isFilterOperatorWithValue(filter.operator);
}

export function createRequestQuery<T>(pagedRequestDTO: PagedRequestDTO<T>): string {
  let requestQuery = RequestQueryBuilder.create();


  if (pagedRequestDTO.page) {
    requestQuery = requestQuery.setPage(pagedRequestDTO.page);
  }

  if (pagedRequestDTO.limit) {
    requestQuery = requestQuery.setLimit(pagedRequestDTO.limit);
  }

  if (pagedRequestDTO.fields) {
    requestQuery = requestQuery.select(pagedRequestDTO.fields.map(f => f.toString()));
  }

  if (pagedRequestDTO.filter) {
    requestQuery = requestQuery.setFilter(pagedRequestDTO.filter.map(f => ({
      field: f.field.toString(),
      operator: transformSimpleFilterOperator(f.operator),
      value: filterHasValue(f) ? f.value : true,
    })));
  }

  if (pagedRequestDTO.search) {
    const buildRequestSearch = (search: PagedRequestSearch<T>): SCondition => {
      if (isCombinationFilter(search)) {
        const requestSearchField = transformCombinationFilterOperator(search.operator);
        const requestSearchFilters = search.filters.map(f => buildRequestSearch(f));
        return {
          [requestSearchField]: requestSearchFilters,
        }
      } else {
        const requestSearchOperator = transformSimpleFilterOperator(search.operator);
        const requestSearchField = search.field;
        const requestSearchValue = filterHasValue(search) ? search.value : true;
        return {
          [requestSearchField]: { [requestSearchOperator]: requestSearchValue }
        }
      }
    };

    requestQuery = requestQuery.search(buildRequestSearch(pagedRequestDTO.search));
  }

  if (pagedRequestDTO.sortOptions) {
    const sortOptions = pagedRequestDTO.sortOptions.map(s => ({
      field: s.field.toString(),
      order: transformSortOrder(s.order),
    }));

    requestQuery = requestQuery.sortBy(sortOptions);
  }

  if (pagedRequestDTO.join) {
    pagedRequestDTO.join.forEach(j => {
      let queryJoin: QueryJoin = { field: j.relation.toString() };
      if (j.fields) {
        queryJoin = { ...queryJoin, select: j.fields.map(f => f.toString()) };
      }

      requestQuery = requestQuery.setJoin(queryJoin);
    });
  }

  return requestQuery.query(); // TODO: use .env to pass false to query method (encoded: false)
}
