import React, { FunctionComponent } from 'react';
import { Badge } from 'reactstrap';
import { ImportLogStatus } from '../../domain/ImportLog';

type Props = {
  status?: ImportLogStatus,
};

const ImportDataStatusBadge: FunctionComponent<Props> = ({ status }) => {
  const getColor = () => {
    switch (status) {
      case ImportLogStatus.RUNNING: return 'secondary';
      case ImportLogStatus.FINISHED: return 'success';
      case ImportLogStatus.ERROR: return 'danger';
      default:
        return '';
    }
  };
  return (
    <Badge color={getColor()}>{status}</Badge>
  );
}

export default ImportDataStatusBadge;
