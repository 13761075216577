import React, { FunctionComponent, ComponentType } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import CenteredLayout from "../layouts/CenteredLayout";
import ScrollToTop from "../layouts/components/ScrollToTop";

import { selectUser } from "../redux/selectors/index";
import { UserState } from "../redux/types";

import Page404 from "../pages/Page404";

import routeConfig, { RoutesWithLayout, AppRoute } from "./routerConfig";


type RouteCreator = (index: string, path: string, component: ComponentType, Layout: ComponentType, user: UserState) => JSX.Element;

const createRoute: RouteCreator = (index, path, Component, Layout) => (
  <Route
    key={index}
    exact
    path={path}
    render={() => (
      <Layout>
        <Component />
      </Layout>
    )}
  />
);

const createProtectedRoute: RouteCreator = (index, path, Component, Layout, user) => (
  user && user.isAuthenticated
    ? createRoute(index, path, Component, Layout, user)
    : createRedirectToLoginRoute(index)
);

const createRedirectToLoginRoute = (index: string) => (
  <Route
    key={index}
    render={props => (
      <Redirect to={{
        pathname: '/auth/sign-in',
        state: {
          from: props.location,
        },
      }} />
    )}
  />
);

const flattenTree = (root: AppRoute) => {
  let flatten: AppRoute[] = [{ ...root }];

  if (root.children && root.children.length > 0) {
    const flattenedChildren: AppRoute[] = root.children
      .map(child => flattenTree(child))
      .reduce((a, b) => a.concat(b), []);

    return flatten.concat(flattenedChildren);
  }

  return flatten;
};

const renderRoutes = ({ routes, layout: RouteConfigLayout }: RoutesWithLayout, user: UserState, createRouteFn: RouteCreator) => {
  return routes
    .map(r => flattenTree(r))
    .reduce((a, b) => [...a, ...b], [])
    .map(({path, component: Component, layout: Layout}, index) => createRouteFn(`${index}`, path, Component, Layout || RouteConfigLayout, user));
}

const AppRouter: FunctionComponent = () => {
  const user = useSelector(selectUser);

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {renderRoutes(routeConfig.public, user, createRoute)}
          {renderRoutes(routeConfig.protected, user, createProtectedRoute)}

          <Route
            render={() => (
              <CenteredLayout>
                <Page404 />
              </CenteredLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default AppRouter;
