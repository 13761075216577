import React, { FunctionComponent } from "react";
import { Container, Row, Col } from "reactstrap";

const Footer: FunctionComponent = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col className="text-right">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{" "}
            <span className="text-muted">
              WeShareApps CMS
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
