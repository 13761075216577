import React, { FunctionComponent } from "react";
import classNames from "classnames";

type MainProps = {
  className?: string;
};

const Main: FunctionComponent<MainProps> = ({ className, children }) => <div className={classNames("main", className)}>{children}</div>;

export default Main;
