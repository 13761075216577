export function diffObject<T>(oldObject: any, newObject: any): T {
  const diff = Object.keys(newObject)
    .map(key => {
      if (newObject[key] !== oldObject[key]) {
        return [key, newObject[key]]
      }
      return undefined;
    })
    .filter(item => item !== undefined);

  return Object.fromEntries(diff as any) as T;
}
