import React, { FunctionComponent, useState } from "react";

import ContentLoader from 'react-content-loader';
import { Card, CardHeader, CardTitle, CardBody, Nav, NavItem, NavLink } from "reactstrap";

import classNames from "classnames";

import { User } from "../../domain/User";
import UserFollows from "./UserFollows";
import UserAppInstalls from "./UserAppInstalls";
import UserFollowers from "./UserFollowers";
import ListContentLoader from "../../components/ListContentLoader";
import Image from "../../components/Image";

export type UserProfileNavItemProps = {
  userId: number;
  updateTotalCount: React.Dispatch<React.SetStateAction<number>>;
  fixedScrollableContentHeight: string;
};

export type UserProfileNavItem = {
  label: string;
  component: FunctionComponent<UserProfileNavItemProps>;
  updateTotalCount: React.Dispatch<React.SetStateAction<number>>;
};

const UserProfileHeaderContentLoader = ({ imageSize }: { imageSize: number }) => {
  const width = 320;
  const height = imageSize + 54;
  return (
    <ContentLoader
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <circle cx={width / 2} cy={imageSize / 2} r={imageSize / 2} />
      <rect width="100%" height={30} x="0" y={height - 30 - 4} rx="8" ry="8" />
    </ContentLoader>
  );
};

const UserProfileContentLoader = ({ contentHeight }: { contentHeight: string }) => {
  const navPillWidth = 33.333; // %
  const navPillWidthPadding = 5; // %
  const navPillHeight = 37;

  return (
    <React.Fragment>
      <ContentLoader
        width="100%"
        height={navPillHeight}
      >
        <rect width={navPillWidth+'%'} x={0} height="100%" y="0" rx="8" ry="8" />
        <rect width={(navPillWidth-navPillWidthPadding)+'%'} x={(1 * navPillWidth + navPillWidthPadding)+'%'} height="60%" y="20%" rx="8" ry="8" />
        <rect width={(navPillWidth-navPillWidthPadding)+'%'} x={(2 * navPillWidth + navPillWidthPadding)+'%'} height="60%" y="20%" rx="8" ry="8" />
      </ContentLoader>

      <div className="pt-4 px-2" style={{ minHeight: contentHeight }}>
        <ListContentLoader />
      </div>
    </React.Fragment>
  );
};


type UserProfileProps = {
  user?: User;
  isFetchingUser?: boolean;
};

const UserProfile: FunctionComponent<UserProfileProps> = ({ user, isFetchingUser }) => {
  const [selectedNav, setSelectedNav] = useState(0);
  const [appInstallsTotalCount, setAppInstallsTotalCount] = useState(0);
  const [followsCount, setFollowsCount] = useState(0);
  const [followersCount, setFollowersCount] = useState(0);
  const imageSize = 100;
  const fixedScrollableContentHeight = '45vh';
  const userHasAvatar = Boolean(user && user.avatar);

  const navPills: UserProfileNavItem[] = [
    {
      label: appInstallsTotalCount ? `${appInstallsTotalCount} apps installed` : 'Apps installed',
      component: UserAppInstalls,
      updateTotalCount: setAppInstallsTotalCount,
    },
    {
      label: followsCount ? `Follows ${followsCount} users` : 'Following users', // TODO: use a better labeling system for large numbers (e.g. instead of "15,325,541 users", show "15mi+ users")
      component: UserFollows,
      updateTotalCount: setFollowsCount,
    },
    {
      label: followersCount ? `Followed by ${followersCount} users` : 'Followed by users',
      component: UserFollowers,
      updateTotalCount: setFollowersCount,
    },
  ];

  return (
    <Card>
      <CardHeader className="d-flex flex-column align-items-center mt-3">
        {isFetchingUser ? (
          <UserProfileHeaderContentLoader imageSize={imageSize} />
        ) :  (
          <React.Fragment>
            <div className="mb-3">
              <Image
                file={user?.avatar}
                size={imageSize + "px"}
                alt={user?.username}
                imageClassName={classNames("img-fluid rounded-circle", { "img-thumbnail": userHasAvatar })}
                imageStyle={{ fontSize: userHasAvatar ? '1em' : '1.6em' }}
              />
            </div>
            <div className="d-flex align-items-center">
              <CardTitle className="mb-0 text-center">
                {isFetchingUser && 'User profile'}
                {!isFetchingUser && user ? `${user.display_name}'s profile` : 'User profile'}
              </CardTitle>
            </div>
          </React.Fragment>
        )}
      </CardHeader>
      <CardBody>
        {isFetchingUser ? (
          <UserProfileContentLoader contentHeight={fixedScrollableContentHeight} />
        ) : (
          <React.Fragment>
            <Nav pills justified className="mb-3">
              {navPills.map((nav, index) => (
                <NavItem key={index}>
                  <NavLink href="#" active={index === selectedNav} onClick={() => setSelectedNav(index)}>
                    {nav.label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <div className="p-2">
              {navPills.map((nav, index) => (
                <div key={index} className={index === selectedNav ? '' : 'd-none'}>
                  <nav.component
                    userId={user ? user.id : 0} // TODO: await for user to load
                    fixedScrollableContentHeight={fixedScrollableContentHeight}
                    updateTotalCount={nav.updateTotalCount}
                  />
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default UserProfile;
