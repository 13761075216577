import React, { FunctionComponent, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import { AxiosError } from "axios";

import classNames from 'classnames';

import { Button, Card, CardBody } from "reactstrap";

import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation";

import { useApiService } from "../../hooks/useApiService";

import { ResetPasswordDTO } from "../../services/api/auth/authRequestTypes";

type ResetPasswordForm = {
  password: string;
  repeat_password: string;
};

const RecoverPasswordPage: FunctionComponent = () => {
  const location = useLocation();

  const [ cardState, setCardState ] = useState<'form' | 'error' | 'success'>('form');

  const [resetPassword, { isLoading }] = useApiService('auth', authService => (
    (resetPasswordReq: ResetPasswordDTO) => (
      authService.resetPassword(resetPasswordReq).then(() => setCardState('success')).catch(error => handleError(error))
    ))
  );

  const handleError = (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401) {
        toastr.error('Error', 'Invalid or expired token');
      } else {
        toastr.error('Error', (error.response.data && error.response.data.message) || 'Unknown error while resetting password');
      }
    } else {
      toastr.error('Error', 'Unknown error while resetting password');
    }
    setCardState('error');
  };

  const onSubmit = (_: React.FormEvent<HTMLFormElement>, values: ResetPasswordForm) => {
    const resetPasswordReq = { ...values };
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    resetPassword({ ...resetPasswordReq, token: token || '' });
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2 className={classNames({ 'mb-3': cardState === 'success' || cardState === 'error' })}>Password Recovery</h2>
        {cardState === 'form' && (<p className="lead">Please enter your new password below</p>)}
      </div>

      <Card className={classNames({ 'bg-success text-white': cardState === 'success' })}>
        <CardBody>
          <div className="m-sm-4">
            {cardState === 'form' && (
              <AvForm onValidSubmit={onSubmit}>

                <AvField label="Password" name="password" type="password" errorMessage="The password is required" />
                <AvField label="Confirm password" name="repeat_password" type="password" validate={{
                  match: { value: 'password', errorMessage: 'Passwords do not match' }
                }} />

                <div className="text-center mt-3">
                  <Button color="primary" size="lg" disabled={isLoading}>
                    {isLoading ? 'Resetting password...' : 'Reset password' }
                  </Button>
                </div>
              </AvForm>
            )}
            {cardState === 'error' && (
              <p className="lead text-center text-danger">
                Something went wrong.
                <br />
                <Link to="/auth/reset-password">
                  Please try again here.
                </Link>
              </p>
            )}
            {cardState === 'success' && (
              <p className="lead text-center">
                Password updated successfully!
                <br />
                <br />
                <Link to="/auth/sign-in" className="text-white">
                  Please click here to sign in
                </Link>
              </p>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecoverPasswordPage;
