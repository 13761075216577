import { ApiServices, ApiServicesKeys, ApiServicesType } from "../services/api";
import { useState, useEffect, useRef, useCallback } from "react";
import { useEffectOnUnmount } from "./useEffectOnUnmount";

type RequestFunction<U> = (...params: U[]) => Promise<any>;

type UseApiServiceReturnType<U> = [
  (...params: U[]) => void,
  { isLoading: boolean },
];

// const log = (debugIdentifier: string, ...params: any[]) => {
//   debugIdentifier && console.log(`[${debugIdentifier}]`, ...params);
// };

// const logInfo = (debugIdentifier: string, ...params: any[]) => {
//   debugIdentifier && console.info(`[${debugIdentifier}]`, ...params);
// };

type RequestState = {
  isLoading: boolean,
  apiServiceParams?: any[],
};

// TODO: when this hook is 100% reliable, we'll remove the logging helper functions
export function useApiService<K extends ApiServicesKeys, U = any>(name: K, requestFn: (service: ApiServicesType[K]) => RequestFunction<U>, debugIdentifier: string = ''): UseApiServiceReturnType<U> {
  const service = ApiServices[name];

  const [requestState, setRequestState] = useState<RequestState>({ isLoading: false, apiServiceParams: undefined });
  const didCancel = useRef(false);
  const reqHandler = useCallback((...params: U[]) => {
    // log(debugIdentifier, 'changing both loading to true and apiServiceParams to', params);
    setRequestState({ isLoading: true, apiServiceParams: params });
  }, []);

  // log(debugIdentifier, 'here, while rendering, didCancel is', didCancel.current);

  useEffect(() => {
    const { isLoading, apiServiceParams } = requestState;

    // log(debugIdentifier, 'on effect because isLoading changed to', [isLoading]);

    if (!isLoading) {
      return;
    }

    // log(debugIdentifier, 'loading is true, so...');

    const onFinally = () => {
      // log(debugIdentifier, 'the request ended, so we\'ll reset the loading state')
      if (!didCancel.current) {
        // log(debugIdentifier, 'successfully reverted isLoading to false and apiServiceParams to undefined');
        setRequestState({ isLoading: false });
      } else {
        // logInfo(debugIdentifier, 'oh, oh, the component is already unmounted, or at least the effect has already cleaned up, so we\'ll ignore loading and apiServiceParams');
      }
    }

    // log(debugIdentifier, 'call both serviceProvider and apiService async. here it shouldn\'t  re-render anything, since we didn\'t change anything on state');

    // we need to catch API errors and do nothing. API error handling is configured on axiosConfig.ts
    apiServiceParams
      ? requestFn(service)(...apiServiceParams).catch(err => undefined).finally(onFinally)
      : requestFn(service)().catch(err => undefined).finally(onFinally);

  // eslint-disable-next-line
  }, [requestState]);

  useEffectOnUnmount(() => {
    // log(debugIdentifier, 'setting didCancel to true');
    didCancel.current = true;
  });

  return [reqHandler, { isLoading: requestState.isLoading }];
}
