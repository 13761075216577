import React, { FunctionComponent } from 'react';

import { toastr } from 'react-redux-toastr';
import { Button, Col, Row } from 'reactstrap';

import { SelectOption } from 'services/api/responses';

import { useApiService } from 'hooks/useApiService';

import { ListWithMetadata } from './HomepageFeaturedAppsPage';
import AppsSelect from './AppsSelect';
import { AppTypesPaths, FrontendAppPage, joinPagesPaths } from 'constants/frontendAppPages';

type Props = {
  list: ListWithMetadata,
  fetchLists: Function,
  updateAppsOnList: Function,
  onlyShowAppsWithFeaturedIcon?: boolean,
  minimum?: number,
  maximum?: number,
  isSortable?: boolean
};

const HomepageFeaturedAppsList: FunctionComponent<Props> = ({
  list,
  fetchLists,
  updateAppsOnList,
  onlyShowAppsWithFeaturedIcon,
  minimum,
  maximum,
  isSortable
}) => {
  const [revalidate] = useApiService('revalidation', revalidationService => (
    (paths: string) => revalidationService.revalidate(paths))
  );

  const [saveList, { isLoading: isSavingList }] = useApiService('list', listService => (
    (list: ListWithMetadata) => (
      listService.updateList(list.id, { orderedEntities: list.apps.map(a => a.value) }).then(() => {
        const pathsToRevalidate = joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS, AppTypesPaths]);
        revalidate(pathsToRevalidate);
        toastr.success('Success', `List updated successfully`);
        fetchLists();
      })
    )
  ));

  const onSaveListButtonClick = (list: ListWithMetadata) => {
    const minimumValue = minimum === undefined ? Number.MIN_VALUE : minimum;
    const maximumValue = maximum === undefined ? Number.MAX_VALUE : maximum;

    if (list.apps.length < minimumValue || list.apps.length > maximumValue) {
      const message = minimum !== undefined && maximum !== undefined
        ? `Please select between ${minimum} and ${maximum} apps`
        : minimum !== undefined
          ? `Please select at least ${minimum} apps`
          : `Please select at most ${maximum} apps`;

      toastr.warning('Attention', message);
    } else {
      saveList(list);
    }
  };

  return (
    <div key={list.id} className="mb-4">
      <h4>{list.name}</h4>
      {onlyShowAppsWithFeaturedIcon && (
        <small>Please make sure that the apps you have selected have a featured image associated. You can associate
          it in App Management page.</small>)}

      <Row>
        <Col md="12" lg="12" className="d-flex">
          <AppsSelect
            className="flex-grow-1"
            value={list.apps}
            onChange={(apps: SelectOption[]) => updateAppsOnList(list.id, apps)}
            isDisabled={isSavingList}
            onlyShowAppsWithFeaturedIcon={onlyShowAppsWithFeaturedIcon}
            isSortable={isSortable}
          />
          {list.wasUpdatedOnce && (
            <Button color="primary" className="ml-2" disabled={isSavingList}
                    onClick={() => onSaveListButtonClick(list)}>
              {isSavingList ? 'Saving...' : 'Save list'}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default HomepageFeaturedAppsList;
