export const joinPagesPaths = (pages: string[]) => pages.join(',');



export const FrontendAppPage = {
  ABOUT: '/about-weshareapps',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  CONTACT: '/contact-and-support',
  FAQ: '/faq',
  CATEGORIES: '/categories',
  SINGLE_CATEGORY: (categorySlug: string) => `/categories/${categorySlug}`,
  HOME: '/',
  ADS_SAMPLE_PAGE: '/ads-sample-page',
  APPS: '/apps',
  APPS_TYPE: (appType: string) => `/apps/${appType}`,
  SINGLE_APP: (appSlug: string) => `/app/${appSlug}`,
};

type PageContentMapperType = Record<string, string>

export const PageContentMapper: PageContentMapperType = {
  1: FrontendAppPage.CONTACT,
  2: FrontendAppPage.ABOUT,
  3: FrontendAppPage.FAQ,
  4: FrontendAppPage.TERMS_AND_CONDITIONS
};

const AppTypesPages = ['popular', 'ourchoice', 'games', 'recently', 'mostshared', 'highlighted', 'recommended', 'paid-promotion'];
export const AppTypesPaths = joinPagesPaths(AppTypesPages.map(appType => FrontendAppPage.APPS_TYPE(appType)));

