import { createStore } from "redux";
import { rootReducer, persistedStatePaths } from "../reducers/index";
import * as persistState from "redux-localstorage";

const preloadedState = undefined;

const store = createStore(
  rootReducer,
  preloadedState,
  persistState(persistedStatePaths),
);

export default store;
