import { AxiosInstance } from "axios";
import { Developer } from "../../../domain/Developer";
import { createRequestQuery, PagedRequestSimpleFilter } from "../requests";
import { PagedResponseDTO } from "../responses";

export class DeveloperService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchDevelopersByName(name: string): Promise<Developer[]> {
    if (!name) {
      return new Promise(resolve => resolve([]));
    }

    const fields: (keyof Developer)[] = [
      'id',
      'name',
    ];

    const page = 1;
    const limit = 20;

    const filter: PagedRequestSimpleFilter<Developer>[] = [
      { field: 'name', operator: 'like', value: name },
    ];

    const requestQuery = createRequestQuery({ fields, filter, page, limit });

    return this.axios.get<PagedResponseDTO<Developer>>(`/developer?${requestQuery}`).then(res => res.data.data);
  }

}
