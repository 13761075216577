import { AxiosInstance } from "axios";
import { PagedResponseDTO } from "../responses";
import { User } from "../../../domain/User";
import { App } from "../../../domain/App";
import { PagedRequestDTO, createRequestQuery, PagedRequestFilterCombination, PagedRequestJoinOption } from "../requests";

export type CreateUserRequestDTO = Omit<User, 'id' | 'role' | 'avatar' | 'display_name'> & { avatar?: File, role: string };
export type UpdateUserRequestDTO = Omit<Partial<User>, 'avatar' | 'display_name'> & { avatar?: File, role: string };
export type SaveUserRequestDTO = CreateUserRequestDTO | UpdateUserRequestDTO;

export class UserService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchUsersPage(pagedRequestDTO: PagedRequestDTO<User>) {
    const fields: (keyof User)[] = [
      'id',
      'first_name',
      'last_name',
      'display_name',
      'username',
      'email',
      'created_at',
    ];

    const requestQuery = createRequestQuery({ ...pagedRequestDTO, fields });

    return this.axios.get<PagedResponseDTO<User>>(`/user?${requestQuery}`).then(res => res.data);
  }

  fetchUsersByName(name: string): Promise<User[]> {
    if (!name) {
      return new Promise(resolve => resolve([]));
    }

    const fields: (keyof User)[] = [
      'id',
      'first_name',
      'last_name',
    ];

    const page = 1;
    const limit = 20;

    const search: PagedRequestFilterCombination<User> = {
      operator: 'or',
      filters: [
        { field: 'first_name', operator: 'like', value: name },
        { field: 'last_name', operator: 'like', value: name },
        { field: 'username', operator: 'like', value: name },
      ],
    };

    const requestQuery = createRequestQuery({ fields, search, page, limit });

    return this.axios.get<PagedResponseDTO<User>>(`/user?${requestQuery}`).then(res => res.data.data);
  }

  fetchUserById(userId: number) {
    const fields: (keyof User)[] = [
      'id',
      'first_name',
      'last_name',
      'username',
      'email',
      'description',
    ];

    const join: PagedRequestJoinOption<User>[] = [{ relation: 'role' }];

    const requestQuery = createRequestQuery({ fields, join });

    return this.axios.get<User>(`/user/${userId}?${requestQuery}`).then(res => res.data);
  }

  fetchUserApps(userId: number, page: number, limit: number) {
    const requestQuery = createRequestQuery({ page, limit });

    return this.axios.get<PagedResponseDTO<App>>(`/user/${userId}/apps?${requestQuery}`).then(res => res.data);
  }

  fetchUserFollows(userId: number, page: number, limit: number) {
    const requestQuery = createRequestQuery({ page, limit });

    return this.axios.get<PagedResponseDTO<User>>(`/user/${userId}/follows?${requestQuery}`).then(res => res.data);
  }

  fetchUserFollowers(userId: number, page: number, limit: number) {
    const requestQuery = createRequestQuery({ page, limit });

    return this.axios.get<PagedResponseDTO<User>>(`/user/${userId}/followers?${requestQuery}`).then(res => res.data);
  }

  createUser(user: CreateUserRequestDTO) {
    const formData = new FormData();
    Object.entries(user).forEach(([ key, value ]) => {
      if (value) {
        formData.append(key, value as string | File);
      }
    });
    formData.append('repeat_password', user.password);

    return this.axios.post('/user', formData);
  }

  updateUser(userId: number, user: UpdateUserRequestDTO) {
    const formData = new FormData();
    Object.entries(user).forEach(([ key, value ]) => {
      if (value) {
        formData.append(key, value as string | File);
      }
    });

    if (user.password) {
      formData.append('repeat_password', user.password);
    }

    return this.axios.patch(`/user/${userId}`, formData);
  }

  deleteUser(userId: number) {
    return this.axios.delete(`/user/${userId}`);
  }

  startSearchSync() {
    return this.axios.post(`/user/searchSync`, { skip: 0, take: 500, limit: 0 });
  }


  bulkImport(csvFile: File) {
    const formData = new FormData();
    formData.append('users', csvFile);
    return this.axios.post(`/user/import`, formData);
  }

}
