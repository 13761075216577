import React, { FunctionComponent } from "react";
import { Row, ButtonToolbar, Button, Col } from "reactstrap";
import { Link } from "react-router-dom";

type CrudFormButtonBarProps = {
  isSaveButtonDisabled?: boolean;
  isCancelButtonDisabled?: boolean;

  saveButtonLabel?: string;
  cancelButtonLabel?: string;

  cancelButtonRoutePath?: string;

  showSaveButton?: boolean;
  showCancelButton?: boolean;
};

const CrudFormButtonBar: FunctionComponent<CrudFormButtonBarProps> = ({
  isSaveButtonDisabled,
  isCancelButtonDisabled,

  saveButtonLabel = 'Save',
  cancelButtonLabel = 'Cancel',

  cancelButtonRoutePath,

  showSaveButton = true,
  showCancelButton = true,
}) => {
  return (
    showSaveButton || showCancelButton ? (
      <Row form className="mt-3">
        <Col>
          <ButtonToolbar>
            {showSaveButton && (
              <Button type="submit" color="primary" size="lg" disabled={isSaveButtonDisabled} className="mr-2">
                {saveButtonLabel}
              </Button>
            )}
            {showCancelButton && (
              <Link to={cancelButtonRoutePath || '/'}>
                <Button type="button" color="light" size="lg" disabled={isCancelButtonDisabled}>
                  {cancelButtonLabel}
                </Button>
              </Link>
            )}
          </ButtonToolbar>
        </Col>
      </Row>
    ) : (
      <React.Fragment></React.Fragment>
    )
  );
};

export default CrudFormButtonBar;
