import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export function useSafeRedirect() {
  const [redirect, setRedirect] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (redirect) {
      history.push(redirect);
    }
  // eslint-disable-next-line
  }, [redirect]);

  return setRedirect;
}
