import React, { FunctionComponent } from "react";

import Wrapper from "./components/Wrapper";
import Main from "./components/Main";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Content from "./components/Content";
import Footer from "./components/Footer";

const AdminLayout: FunctionComponent = ({ children }) => (
  <Wrapper>
    <Sidebar />
    <Main>
      <Navbar />
      <Content>{children}</Content>
      <Footer />
    </Main>
  </Wrapper>
);

export default AdminLayout;
