import React, { FunctionComponent } from "react";
import { Link, useRouteMatch } from "react-router-dom";

import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  Button,
  CardBody,
  Spinner,
  Input, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
// TODO: try again to put pagination components on top and bottom of table using standalone components
//import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone, paginationTotalAdapter } from "react-bootstrap-table2-paginator";
import paginationFactory from "react-bootstrap-table2-paginator";

import { DebounceInput } from "react-debounce-input";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";
import { useDispatch, useSelector } from "react-redux";
import { selectListingPageTableState } from "../../redux/selectors";
import { updateListingPageTableState, clearOtherListingPageTableStates  } from "../../redux/actions/listingPageTableStatesActions";



export type CrudTableState<T> = {
  page: number;
  sizePerPage: number;
  sizePerPageList: number[];
  totalSize?: number;
  sortField?: keyof T;
  sortOrder?: 'asc' | 'desc';
  filter?: any;
};

export const initialTableState: CrudTableState<any> = {
  page: 1,
  sizePerPage: 10,
  sizePerPageList: [10, 20, 50, 100],
};


export type OnCrudTableStateChange<T> = (newTableState: CrudTableState<T>) => any;

export type OnCrudTableChangeReducer<T> = (state: CrudTableState<T>, action: OnCrudTableChangeAction) => CrudTableState<T>;
export type OnCrudTableChangeAction = { type: OnCrudTableChangeActionType, payload: OnCrudTableChangeActionPayload };
export type OnCrudTableChangeActionType = 'pagination' | 'sort' | 'filter';
export type OnCrudTableChangeActionPayload = {
  page?: number,
  sizePerPage?: number,
  sortField?: string,
  sortOrder?: 'asc' | 'desc',
  filter?: any,
};

export type CrudListCustomFilterComponent = React.ComponentType<{ filter: any, setFilter: (newFilterValue: any) => void }>;

const defaultTableChangeReducer: OnCrudTableChangeReducer<any> = (tableState: CrudTableState<any>, action: OnCrudTableChangeAction) => {
  switch (action.type) {
    case 'pagination':
      return {
        ...tableState,
        page: action.payload.page!,
        sizePerPage: action.payload.sizePerPage!,
      };

    case 'sort':
      return {
        ...tableState,
        sortField: action.payload.sortField,
        sortOrder: action.payload.sortOrder,
        page: 1, // reset page on sort
      };

    case 'filter':
      return {
        ...tableState,
        filter: action.payload.filter,
        page: 1, // reset page on filter
      };

  }
  return tableState;
}


type CrudListProps<T> = {
  title: string;

  showAddButton?: boolean;
  addButtonLinkPath?: string;
  addButtonLabel?: string;

  showDropdownButton?: boolean;
  dropdownButtonLabel?: string;
  dropdownButtons?: any[];

  tableKeyField?: string;
  tableData: any[];
  tableColumns: any[]; // TODO: use high-quality typed react-bootstrap-table-next
  tableIsLoading: boolean;
  onTableChangeReducer?: OnCrudTableChangeReducer<T>;
  onTableChange: OnCrudTableStateChange<T>;
  tableState: CrudTableState<T>;

  reduxSyncTableStateKey?: string;

  useSimpleFilter?: boolean;
  simpleFilterPlaceholder?: string;

  useCustomFilter?: boolean;
  customFilter?: CrudListCustomFilterComponent;
};

const CrudList: FunctionComponent<CrudListProps<any>> = ({
  title,

  showAddButton = true,
  addButtonLinkPath,
  addButtonLabel,

  showDropdownButton,
  dropdownButtonLabel,
  dropdownButtons,

  tableKeyField = 'id',
  tableData,
  tableColumns,
  tableIsLoading,
  onTableChangeReducer = defaultTableChangeReducer,
  onTableChange,
  tableState = initialTableState,

  reduxSyncTableStateKey,

  useSimpleFilter = false,
  simpleFilterPlaceholder,

  useCustomFilter = false,
  customFilter: CustomFilter,
}) => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const usingReduxSyncTableState = Boolean(reduxSyncTableStateKey);
  const reduxTableState = useSelector(selectListingPageTableState(reduxSyncTableStateKey || ''));
  const updateReduxTableState = (filterValue: any) => dispatch(updateListingPageTableState(reduxSyncTableStateKey || '', filterValue));
  const clearOtherReduxTableStates = () => dispatch(clearOtherListingPageTableStates(reduxSyncTableStateKey || ''));

  useEffectOnMount(() => {
    if (usingReduxSyncTableState) {
      clearOtherReduxTableStates();
      tableState = reduxTableState || tableState;
      onTableChange(tableState);
    }
  });

  const onBootstrapTableChange = (type: OnCrudTableChangeActionType, payload: OnCrudTableChangeActionPayload) => {
    const newTableState = onTableChangeReducer(tableState, { type, payload });

    if (usingReduxSyncTableState) {
      updateReduxTableState(newTableState);
    }

    onTableChange(newTableState);
  };

  const onFilterInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onBootstrapTableChange('filter', { filter: event.target.value });
  };

  const onCustomFilterChange = (newFilterValue: any) => {
    onBootstrapTableChange('filter', { filter: newFilterValue });
  };

  return (
    <Container className="p-0">
      <Card>
        <CardHeader className="d-flex mt-3 align-items-center">
          <CardTitle tag="h2" className="mb-0 flex-grow-1">{title}</CardTitle>
          {showAddButton && (
            <Link to={`${url}${addButtonLinkPath}`}>
              <Button color="primary">
                {addButtonLabel}
              </Button>
            </Link>
          )}
          {showDropdownButton && (
            <UncontrolledButtonDropdown direction="left">
              <DropdownToggle caret color="primary">
                {dropdownButtonLabel}
              </DropdownToggle>
              {dropdownButtons && dropdownButtons.length && (
                <DropdownMenu>
                  {dropdownButtons.map((db, index) => (
                    <DropdownItem key={index} header={db.header} disabled={db.disabled} divider={db.divider} onClick={() => db.onClick && db.onClick()}>{db.label}</DropdownItem>
                  ))}
                </DropdownMenu>
              )}
            </UncontrolledButtonDropdown>
          )}
        </CardHeader>
        <CardBody>
          {useSimpleFilter && (
            <div className="mb-3">
              <DebounceInput
                element={Input as any}
                value={tableState.filter}
                onChange={onFilterInputChange}
                debounceTimeout={1000}
                placeholder={simpleFilterPlaceholder}
              />
            </div>
          )}
          {useCustomFilter && (
            <div className="mb-3">
              {React.createElement(CustomFilter!, { filter: tableState.filter, setFilter: onCustomFilterChange })}
            </div>
          )}
          <BootstrapTable
            keyField={tableKeyField}
            data={tableData}
            columns={tableColumns}
            bootstrap4
            bordered={false}
            striped
            noDataIndication={() => 'No data found'}
            loading={tableIsLoading}
            overlay={overlayFactory({
              spinner: <Spinner color="dark" />,
              styles: {
                overlay: (base: any) => ({
                  ...base,
                  background: 'rgba(0, 0, 0, 0.3)',
                })
              }
            })}
            remote={{ pagination: true, sort: true }}
            onTableChange={onBootstrapTableChange}
            defaultSortDirection="asc"
            pagination={paginationFactory({
              page: tableState.page,
              sizePerPage: tableState.sizePerPage,
              sizePerPageList: tableState.sizePerPageList,
              totalSize: tableState.totalSize,
              showTotal: true,
              alwaysShowAllBtns: true,
            })}
          />
        </CardBody>
      </Card>
    </Container>
  )
};

export default CrudList;
