import { AxiosInstance } from "axios";
import { PagedRequestDTO, createRequestQuery, PagedRequestSortOption, PagedRequestSimpleFilter } from "../requests";
import { Tag } from "../../../domain/Tag";
import { PagedResponseDTO, SelectOption } from "../responses";

type SaveTagRequestDTO = Pick<Tag, 'name' | 'description'>;

export class TagService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchTagsSelectOptions(name: string): Promise<SelectOption[]> {
    if (!name) {
      return new Promise(resolve => resolve([]));
    }

    const fields: (keyof Tag)[] = [
      'id',
      'name',
    ];

    const page = 1;
    const limit = 1000;

    const sortOptions: PagedRequestSortOption<Tag>[] = [{ field: 'name', order: 'asc' }];

    const filter: PagedRequestSimpleFilter<Tag>[] = name ? [{ field: 'name', operator: 'like', value: name }] : [];

    const requestQuery = createRequestQuery({ fields, page, limit, sortOptions, filter });

    return this.axios.get<PagedResponseDTO<Tag>>(`/tag?${requestQuery}`).then(res => res.data.data.map(c => ({ label: c.name, value: c.id })));
  }

  fetchTagsPage(pagedRequestDTO: PagedRequestDTO<Tag>) {
    const fields: (keyof Tag)[] = [
      'id',
      'name',
      'updated_at',
    ];

    if (!pagedRequestDTO.sortOptions) {
      pagedRequestDTO.sortOptions = [{ field: 'name', order: 'asc' }];
    }

    const requestQuery = createRequestQuery({ ...pagedRequestDTO, fields });

    return this.axios.get<PagedResponseDTO<Tag>>(`/tag?${requestQuery}`).then(res => res.data);
  }

  fetchTagById(tagId: number) {
    const fields: (keyof Tag)[] = [
      'id',
      'name',
      'description',
    ];

    const requestQuery = createRequestQuery({ fields });

    return this.axios.get<Tag>(`/tag/${tagId}?${requestQuery}`).then(res => res.data);
  }

  createTag(tag: SaveTagRequestDTO) {
    return this.axios.post('/tag', tag);
  }

  updateTag(tagId: number, tag: SaveTagRequestDTO) {
    return this.axios.patch(`/tag/${tagId}`, tag);
  }

  deleteTag(tagId: number) {
    return this.axios.delete(`/tag/${tagId}`);
  }

}

