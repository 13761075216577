import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import moment from 'moment';

import CrudForm from '../../components/crud/CrudForm';

import { useApiService } from '../../hooks/useApiService';
import { useEffectOnMount } from '../../hooks/useEffectOnMount';

import ImportDataStatusBadge from './ImportDataStatusBadge';

import { ImportLog } from '../../domain/ImportLog';
import CrudFormButtonBar from '../../components/crud/CrudFormButtonBar';
import { CustomInput, Spinner } from 'reactstrap';

const ImportDataDetailsPage: FunctionComponent = () => {
  const { id } = useParams();

  const [ importLog, setImportLog ] = useState<ImportLog>();
  const [ autoRefreshLogsEnabled, setAutoRefreshLogsEnabled ] = useState(true);
  const [ autoRefreshLogsTimeoutSeconds ] = useState(5);

  const [ autoRefreshLogsTimeout, setAutoRefreshLogsTimeout ] = useState<NodeJS.Timeout>();

  const importLogMessageRef = useRef<any>(null);

  // Fetch importLog logic

  const [fetchImportLogById, { isLoading: isFetchingImportLog }] = useApiService('importLog', importLogService => (
    (importLogId: number) => (
      importLogService.fetchImportLogById(importLogId).then(setImportLog)
    ))
  );

  const toggleAutoRefreshLogs = () => {
    const nowEnabled = !autoRefreshLogsEnabled;
    // console.log('autoRefreshLogsEnabled was', autoRefreshLogsEnabled, 'and now is', nowEnabled);

    setAutoRefreshLogsEnabled(nowEnabled);

    if (nowEnabled) {
      // console.log('since the user enabled it, do something');
      fetchAfterTimeoutIfNeeded(true);
    } else {
      // console.log('since the user disabled it, check if is there any timeout to dismiss');
      if (autoRefreshLogsTimeout) {
        // console.log('now that we have to dismiss a timeout, dismiss it', autoRefreshLogsTimeout, 'and then setting the timeout to undefined');
        clearTimeout(autoRefreshLogsTimeout);
        setAutoRefreshLogsTimeout(undefined);
      }
    }
  };

  const scrollLogToBottom = () => {
    if (importLogMessageRef.current) {
      importLogMessageRef.current!.scrollTop = importLogMessageRef.current!.scrollHeight;
    }
  };

  const fetchAfterTimeoutIfNeeded = (fetchNow?: boolean) => {
    if (importLog && !autoRefreshLogsTimeout && (fetchNow || autoRefreshLogsEnabled)) {
      // console.log('since autoRefreshLogsEnabled was true and there wasn\'t a timeout already configured, setting timeout for another fetch');

      const timeout = setTimeout(() => {
        // console.log('after timeout, setting the timeout to null and fetching');
        setAutoRefreshLogsTimeout(undefined);
        fetchImportLogById(id);
      }, autoRefreshLogsTimeoutSeconds * 1000);

      // console.log('setting timeout to the timeout newly created', timeout);
      setAutoRefreshLogsTimeout(timeout);
    }
  }

  useEffect(() => {
    // console.log('importLog changed, scrolling to bottom');
    scrollLogToBottom();
    fetchAfterTimeoutIfNeeded();
  //eslint-disable-next-line
  }, [importLog]);

  useEffect(() => {
    return () => {
      // console.log('using effect on unmount, check if is needed to clearTimeout');
      // console.log('autoRefreshLogsTimeout', autoRefreshLogsTimeout);
      if (autoRefreshLogsTimeout) {
        // console.log('clearing timeout', autoRefreshLogsTimeout);
        clearTimeout(autoRefreshLogsTimeout);
      }
    };
  }, [autoRefreshLogsTimeout]);

  useEffectOnMount(() => {
    if (id) {
      fetchImportLogById(id);
    }
  });

  const isFetchingImportLogFirstTime = !importLog && isFetchingImportLog;
  const isRefreshingImportLog = importLog && isFetchingImportLog;

  return (
    <CrudForm title={'Import data details'} showSpinner={isFetchingImportLogFirstTime}>
      {importLog && (
        <React.Fragment>
          <h4>
            {importLog.type} <ImportDataStatusBadge status={importLog.status} />
          </h4>
          <p>
            Started at {moment(importLog.created_at).format('MMMM Do YYYY, h:mm:ss a')}
            <br />
            Last updated at {moment(importLog.updated_at).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
          <div className="d-flex">
            <CustomInput type="switch" id="enableAutoRefreshLogs" name="enableAutoRefreshLogs" checked={autoRefreshLogsEnabled} onChange={() => toggleAutoRefreshLogs()} label="Auto-refresh logs every 5 seconds" />
            {isRefreshingImportLog && <Spinner color="primary" size="sm" className="ml-2" />}
          </div>
          <br />
          <div ref={importLogMessageRef} style={{ border: '1px solid #cacaca', padding: '0.8rem 1rem', maxHeight: '46vh', overflowY: 'scroll' }}>
            <pre>{importLog?.message}</pre>
          </div>
        </React.Fragment>
      )}
      <CrudFormButtonBar
        showSaveButton={false}
        cancelButtonRoutePath={'/import-data'}
      />
    </CrudForm>
  );
};

export default ImportDataDetailsPage;
