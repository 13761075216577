export enum FileVariationType {
  ORIGINAL = 'original',
  THUMB = 'thumb',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type FileVariation = {
  id: number;
  url: string;
  variation: FileVariationType;
}
