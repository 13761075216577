import { DeepPartial } from "redux";
import { AxiosInstance } from "axios";
import { List, ListType } from "../../../domain/List";
import { createRequestQuery, PagedRequestSimpleFilter, PagedRequestJoinOption, PagedRequestDTO } from "../requests";
import { PagedResponseDTO } from "../responses";

type SaveListRequestDTO = DeepPartial<Omit<List, 'type'> & { type: string }>;
type UpdateListRequestDTO = Partial<{
  orderedEntities: number[],
  name: string,
  description: string,
  type: string
}>

export class ListService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchListsPage(pagedRequestDTO: PagedRequestDTO<List>) {
    const fields: (keyof List)[] = [
      'id',
      'name',
      'type',
      'updated_at',
    ];

    if (!pagedRequestDTO.sortOptions) {
      pagedRequestDTO.sortOptions = [{ field: 'name', order: 'asc' }];
    }

    const requestQuery = createRequestQuery({ ...pagedRequestDTO, fields });

    return this.axios.get<PagedResponseDTO<List>>(`/list?${requestQuery}`).then(res => res.data);
  }

  fetchListsByTypes(types: ListType[]) {
    const filter: PagedRequestSimpleFilter<List>[] = [
      { field: 'type', operator: 'in', value: types },
    ];

    const join: PagedRequestJoinOption<List>[] = [
      { relation: 'apps', fields: ['id', 'name', 'status'] },
    ];

    const requestQuery = createRequestQuery({
      page: 1,
      limit: 100, // we shouldn't have more than 100 lists
      filter,
      join,
    });

    return this.axios.get<PagedResponseDTO<List>>(`/list?${requestQuery}`).then(res => res.data.data);
  }

  fetchListByIdOrSlug(idOrSlug: number | string) {
    const fields: (keyof List)[] = [
      'id',
      'name',
      'slug',
      'type',
      'description',
    ];

    const join: PagedRequestJoinOption<List>[] = [
      { relation: 'apps', fields: ['id', 'name', 'status'] },
    ];

    const requestQuery = createRequestQuery({ fields, join });

    return this.axios.get<List>(`/list/${idOrSlug}?${requestQuery}`).then(res => res.data);
  }

  createList(list: SaveListRequestDTO) {
    return this.axios.post('/list', list);
  }

  updateList(listId: number, list: UpdateListRequestDTO) {
    return this.axios.patch(`/list/${listId}`, list);
  }

  deleteList(listId: number) {
    return this.axios.delete(`/list/${listId}`);
  }
}
