import React, { FunctionComponent, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Edit as EditIcon } from "react-feather";

import moment from 'moment';

import CrudList, { CrudTableState, initialTableState } from "../../components/crud/CrudList";
import { CrudTableColumnBuilder } from "../../components/crud/CrudTableColumnBuilder";

import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import { PagedRequestDTO } from "../../services/api/requests";

import { CustomPage } from "../../domain/CustomPage";

type CustomPageTableRow = {
  id: number;
  title: string;
  updated_at: string;
};

const CustomPageListPage: FunctionComponent = () => {

  // Custom pages table

  const [customPages, setCustomPages] = useState<CustomPageTableRow[]>([]);
  const [tableState, setTableState] = useState<CrudTableState<CustomPageTableRow>>(initialTableState);

  const { url } = useRouteMatch();

  const columns = CrudTableColumnBuilder(tableState)
    .addIndexColumn()
    .addColumn({ text: 'Title', dataField: 'title', sort: true, })
    .addColumn({ text: 'Updated at', dataField: 'updated_at', sort: true, headerStyle: { width: '250px' } })
    .addActionsColumn((customPage: CustomPageTableRow) => (
      // TODO: convert to an "addActionColumn" sequence where we pass only the path?: string, icon: Icon, and onClick?: Fn
      <div>
        <Link to={`${url}/update/${customPage.id}`}>
          <EditIcon size={18} className="mr-1"></EditIcon>
        </Link>
        {/* <Link to="#" onClick={() => deleteCustomPageModalRef.current!.open(customPage)}>
          <TrashIcon size={18} className="ml-1"></TrashIcon>
        </Link> */}
      </div>
    ))
    .getColumns();

  // Fetch custom pages logic

  const [fetchCustomPages, { isLoading: isFetchingCustomPages }] = useApiService('customPage', customPageService => (
    (tableState: CrudTableState<CustomPageTableRow>) => {
      const pagedRequestDTO = mapTableStateToPagedRequest(tableState);
      return customPageService.fetchCustomPagesPage(pagedRequestDTO).then(customPagesPage => {
        const totalSize = customPagesPage.total;

        setCustomPages(customPagesPage.data.map(mapCustomPageToCustomPageTableRow));
        setTableState({ ...tableState, totalSize });
      });
    })
  );

  const mapCustomPageToCustomPageTableRow = (customPage: CustomPage) => ({
    id: customPage.id,
    title: customPage.title,
    updated_at: moment(customPage.updated_at).format('MMMM Do YYYY, h:mm:ss a'),
  } as CustomPageTableRow);

  const mapTableStateToPagedRequest = (tableState: CrudTableState<CustomPageTableRow>) => {
    const pagedRequestDTO: PagedRequestDTO<CustomPage> = {
      page: tableState.page,
      limit: tableState.sizePerPage,
    };
    if (tableState.filter) {
      pagedRequestDTO.search = {
        operator: 'or',
        filters: [
          { field: 'title', operator: 'like', value: tableState.filter },
          { field: 'slug', operator: 'like', value: tableState.filter },
        ],
      };
    }
    if (tableState.sortField && tableState.sortOrder) {
      pagedRequestDTO.sortOptions = [{ field: tableState.sortField, order: tableState.sortOrder }];
    }
    return pagedRequestDTO;
  };

  const onTableChange = (tableState: CrudTableState<CustomPageTableRow>) => fetchCustomPages(tableState);
  useEffectOnMount(() => fetchCustomPages(tableState));

  // Delete custom page logic

  // const deleteCustomPageModalRef = useRef<ConfirmationModal>(null);

  // const [deleteCustomPage, { isLoading: isDeletingCustomPage }] = useApiService('customPage', customPageService => (
  //   (customPage: CustomPageTableRow) => (
  //     customPageService.deleteCustomPage(customPage.id).then(displayCustomPageDeletedSuccessMessage)
  //   ))
  // );

  // const displayCustomPageDeletedSuccessMessage = () => {
  //   toastr.success('Success', 'Custom page deleted successfully');
  //   deleteCustomPageModalRef.current!.hide();
  //   fetchCustomPages(tableState);
  // };

  return (
    <React.Fragment>
      <CrudList
        title="Custom pages"
        addButtonLinkPath="/create"
        addButtonLabel="Add new custom page"
        tableData={customPages}
        tableColumns={columns}
        tableIsLoading={isFetchingCustomPages}
        onTableChange={onTableChange}
        tableState={tableState}
        reduxSyncTableStateKey="custom-page"
        useSimpleFilter
        simpleFilterPlaceholder="Filter by title..."
      />

      {/* <ConfirmationModal
        ref={deleteCustomPageModalRef}
        title="Delete customPage"
        modalBody={(customPage: CustomPageTableRow) => `Do you really want to delete custom page "${customPage?.title}"?`}
        confirmButtonColor="danger"
        confirmButtonDisabled={isDeletingCustomPage}
        confirmButtonLabel={isDeletingCustomPage ? 'Deleting custom page...' : 'Delete custom page'}
        cancelButtonDisabled={isDeletingCustomPage}
        onConfirm={(customPage: CustomPageTableRow) => deleteCustomPage(customPage)}
      /> */}
    </React.Fragment>
  );
}

export default CustomPageListPage;
