import axiosConfig from "./axiosConfig";
import { AuthService } from "./auth/AuthService";
import { UserService } from "./domain/UserService";
import { RoleService } from "./domain/RoleService";
import { CategoryService } from "./domain/CategoryService";
import { TagService } from "./domain/TagService";
import { AppService } from "./domain/AppService";
import { CampaignService } from "./domain/CampaignService";
import { DeveloperService } from "./domain/DeveloperService";
import { CustomPageService } from "./domain/CustomPageService";
import { ListService } from "./domain/ListService";
import { ImportLogService } from "./domain/ImportLogService";
import { ExportService } from "./domain/ExportService";
import { RevalidationService } from './revalidation/RevalidationService';
import { NewsletterService } from 'services/api/domain/NewsletterService';

export type ApiServicesType = typeof ApiServices;
export type ApiServicesKeys = keyof ApiServicesType;

export const ApiServices = {
  revalidation: new RevalidationService(axiosConfig),
  auth: new AuthService(axiosConfig),
  user: new UserService(axiosConfig),
  role: new RoleService(axiosConfig),
  category: new CategoryService(axiosConfig),
  tag: new TagService(axiosConfig),
  app: new AppService(axiosConfig),
  campaign: new CampaignService(axiosConfig),
  developer: new DeveloperService(axiosConfig),
  customPage: new CustomPageService(axiosConfig),
  list: new ListService(axiosConfig),
  importLog: new ImportLogService(axiosConfig),
  export: new ExportService(axiosConfig),
  newsletter: new NewsletterService(axiosConfig)
};


