import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

import { ExternalLink } from "react-feather";

import { File } from "../../domain/File";

import Image from "../../components/Image";

type UserLinkProps = {
  link: string;
  label: string;
  image?: File;
}

const UserProfileItemLink: FunctionComponent<UserLinkProps> = ({ link, label, image }) => {
  return (
    <Link to={link} target="_blank" className="d-flex align-items-center mt-1 p-2 mr-2 user-profile-item">
      <div className="pr-2">
        <Image
          file={image}
          alt={label}
          size={"40px"}
          imageClassName={"img-fluid rounded-circle"}
        />
      </div>
      <div className="flex-grow-1">
        {label}
      </div>
      <div className="pr-2 user-profile-item-link-icon">
        <ExternalLink size={18} />
      </div>
    </Link>
  );
}

export default UserProfileItemLink;
