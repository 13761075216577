import React, { FunctionComponent, useState } from 'react';

import { Card, CardBody, CardHeader, CardTitle, Container, Spinner } from 'reactstrap';

import { useApiService } from 'hooks/useApiService';
import { useEffectOnMount } from 'hooks/useEffectOnMount';

import { List } from 'domain/List';

import { SelectOption } from 'services/api/responses';

import HomepageFeaturedAppsList from './HomepageFeaturedAppsList';
import { ListTypeId } from 'constants/listTypes';

export type ListWithMetadata = Omit<List, 'apps'> & {
  apps: SelectOption[];
  wasUpdatedOnce?: boolean;
};

const HomepageFeaturedAppsPage: FunctionComponent = () => {
  const [highlightedList, setHighlightedList] = useState<ListWithMetadata>();
  const [ourchoiceList, setOurchoiceList] = useState<ListWithMetadata>();
  const [bannersList, setBannersList] = useState<ListWithMetadata>();
  const [newAppsList, setNewAppsList] = useState<ListWithMetadata>();
  const [trendingList, setTrendingList] = useState<ListWithMetadata>();

  const [fetcHighlightedList, { isLoading: isFetchingHighlightedList }] = useApiService('list', listService => (
    () => (
      listService.fetchListByIdOrSlug(ListTypeId.HIGHLIGHTED).then(list => {
        const listWithMetadata: ListWithMetadata = {
          ...list,
          apps: list.apps.map(a => ({
            label: `${a.name}${a.status !== 'Published' ? ` (${a.status})` : ''}`,
            value: a.id
          }))
        };
        setHighlightedList(listWithMetadata);
      })
    )
  ));

  const [fetchOurchoiceList, { isLoading: isFetchingOurchoiceList }] = useApiService('list', listService => (
    () => (
      listService.fetchListByIdOrSlug(ListTypeId.OUR_CHOICE).then(list => {
        const listWithMetadata: ListWithMetadata = {
          ...list,
          apps: list.apps.map(a => ({
            label: `${a.name}${a.status !== 'Published' ? ` (${a.status})` : ''}`,
            value: a.id
          }))
        };
        setOurchoiceList(listWithMetadata);
      })
    )
  ));

  const [fetchBannersList, { isLoading: isFetchingBannersList }] = useApiService('list', listService => (
    () => (
      // TODO: replace ourchoice with banners slug
      listService.fetchListByIdOrSlug(ListTypeId.BANNERS).then(list => {
        const listWithMetadata: ListWithMetadata = {
          ...list,
          name: 'Banners',
          slug: 'banners',
          apps: list.apps.map(a => ({
            label: `${a.name}${a.status !== 'Published' ? ` (${a.status})` : ''}`,
            value: a.id
          }))
        };
        setBannersList(listWithMetadata);
      })
    )
  ));


  const [fetchNewAppsList, { isLoading: isFetchingNewAppsList }] = useApiService('list', listService => (
    () => (
      listService.fetchListByIdOrSlug(ListTypeId.NEW_APPS).then(list => {
        const listWithMetadata: ListWithMetadata = {
          ...list,
          name: 'New apps',
          slug: 'new-apps',
          apps: list.apps.map(a => ({
            label: `${a.name}${a.status !== 'Published' ? ` (${a.status})` : ''}`,
            value: a.id
          }))
        };
        setNewAppsList(listWithMetadata);
      })
    )
  ));

  const [fetchTrendingList, { isLoading: isFetchingTrendingList }] = useApiService('list', listService => (
    () => (
      listService.fetchListByIdOrSlug(ListTypeId.TRENDING).then(list => {
        const listWithMetadata: ListWithMetadata = {
          ...list,
          name: 'Trending',
          slug: 'trending',
          apps: list.apps.map(a => ({
            label: `${a.name}${a.status !== 'Published' ? ` (${a.status})` : ''}`,
            value: a.id
          }))
        };
        setTrendingList(listWithMetadata);
      })
    )
  ));

  const getStateBySlug = (slug: string) => {
    const listTypes: Record<string, [ListWithMetadata | undefined, React.Dispatch<React.SetStateAction<ListWithMetadata | undefined>>]> = {
      [ListTypeId.HIGHLIGHTED]: [highlightedList, setHighlightedList],
      [ListTypeId.OUR_CHOICE]: [ourchoiceList, setOurchoiceList],
      [ListTypeId.BANNERS]: [bannersList, setBannersList],
      [ListTypeId.NEW_APPS]: [newAppsList, setNewAppsList],
      [ListTypeId.TRENDING]: [trendingList, setTrendingList],
    }
    return listTypes[slug];
  }

  const updateAppsOnList = (slug: string, apps: SelectOption[]) => {
    const [list, setList] = getStateBySlug(slug)
    setList({ ...list!, apps: apps || [], wasUpdatedOnce: true });
  };

  useEffectOnMount(() => {
    fetcHighlightedList();
    fetchOurchoiceList();
    fetchBannersList();
    fetchNewAppsList();
    fetchTrendingList();
  });

  const isLoading = isFetchingHighlightedList || isFetchingOurchoiceList || isFetchingBannersList || isFetchingNewAppsList || isFetchingTrendingList;

  return (
    <Container className="p-0">
      <Card>
        <CardHeader className="d-flex align-items-center mt-3">
          <CardTitle tag="h2" className="mb-0">Homepage Featured Apps</CardTitle>
          {isLoading &&
            <Spinner color="primary" size="sm" className="ml-2" />}
        </CardHeader>

        <CardBody>
          {highlightedList && (
            <HomepageFeaturedAppsList
              list={highlightedList}
              fetchLists={fetcHighlightedList}
              updateAppsOnList={updateAppsOnList}
              onlyShowAppsWithFeaturedIcon
              minimum={3}
              isSortable
            />
          )}
          {ourchoiceList && (
            <HomepageFeaturedAppsList
              list={ourchoiceList}
              fetchLists={fetchOurchoiceList}
              updateAppsOnList={updateAppsOnList}
              minimum={2}
              maximum={20}
              isSortable
            />
          )}
          {newAppsList && (
            <HomepageFeaturedAppsList
              list={newAppsList}
              fetchLists={fetchNewAppsList}
              updateAppsOnList={updateAppsOnList}
              minimum={2}
              maximum={20}
              isSortable
            />
          )}
          {trendingList && (
            <HomepageFeaturedAppsList
              list={trendingList}
              fetchLists={fetchTrendingList}
              updateAppsOnList={updateAppsOnList}
              minimum={2}
              maximum={20}
              isSortable
            />
          )}
          {bannersList && (
            <HomepageFeaturedAppsList
              list={bannersList}
              fetchLists={fetchBannersList}
              updateAppsOnList={updateAppsOnList}
              minimum={2}
              maximum={20}
              isSortable
            />
          )}

        </CardBody>
      </Card>
    </Container>
  );
};

export default HomepageFeaturedAppsPage;
