import { combineReducers } from "redux";

import { reducer as toastr } from "react-redux-toastr";

import user from "./userReducer";
import sidebar from "./sidebarReducer";
import listingPageTableStates from "./listingPageTableStatesReducer";

const reducers = {
  user,
  sidebar,
  listingPageTableStates,
  toastr,
};

export const persistedStatePaths: (keyof typeof reducers)[] = [
  'user',
  'sidebar',
];

export const rootReducer = combineReducers(reducers);
