import React, { FunctionComponent, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { Col, Row } from 'reactstrap';

import { AvField, AvForm } from 'availity-reactstrap-validation';

import { useSafeRedirect } from '../../hooks/useSafeRedirect';
import { useApiService } from '../../hooks/useApiService';
import { useEffectOnMount } from '../../hooks/useEffectOnMount';

import CrudForm from '../../components/crud/CrudForm';
import CrudFormButtonBar from '../../components/crud/CrudFormButtonBar';

import { Category } from '../../domain/Category';
import { FrontendAppPage, joinPagesPaths } from '../../constants/frontendAppPages';

type CategoryForm = {
  name: string;
  description: string;
};

const CategoryFormPage: FunctionComponent = () => {
  const {id} = useParams();
  const redirectTo = useSafeRedirect();

  const isUpdatingCategory = Boolean(id);

  const title = isUpdatingCategory ? 'Update category' : 'Create category';

  const avForm = useRef<{ reset: Function }>(); // TODO: use AvForm type
  const [oldCategoryData, setOldCategoryData] = useState<CategoryForm>();

  // Fetch category logic

  const [fetchCategoryById, {isLoading: isFetchingCategory}] = useApiService('category', categoryService => (
    (categoryId: number) => (
      categoryService.fetchCategoryById(categoryId).then(resetFormWithCategoryData)
    ))
  );

  const [revalidate] = useApiService('revalidation', revalidationService => (
    (paths: string) => revalidationService.revalidate(paths))
  );


  useEffectOnMount(() => {
    if (id) {
      fetchCategoryById(id);
    }
  });

  const resetFormWithCategoryData = (category: Category) => {
    const categoryForm = {
      ...category,
    };
    setOldCategoryData(categoryForm);
    avForm.current!.reset(); // we need to reset the form so AvForm will accept the data we pass into its 'model' prop
  };

  // Save category logic

  const [saveCategory, {isLoading: isSaving}] = useApiService('category', categoryService => (
    (categoryForm: CategoryForm) => {
      return isUpdatingCategory
        ? categoryService.updateCategory(id, categoryForm).then((updatedCategory: any) => {
          const categorySlug = updatedCategory.data.slug;
          const pathsToRevalidate = joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS, FrontendAppPage.CATEGORIES, FrontendAppPage.SINGLE_CATEGORY(categorySlug)]);
          revalidate(pathsToRevalidate);
          displaySuccessMessageAndGoBack();
        })
        : categoryService.createCategory(categoryForm).then((createdCategory: any) => {
          const categorySlug = createdCategory.data.slug;
          const pathsToRevalidate = joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS, FrontendAppPage.CATEGORIES, FrontendAppPage.SINGLE_CATEGORY(categorySlug)]);
          revalidate(pathsToRevalidate);
          displaySuccessMessageAndGoBack();
        });
    })
  );

  const displaySuccessMessageAndGoBack = () => {
    toastr.success('Success', `Category ${id ? 'updated' : 'created'} successfully`);
    redirectTo('/apps/categories');
  };

  const onSubmit = (_: React.FormEvent<HTMLFormElement>, categoryForm: CategoryForm) => {
    saveCategory(categoryForm);
  };

  const isBusy = isFetchingCategory || isSaving;

  return (
    <CrudForm title={title} showSpinner={isFetchingCategory}>
      <AvForm onValidSubmit={onSubmit} ref={avForm} model={oldCategoryData}>

        <Row form>
          <Col md="12" lg="3">
            <AvField label="Name" name="name" type="text" validate={{
              required: {value: true, errorMessage: 'The name is required'},
              maxLength: {value: 255, errorMessage: 'The name must be less than 255 characters'},
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12" lg="5">
            <AvField label="Description" name="description" type="textarea" rows="5" validate={{
              maxLength: {value: 65536, errorMessage: 'The description must be less than 65536 characters'},
            }} />
          </Col>
        </Row>

        <CrudFormButtonBar
          isSaveButtonDisabled={isBusy}
          isCancelButtonDisabled={isBusy}
          saveButtonLabel={isSaving ? 'Saving...' : 'Save'}
          cancelButtonRoutePath={'/apps/categories'}
        />
      </AvForm>
    </CrudForm>
  );
};

export default CategoryFormPage;
