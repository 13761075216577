import React, { FunctionComponent, useState, useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Edit as EditIcon, Trash2 as TrashIcon, Award as AwardIcon } from "react-feather";
import { toastr } from "react-redux-toastr";

import moment from 'moment';

import CrudList, { CrudTableState, initialTableState } from "../../components/crud/CrudList";
import { CrudTableColumnBuilder } from "../../components/crud/CrudTableColumnBuilder";

import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import { PagedRequestDTO } from "../../services/api/requests";

import ConfirmationModal from "../../components/ConfirmationModal";

import { List, ListType } from "../../domain/List";
import { FrontendAppPage, joinPagesPaths } from '../../constants/frontendAppPages';

type ListTableRow = {
  id: number;
  name: string;
  typeLabel: string;
  typeValue: string;
  updated_at: string;
};

const MyAppsFeaturedAppsPage: FunctionComponent = () => {

  // Lists table

  const [lists, setLists] = useState<ListTableRow[]>([]);
  const [tableState, setTableState] = useState<CrudTableState<ListTableRow>>(initialTableState);

  const { url } = useRouteMatch();

  const columns = CrudTableColumnBuilder(tableState)
    .addIndexColumn()
    .addColumn({ text: 'Name', dataField: 'name', sort: true, })
    .addColumn({ text: 'Type', dataField: 'typeLabel', sort: true, headerStyle: { width: '250px' } })
    .addColumn({ text: 'Updated at', dataField: 'updated_at', sort: true, headerStyle: { width: '250px' } })
    .addActionsColumn((list: ListTableRow) => (
      // TODO: convert to an "addActionColumn" sequence where we pass only the path?: string, icon: Icon, and onClick?: Fn
      <div>
        <Link to="#" onClick={() => updateListToHybridTypeModalRef.current!.open(list)} style={{ visibility: list.typeValue === ListType.MY_APPS ? 'visible' : 'hidden' }}>
          <AwardIcon size={18} className="mr-2"></AwardIcon>
        </Link>
        <Link to={`${url}/update/${list.id}`}>
          <EditIcon size={18} className="mr-1"></EditIcon>
        </Link>
        <Link to="#" onClick={() => deleteListModalRef.current!.open(list)}>
          <TrashIcon size={18} className="ml-1"></TrashIcon>
        </Link>
      </div>
    ), '110px')
    .getColumns();

  // Fetch lists logic

  const [fetchLists, { isLoading: isFetchingLists }] = useApiService('list', listService => (
    (tableState: CrudTableState<ListTableRow>) => {
      const pagedRequestDTO = mapTableStateToPagedRequest(tableState);
      return listService.fetchListsPage(pagedRequestDTO).then(listsPage => {
        const totalSize = listsPage.total;

        setLists(listsPage.data.map(mapListToListTableRow));
        setTableState({ ...tableState, totalSize });
      });
    })
  );

  const [revalidate] = useApiService('revalidation', revalidationService => (
    (paths: string) => revalidationService.revalidate(paths))
  );

  const mapListToListTableRow = (list: List) => ({
    id: list.id,
    name: list.name,
    typeLabel: list.type === ListType.HYBRID ? 'Also shown on Homepage' : 'Shown only on My Apps page',
    typeValue: list.type,
    updated_at: moment(list.updated_at).format('MMMM Do YYYY, h:mm:ss a'),
  } as ListTableRow);

  const mapTableStateToPagedRequest = (tableState: CrudTableState<ListTableRow>) => {
    const pagedRequestDTO: PagedRequestDTO<List> = {
      page: tableState.page,
      limit: tableState.sizePerPage,
      filter: [{ field: 'type', operator: 'in', value: [ListType.HYBRID, ListType.MY_APPS] }],
    };
    if (tableState.filter) {
      pagedRequestDTO.filter!.push({ field: 'name', operator: 'like', value: tableState.filter });
    }
    if (tableState.sortField && tableState.sortOrder) {
      pagedRequestDTO.sortOptions = [{ field: tableState.sortField, order: tableState.sortOrder }];
    }
    return pagedRequestDTO;
  };

  const onTableChange = (tableState: CrudTableState<ListTableRow>) => fetchLists(tableState);
  useEffectOnMount(() => fetchLists(tableState));

  // Delete list logic

  const deleteListModalRef = useRef<ConfirmationModal>(null);

  const [deleteList, { isLoading: isDeletingList }] = useApiService('list', listService => (
    (list: ListTableRow) => (
      listService.deleteList(list.id).then(() => {
        const pathsToRevalidate = joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS])
        revalidate(pathsToRevalidate);
        displayListDeletedSuccessMessage()
      })
    )
  ));

  const displayListDeletedSuccessMessage = () => {
    toastr.success('Success', 'List deleted successfully');
    deleteListModalRef.current!.hide();
    fetchLists(tableState);
  };

  // Update list to hybrid type logic

  const updateListToHybridTypeModalRef = useRef<ConfirmationModal>(null);

  const [updateListToHybridType, { isLoading: isUpdatingListToHybridType }] = useApiService('list', listService => (
    (list: ListTableRow) => (
      listService.updateList(list.id, { type: ListType.HYBRID }).then(() => {
        const pathsToRevalidate = joinPagesPaths([FrontendAppPage.HOME, FrontendAppPage.ADS_SAMPLE_PAGE, FrontendAppPage.APPS])
        revalidate(pathsToRevalidate);
        displayListUpdatedSuccessMessage()
      })
    )
  ));

  const displayListUpdatedSuccessMessage = () => {
    toastr.success('Success', 'List updated successfully');
    updateListToHybridTypeModalRef.current!.hide();
    fetchLists(tableState);
  };

  return (
    <React.Fragment>
      <CrudList
        title="My Apps Featured Apps"
        addButtonLinkPath="/create"
        addButtonLabel="Add new list"
        tableData={lists}
        tableColumns={columns}
        tableIsLoading={isFetchingLists}
        onTableChange={onTableChange}
        tableState={tableState}
        reduxSyncTableStateKey="myapps-featured"
        useSimpleFilter
        simpleFilterPlaceholder="Filter by name..."
      />

      <ConfirmationModal
        ref={deleteListModalRef}
        title="Delete list"
        modalBody={(list: ListTableRow) => `Do you really want to delete list "${list?.name}"?`}
        confirmButtonColor="danger"
        confirmButtonDisabled={isDeletingList}
        confirmButtonLabel={isDeletingList ? 'Deleting list...' : 'Delete list'}
        cancelButtonDisabled={isDeletingList}
        onConfirm={(list: ListTableRow) => deleteList(list)}
      />

      <ConfirmationModal
        ref={updateListToHybridTypeModalRef}
        title="Update list"
        modalBody={(list: ListTableRow) => `Do you really want to update list "${list?.name}" to show apps on Homepage? Remember that only one list can be on this state.`}
        confirmButtonDisabled={isUpdatingListToHybridType}
        confirmButtonLabel={isUpdatingListToHybridType ? 'Updating list...' : 'Update list'}
        cancelButtonDisabled={isUpdatingListToHybridType}
        onConfirm={(list: ListTableRow) => updateListToHybridType(list)}
      />
    </React.Fragment>
  );
}

export default MyAppsFeaturedAppsPage;
