import { ListingPageTableStatesState } from "../types";
import { ListingPageTableStateActions, ListingPageTableStatesActionTypes } from "../actions/listingPageTableStatesActions";

const initialState: ListingPageTableStatesState = { };

export default function reducer(state = initialState, action: ListingPageTableStateActions): ListingPageTableStatesState {
  switch (action.type) {
    case ListingPageTableStatesActionTypes.UPDATE_TABLE_STATE:
      return { ...state, [action.payload.key]: action.payload.tableState };
    case ListingPageTableStatesActionTypes.CLEAR_OTHER_TABLE_STATES:
      return { [action.payload.key]: state[action.payload.key] };
    default:
      return state;
  }
}
