import { AxiosInstance } from "axios";
import { PagedRequestDTO, createRequestQuery, PagedRequestSortOption } from "../requests";
import { Category } from "../../../domain/Category";
import { PagedResponseDTO, SelectOption } from "../responses";

type SaveCategoryRequestDTO = Pick<Category, 'name' | 'description'>;

export class CategoryService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchCategoriesSelectOptions(): Promise<SelectOption[]> {
    const fields: (keyof Category)[] = [
      'id',
      'name',
      'slug'
    ];

    const page = 1;
    const limit = 1000;

    const sortOptions: PagedRequestSortOption<Category>[] = [{ field: 'name', order: 'asc' }];

    const requestQuery = createRequestQuery({ fields, page, limit, sortOptions });

    return this.axios.get<PagedResponseDTO<Category>>(`/category?${requestQuery}`).then(res => res.data.data.map(c => ({ label: c.name, value: c.id, slug: c.slug })));
  }

  fetchCategoriesPage(pagedRequestDTO: PagedRequestDTO<Category>) {
    const fields: (keyof Category)[] = [
      'id',
      'name',
      'updated_at',
      'slug'
    ];

    if (!pagedRequestDTO.sortOptions) {
      pagedRequestDTO.sortOptions = [{ field: 'name', order: 'asc' }];
    }

    const requestQuery = createRequestQuery({ ...pagedRequestDTO, fields });

    return this.axios.get<PagedResponseDTO<Category>>(`/category?${requestQuery}`).then(res => res.data);
  }

  fetchCategoryById(categoryId: number) {
    const fields: (keyof Category)[] = [
      'id',
      'name',
      'description',
    ];

    const requestQuery = createRequestQuery({ fields });

    return this.axios.get<Category>(`/category/${categoryId}?${requestQuery}`).then(res => res.data);
  }

  createCategory(category: SaveCategoryRequestDTO) {
    return this.axios.post('/category', category);
  }

  updateCategory(categoryId: number, category: SaveCategoryRequestDTO) {
    return this.axios.patch(`/category/${categoryId}`, category);
  }

  deleteCategory(categoryId: number) {
    return this.axios.delete(`/category/${categoryId}`);
  }

}

