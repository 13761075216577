import React, { FunctionComponent, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Row,
  Col,
  Label,
  Container,
} from "reactstrap";

import ImageUploader from "react-images-upload";

import { toastr } from "react-redux-toastr";

import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";

import { useApiService } from "../../hooks/useApiService";
import { useSafeRedirect } from "../../hooks/useSafeRedirect";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import { User } from "../../domain/User";
import { Role } from "../../domain/Role";

import { UpdateUserRequestDTO } from "../../services/api/domain/UserService";

import { diffObject } from "../../helpers/diffObject";

import CrudForm from "../../components/crud/CrudForm";
import CrudFormButtonBar from "../../components/crud/CrudFormButtonBar";

import UserProfile from "./UserProfile";

type UserForm = {
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  password: string;
  description: string;
  role: string;
  send_welcome_email: boolean;
};

const UserFormPage: FunctionComponent = () => {
  const { id } = useParams();
  const redirectTo = useSafeRedirect();

  const isUpdatingUser = Boolean(id);

  const title = isUpdatingUser ? 'Update user' : 'Create user';

  const avForm = useRef<{ reset: Function }>(); // TODO: use AvForm type
  const [oldUserData, setOldUserData] = useState<User>();
  const [oldUserFormData, setOldUserFormData] = useState<UserForm>();

  // User avatar logic

  const [avatar, setAvatar] = useState<File>();

  const onDrop = (files: File[]) => {
    const avatar = files[0];
    setAvatar(avatar);
  };

  // Fetch user logic

  const [roles, setRoles] = useState<Role[]>([]);

  const [fetchUserById, { isLoading: isFetchingUser }] = useApiService('user', userService => (
    (userId: number) => (
      userService.fetchUserById(userId).then(resetFormWithUserData)
    ))
  );

  const [fetchRoles, { isLoading: isFetchingRoles }] = useApiService('role', roleService => (
    () => (
      roleService.fetchRoles().then(roles => setRoles(roles))
    ))
  );

  useEffectOnMount(() => {
    if (id) {
      fetchUserById(id);
    }
    fetchRoles();
  });

  const resetFormWithUserData = (user: User) => {
    const userForm = {
      ...user,
      role: String(user.role.id),
      password: '',
      send_welcome_email: false,
    };
    setOldUserData(user);
    setOldUserFormData(userForm);
    avForm.current!.reset(); // we need to reset the form so AvForm will accept the data we pass into its 'model' prop
  };

  // Save user logic

  const [saveUser, { isLoading: isSaving }] = useApiService('user', userService => (
    (userForm: UserForm) => {
      if (!isUpdatingUser) {
        return userService.createUser({ ...userForm, avatar }).then(displaySuccessMessageAndGoBack);
      }

      const changedUserData = diffObject<UpdateUserRequestDTO>(oldUserFormData!, userForm);
      if (avatar) {
        changedUserData.avatar = avatar;
      }

      const hasAnyChange = Object.keys(changedUserData).length;

      if (!hasAnyChange) {
        return new Promise(resolve => {
          displaySuccessMessageAndGoBack();
          resolve();
        });
      }

      return userService.updateUser(id, changedUserData).then(displaySuccessMessageAndGoBack);
    })
  );

  const displaySuccessMessageAndGoBack = () => {
    toastr.success('Success', `User ${id ? 'updated' : 'created'} successfully`);
    redirectTo('/users');
  };

  const onSubmit = (_: React.FormEvent<HTMLFormElement>, userForm: UserForm) => {
    saveUser(userForm);
  };

  const isFetching = isFetchingUser || isFetchingRoles;
  const isBusy = isFetching || isSaving;

  return (
    <Container>
      <Row>
        <Col md="12" lg={isUpdatingUser ? "6" : "12"}>
          <CrudForm title={title} showSpinner={isFetchingUser}>
            <Row>
              <Col md="12" lg={isUpdatingUser ? "12" : "6"}>
                <AvForm onValidSubmit={onSubmit} ref={avForm} model={oldUserFormData}>
                  <Row form>
                    <Col md="12" lg="12">
                      <Label>User avatar</Label>
                      <ImageUploader
                        withIcon={false}
                        withPreview
                        singleImage
                        onChange={onDrop}
                        imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
                        maxFileSize={10485760}
                        buttonText="Choose avatar"
                        label="Max file size: 10mb, accepted: jpg | gif | png"
                        className="circular"
                      />
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="12" lg="6">
                      <AvField label="First name" name="first_name" type="text" validate={{
                        required: { value: true, errorMessage: 'The first name is required' },
                        maxLength: { value: 50, errorMessage: 'The first name must be less than 50 characters' },
                      }} />
                    </Col>
                    <Col md="12" lg="6">
                      <AvField label="Last name" name="last_name" type="text" validate={{
                        required: { value: true, errorMessage: 'The last name is required' },
                        maxLength: { value: 100, errorMessage: 'The last name must be less than 100 characters' },
                      }} />
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="12" lg="6">
                      <AvField label="Email" name="email" type="email" validate={{
                        required: { value: true, errorMessage: 'The email is required' },
                        maxLength: { value: 50, errorMessage: 'The email must be less than 50 characters' },
                        email: { value: true, errorMessage: 'Please enter a valid email' },
                      }} />
                    </Col>
                    <Col md="12" lg="6">
                      <AvField label="Username" name="username" type="text" validate={{
                        required: { value: true, errorMessage: 'The username is required' },
                        maxLength: { value: 50, errorMessage: 'The username must be less than 50 characters' },
                      }} />
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="12" lg="6">
                      <AvField label="Password" name="password" type="password" required={!isUpdatingUser} errorMessage="The password is required" />
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="12" lg="12">
                      <AvField label="Description" name="description" type="textarea" rows="5" validate={{
                        maxLength: { value: 7800, errorMessage: 'The description must be less than 7800 characters' },
                      }} />
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="12" lg="6">
                      <AvField type="select" name="role" label="Role" required errorMessage="The role is required">
                        <option value="">Pick a role...</option>
                        {roles.map(role => (
                          <option key={role.id} value={role.id}>{role.name}</option>
                        ))}
                      </AvField>
                    </Col>
                  </Row>

                  {!isUpdatingUser && (
                    <Row form className="mt-3">
                      <Col md="12" lg="8">
                        <AvGroup check>
                          <Label check>
                            <AvInput type="checkbox" name="send_welcome_email" /> Send welcome email?
                          </Label>
                        </AvGroup>
                      </Col>
                    </Row>
                  )}

                  <CrudFormButtonBar
                    isSaveButtonDisabled={isBusy}
                    isCancelButtonDisabled={isBusy}
                    saveButtonLabel={isSaving ? 'Saving...' : 'Save'}
                    cancelButtonRoutePath={'/users'}
                  />
                </AvForm>
              </Col>
            </Row>
          </CrudForm>
        </Col>
        {isUpdatingUser && (
          <Col md="12" lg="6">
            <UserProfile user={oldUserData} isFetchingUser={isFetchingUser} />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default UserFormPage;