import React, { useEffect, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Navbar,
  UncontrolledDropdown,
  Collapse,
  Nav,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  Settings as SettingsIcon,
  LogOut as LogOutIcon,
} from "react-feather";

import classNames from "classnames";

import { toggleSidebar } from "../../redux/actions/sidebarActions";
import { selectUser, selectIsUserAuthenticated } from "../../redux/selectors";
import { userSignOut, userFetchAvatar } from "../../redux/actions/userActions";

import { useApiService } from "../../hooks/useApiService";

import Image from "../../components/Image";

const AppNavbar: FunctionComponent = () => {
  const user = useSelector(selectUser);
  const userId = user?.id;
  const userAvatar = user?.avatar;
  const isUserAuthenticated = useSelector(selectIsUserAuthenticated);

  const dispatch = useDispatch();

  const history = useHistory();

  const [fetchUserById] = useApiService('user', userService => (
    (userId: number) => userService.fetchUserById(userId).then(user => dispatch(userFetchAvatar(user.avatar))))
  );

  useEffect(() => {
    if (!isUserAuthenticated) {
      history.push('/auth/sign-in');
    }
  // eslint-disable-next-line
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (user && userId && !userAvatar) {
      fetchUserById(userId);
    }
    // eslint-disable-next-line
  }, [userId, userAvatar]);

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => dispatch(toggleSidebar())}
      >
        <i className="hamburger align-self-center"></i>
      </span>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>

          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <SettingsIcon size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <Image
                  file={user.avatar}
                  alt={user.name}
                  size={"40px"}
                  imageClassName={classNames("avatar img-fluid rounded-circle mr-2", { "navbar-avatar-default-image": !Boolean(user.avatar) })}
                />
                <span className="text-dark">{user.name}</span>
              </DropdownToggle>
            </span>

            <DropdownMenu right>
              <DropdownItem onClick={() => dispatch(userSignOut())}>
                <LogOutIcon size={18} className="align-middle mr-2" />
                Sign out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

        </Nav>
      </Collapse>

    </Navbar>
  );
};

export default AppNavbar;
