import { ComponentType } from "react";

import CenteredLayout from '../layouts/CenteredLayout';
import AdminLayout from '../layouts/AdminLayout';

import HomePage from '../pages/HomePage';
import SignInPage from "../pages/auth/SignInPage";
import RequestNewPasswordPage from "../pages/auth/RequestNewPasswordPage";
import RecoverPasswordPage from "../pages/auth/RecoverPasswordPage";
import Page404 from "../pages/Page404";

import UsersListPage from '../pages/users/UsersListPage';
import UserFormPage from "../pages/users/UserFormPage";
import AppsListPage from "../pages/apps/AppsListPage";
import AppsFormPage from "../pages/apps/AppsFormPage";
import CategoriesListPage from "../pages/categories/CategoriesListPage";
import CategoryFormPage from "../pages/categories/CategoryFormPage";
import TagsListPage from "../pages/tags/TagsListPage";
import TagFormPage from "../pages/tags/TagFormPage";
import HomepageFeaturedAppsPage from "../pages/featured-apps/HomepageFeaturedAppsPage";
import MyAppsFeaturedAppsPage from "../pages/featured-apps/MyAppsFeaturedAppsPage";
import MyAppsFeaturedAppsFormPage from "../pages/featured-apps/MyAppsFeaturedAppsFormPage";
import CustomPageListPage from "../pages/custom-page/CustomPageListPage";
import CustomPageFormPage from "../pages/custom-page/CustomPageFormPage";
import ImportDataListPage from "../pages/import-data/ImportDataListPage";
import ImportDataDetailsPage from "../pages/import-data/ImportDataDetailsPage";
import ExportDataPage from "../pages/export-data/ExportDataPage";
import CampaignsListPage from "../pages/campaigns/CampaignsListPage";
import NewsletterPage from "../pages/newsletter/NewsletterPage"

const publicRoutes: AppRoute[] = [
  {
    path: '/auth/sign-in',
    component: SignInPage,
  },
  {
    path: '/auth/reset-password',
    component: RequestNewPasswordPage,
  },
  {
    path: '/auth/recovery',
    component: RecoverPasswordPage,
  },
  {
    path: '/404',
    component: Page404,
    layout: CenteredLayout,
  },
];

const protectedRoutes: AppRoute[] = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/users',
    component: UsersListPage,
    children: [
      {
        path: '/users/create',
        component: UserFormPage,
      },
      {
        path: '/users/update/:id',
        component: UserFormPage,
      },
    ],
  },
  {
    path: '/apps',
    component: AppsListPage,
    children: [
      {
        path: '/apps/create',
        component: AppsFormPage,
      },
      {
        path: '/apps/update/:id',
        component: AppsFormPage,
      },
      {
        path: '/apps/categories',
        component: CategoriesListPage,
        children: [
          {
            path: '/apps/categories/create',
            component: CategoryFormPage,
          },
          {
            path: '/apps/categories/update/:id',
            component: CategoryFormPage,
          },
        ],
      },
      {
        path: '/apps/tags',
        component: TagsListPage,
        children: [
          {
            path: '/apps/tags/create',
            component: TagFormPage,
          },
          {
            path: '/apps/tags/update/:id',
            component: TagFormPage,
          },
        ],
      },
      {
        path: '/apps/homepage-featured',
        component: HomepageFeaturedAppsPage,
      },
      {
        path: '/apps/myapps-featured',
        component: MyAppsFeaturedAppsPage,
        children: [
          {
            path: '/apps/myapps-featured/create',
            component: MyAppsFeaturedAppsFormPage,
          },
          {
            path: '/apps/myapps-featured/update/:id',
            component: MyAppsFeaturedAppsFormPage,
          },
        ],
      },
    ],
  },
  {
    path: '/custom-page',
    component: CustomPageListPage,
    children: [
      {
        path: '/custom-page/create',
        component: CustomPageFormPage,
      },
      {
        path: '/custom-page/update/:id',
        component: CustomPageFormPage,
      },
    ]
  },
  {
    path: '/import-data',
    component: ImportDataListPage,
    children: [
      {
        path: '/import-data/details/:id',
        component: ImportDataDetailsPage,
      },
    ],
  },
  {
    path: '/export-data',
    component: ExportDataPage
  },
  {
    path: '/campaigns',
    component: CampaignsListPage
  },
  {
    path: '/newsletter',
    component: NewsletterPage
  }
];

export type AppRoute = {
  path: string;
  component: ComponentType;
  layout?: ComponentType;
  children?: AppRoute[];
};

export type RoutesWithLayout = {
  layout: ComponentType;
  routes: AppRoute[];
};

export type RouteConfig = {
  public: RoutesWithLayout;
  protected: RoutesWithLayout;
};

const routeConfig: RouteConfig = {
  public: {
    layout: CenteredLayout,
    routes: publicRoutes,
  },
  protected: {
    layout: AdminLayout,
    routes: protectedRoutes,
  },
};

export default routeConfig;
