import React, { FunctionComponent } from "react";
import ContentLoader from "react-content-loader";

type ListContentLoaderProps = {
  quantity?: number;
  height?: number;
  padding?: number;
};

const ListContentLoader: FunctionComponent<ListContentLoaderProps> = ({
  quantity = 3,
  height = 45,
  padding = 15,
}) => {
  return (
    <ContentLoader width="100%" height={quantity * (height + padding)}>
      {[...new Array(quantity)].map((_, index) => (
        <rect key={index} width="100%" height={height} x="0" y={padding + index * (height + padding)} rx="4" ry="4"></rect>
      ))}
    </ContentLoader>
  );
}

export default ListContentLoader;
