import React, { FunctionComponent, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import ReactQuill from "react-quill";

import { Row, Col, FormGroup, Label } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { useSafeRedirect } from "../../hooks/useSafeRedirect";
import { useApiService } from "../../hooks/useApiService";
import { useEffectOnMount } from "../../hooks/useEffectOnMount";

import CrudForm from "../../components/crud/CrudForm";
import CrudFormButtonBar from "../../components/crud/CrudFormButtonBar";

import { CustomPage } from "../../domain/CustomPage";
import { PageContentMapper } from '../../constants/frontendAppPages';

type CustomPageForm = {
  title: string;
  content: string;
};

const CustomPageFormPage: FunctionComponent = () => {
  const { id } = useParams();
  const [revalidate] = useApiService('revalidation', revalidationService => (
    (paths: string) => revalidationService.revalidate(paths))
  )
  const redirectTo = useSafeRedirect();

  const isUpdatingCustomPage = Boolean(id);

  const title = isUpdatingCustomPage ? 'Update custom page' : 'Create custom page';

  const avForm = useRef<{ reset: Function }>(); // TODO: use AvForm type
  const [oldCustomPageData, setOldCustomPageData] = useState<CustomPageForm>();
  const [customPageContent, setCustomPageContent] = useState('');

  // Fetch custom page logic

  const [fetchCustomPageById, { isLoading: isFetchingCustomPage }] = useApiService('customPage', customPageService => (
    (customPageId: number) => (
      customPageService.fetchCustomPageById(customPageId).then(resetFormWithCustomPageData)
    ))
  );

  useEffectOnMount(() => {
    if (id) {
      fetchCustomPageById(id);
    }
  });

  const resetFormWithCustomPageData = (customPage: CustomPage) => {
    const customPageForm = {
      ...customPage,
    };
    setCustomPageContent(customPageForm.content);
    setOldCustomPageData(customPageForm);
    avForm.current!.reset(); // we need to reset the form so AvForm will accept the data we pass into its 'model' prop
  };

  // Save custom page logic

  const [saveCustomPage, { isLoading: isSaving }] = useApiService('customPage', customPageService => (
    (customPageForm: CustomPageForm) => {
      return isUpdatingCustomPage
        ? customPageService.updateCustomPage(id, customPageForm).then(() => {
          revalidate(PageContentMapper[id]);
          displaySuccessMessageAndGoBack();
        })
        : customPageService.createCustomPage(customPageForm).then(displaySuccessMessageAndGoBack);
    })
  );

  const displaySuccessMessageAndGoBack = () => {
    toastr.success('Success', `Custom page ${id ? 'updated' : 'created'} successfully`);
    redirectTo('/custom-page');
  };

  const onSubmit = (_: React.FormEvent<HTMLFormElement>, customPageForm: CustomPageForm) => {
    customPageForm = { ...customPageForm, content: customPageContent };
    saveCustomPage(customPageForm);
  };

  const isBusy = isFetchingCustomPage || isSaving;

  return (
    <CrudForm title={title} showSpinner={isFetchingCustomPage}>
      <AvForm onValidSubmit={onSubmit} ref={avForm} model={oldCustomPageData}>

        <Row form>
          <Col md="12" lg="7">
            <AvField label="Title" name="title" type="text" disabled={isUpdatingCustomPage} helpMessage={isUpdatingCustomPage && "You can't change a custom page's title"} validate={{
              required: { value: true, errorMessage: 'The title is required' },
              maxLength: { value: 512, errorMessage: 'The title must be less than 512 characters' },
            }} />
          </Col>
        </Row>

        <Row form>
          <Col md="12">
            <FormGroup>
              <Label>Content</Label>
              <ReactQuill value={customPageContent} onChange={setCustomPageContent} />
            </FormGroup>
          </Col>
        </Row>

        <CrudFormButtonBar
          isSaveButtonDisabled={isBusy}
          isCancelButtonDisabled={isBusy}
          saveButtonLabel={isSaving ? 'Saving...' : 'Save'}
          cancelButtonRoutePath={'/custom-page'}
        />
      </AvForm>
    </CrudForm>
  );
}

export default CustomPageFormPage;
