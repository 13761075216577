import React, { useState, FunctionComponent, MouseEventHandler } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import classNames from "classnames";

import { Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Icon } from "react-feather";

import { selectSidebar } from "../../redux/selectors";

import { useLocationWithPreviousRoute } from "../../hooks/useLocationWithPreviousRoute";

import sidebarConfig from "../../sidebar/sidebarConfig";

type SidebarCategoryProps = {
  name: string;
  icon?: Icon;
  to: string;
  isOpen: boolean;
  onClick: MouseEventHandler;
};

const SidebarCategory: FunctionComponent<SidebarCategoryProps> = ({ name, icon: Icon, to, children, isOpen, onClick }) => {
  const location = useLocation();
  return (
    <li className={classNames({ 'sidebar-item': true, active: location.pathname.indexOf(to) !== -1 })}>
      {/* TODO: use NavLink so clicking on category will also redirect to the associated page. To do this, we'll need to resolve the Sidebar re-rendering between route changes issue */}
      {/* <NavLink to={to} */}
      <span
        className={classNames({ 'sidebar-link': true, collapsed: !isOpen })}
        onClick={onClick}
        data-toggle="collapse"
        aria-expanded={isOpen ? "true" : "false"}
      >
        {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
        <span className="align-middle">{name}</span>
      </span>
      {/* {</NavLink>} */}
      <Collapse isOpen={isOpen}>
        <ul className="sidebar-dropdown list-unstyled">
          {children}
        </ul>
      </Collapse>
    </li>
  );
};


type SidebarItemProps = {
  name: string;
  icon?: Icon;
  to: string;
};

const SidebarItem: FunctionComponent<SidebarItemProps> = ({ name, icon: Icon, to }) => {
  const location = useLocation();
  return (
    <li className={classNames({ 'sidebar-item': true, active: location.pathname === to })}>
      <NavLink to={to} className="sidebar-link" activeClassName="active">
        {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
        <span className="align-middle">{name}</span>
      </NavLink>
    </li>
  );
};


const Sidebar = () => {
  const sidebar = useSelector(selectSidebar);

  const location = useLocationWithPreviousRoute();
  const [openCategoryIndex, setOpenCategoryIndex] = useState(() => {
    const initialState = sidebarConfig.findIndex(r => r.children && location.pathname.indexOf(r.path || '') === 0);
    return initialState === -1 ? undefined : initialState;
  });

  const toggleOpenCategoryIndex = (index: number) => {
    const isCategoryOpen = index === openCategoryIndex;
    setOpenCategoryIndex(isCategoryOpen ? undefined : index);
  };

  return (
    <nav className={classNames({ sidebar: true, toggled: !sidebar.isOpen })}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img src="/img/weshareapps-icon.png" width="24px" height="24px" alt="WeShareApps CMS" />
            <span className="align-middle">WeShareApps CMS</span>
          </a>

          <ul className="sidebar-nav">
            {sidebarConfig.map((sidebarItem, index) => {
              return (
                <React.Fragment key={index}>
                  {sidebarItem.children ? (
                    <SidebarCategory
                      name={sidebarItem.name}
                      icon={sidebarItem.icon}
                      to={sidebarItem.path}
                      isOpen={openCategoryIndex === index}
                      onClick={() => toggleOpenCategoryIndex(index)}
                    >
                      {sidebarItem.children.map((sidebarChildItem, index) => (
                        <SidebarItem
                          key={index}
                          name={sidebarChildItem.name}
                          icon={sidebarChildItem.icon}
                          to={sidebarChildItem.path}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={sidebarItem.name}
                      icon={sidebarItem.icon}
                      to={sidebarItem.path}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
