import React, { FunctionComponent, useState } from "react";

import classNames from 'classnames';

import { Button, Card, CardBody } from "reactstrap";

import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation";

import { useApiService } from "../../hooks/useApiService";

import { RequestNewPasswordDTO } from "../../services/api/auth/authRequestTypes";

type ResetPasswordForm = {
  email: string;
};

const RequestNewPasswordPage: FunctionComponent = () => {

  const [ requestedPassword, setRequestedPassword ] = useState(false);

  const [requestNewPassword, { isLoading }] = useApiService('auth', authService => (
    (requestNewPasswordReq: RequestNewPasswordDTO) => (
      authService.requestNewPassword(requestNewPasswordReq).then(() => setRequestedPassword(true))
    ))
  );

  const onSubmit = (_: React.FormEvent<HTMLFormElement>, values: ResetPasswordForm) => {
    const requestNewPasswordReq = { ...values };
    requestNewPassword(requestNewPasswordReq);
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2 className={classNames({ 'mb-3': requestedPassword })}>Password Recovery</h2>
        {!requestedPassword && (<p className="lead">Enter your email below and we'll send you a link with the instructions</p>)}
      </div>

      <Card className={classNames({ 'bg-success text-white': requestedPassword })}>
        <CardBody>
          <div className="m-sm-4">
            {requestedPassword && (
              <p className="lead text-center">
                Password recovery requested successfully! Please check your e-mail inbox and/or spam folder for further instructions.
              </p>
            )}
            {!requestedPassword && (
              <AvForm onValidSubmit={onSubmit}>

                <AvField label="Email" name="email" type="email" bsSize="lg" validate={{
                  required: { value: true, errorMessage: 'The email is required' },
                  email: { value: true, errorMessage: 'Please enter a valid email' },
                }} />

                <div className="text-center mt-3">
                  <Button color="primary" size="lg" disabled={isLoading}>
                    {isLoading ? 'Requesting new password...' : 'Request new password' }
                  </Button>
                </div>
              </AvForm>
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>

  );
};

export default RequestNewPasswordPage;
