import { UserState } from "../types";
import { UserActionTypes, UserActions } from "../actions/userActions";
import jwtDecode from "jwt-decode";

const initialState: UserState = {
  isAuthenticated: false,
};

type AccessTokenClaims = {
  id: number,
  email: string,
  username: string,
};

export default function reducer(state = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.USER_SIGN_IN:
      const accessToken = action.payload.access_token;
      const decodedJwt = jwtDecode<AccessTokenClaims>(accessToken);

      return {
        isAuthenticated: true,
        accessToken: accessToken,
        id: decodedJwt.id,
        name: decodedJwt.username,
      };

    case UserActionTypes.USER_FETCH_AVATAR:
      const avatar = action.payload;
      return {
        ...state,
        avatar
      };

    case UserActionTypes.USER_SIGN_OUT:
      return {
        isAuthenticated: false,
      };

    default:
      return state;
  }
}
