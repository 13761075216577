import React, { useRef, useState } from 'react';
import { Button, ButtonToolbar, CardBody, CardText, Col, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import ConfirmationModal from 'components/ConfirmationModal';
import { useApiService } from 'hooks/useApiService';
import { toastr } from 'react-redux-toastr';
import { StartNewsletterCampaignRequest } from 'services/api/domain/NewsletterService';
import { EMAIL_REGEX } from 'constants/regex';
import { Base64 } from 'js-base64';

type NewsletterForm = {
  subject: string,
  template: string,
  emails: string
}


type NoActiveNewsletterProps = {
  getNewsletterStatus: (...params: never[]) => void
}

const initialFormData = {
  subject: '',
  template: '',
  emails: ''
};

export const NoActiveNewsletter = ({ getNewsletterStatus }: NoActiveNewsletterProps) => {
  const [isSendNewsletterOpen, setCreateNewsletterStatus] = useState(false);
  const createNewsletterFormRef = useRef<{ reset: Function }>();
  const confirmStartNewsletterCampaign = useRef<ConfirmationModal>(null);
  const [formData, setFormData] = useState<NewsletterForm>(initialFormData);
  const [isTestNewsletter, setIsTestNewsletter] = useState(false);
  const [startNewsletterCampaign, { isLoading: isLoadingStartNewsletterCampaign }] = useApiService('newsletter', (newsletterService) => (newsletterCampaignData: StartNewsletterCampaignRequest) => {
    return newsletterService.startNewsletterCampaign(newsletterCampaignData).then(() => {
      if (isTestNewsletter) {
        toastr.success('Success', 'Test emails have been sent!');
      } else {
        getNewsletterStatus();
      }
      confirmStartNewsletterCampaign.current!.hide();
      createNewsletterFormRef.current!.reset();
      setFormData(initialFormData);
    });
  });

  const handleNewCampaignClick = () => {
    setCreateNewsletterStatus(true);
  };

  const handleSendTest = () => {
    setIsTestNewsletter(true);
  };
  const handleSendMails = () => {
    setIsTestNewsletter(false);
  };

  const testEmailsAreValid = (data: NewsletterForm): boolean => {
    if (!data.emails) {
      toastr.error('Error', 'Emails should be specified for the test newsletter');
      return false;
    }
    if (data.emails.split(',').filter(Boolean).some(email => !email.match(EMAIL_REGEX))) {
      toastr.error('Error', 'Some of your test emails are incorrect.');
      return false;
    }
    return true;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>, data: NewsletterForm) => {
    setFormData(data);
    if (isTestNewsletter && !testEmailsAreValid(data)) return;
    confirmStartNewsletterCampaign.current!.open(null);
  };

  const handleConfirm = () => {
    const transformedFromData = {
      subject: formData.subject,
      template: Base64.encode(formData.template),
      ...(formData.emails && { emails: formData.emails.split(',') })
    };
    if (isTestNewsletter) {
      startNewsletterCampaign(transformedFromData);
    } else {
      delete transformedFromData.emails;
      startNewsletterCampaign(transformedFromData);
    }
  }

  return <>
    <CardBody>
      <CardText>No emails are being sent.</CardText>
      <Button className={'d-flex align-items-center mb-4'} color="primary" size="lg" onClick={handleNewCampaignClick}>
        New campaign
      </Button>
      {isSendNewsletterOpen ? (
        <AvForm onValidSubmit={onSubmit} ref={createNewsletterFormRef}>
          <Row form>
            <Col md="12" lg="4">
              <AvField label="Subject" name="subject" type="text" placeholder="The subject of the email" validate={{
                required: { value: true, errorMessage: 'The subject is required' },
              }} />
            </Col>
          </Row>

          <Row form>
            <Col md="12" lg="4">
              <AvField label="Template" name="template" type="textarea" rows="10" validate={{
                required: { value: true, errorMessage: 'The template is required' },
              }} />
            </Col>
          </Row>

          <Row form>
            <Col md="12" lg="4">
              <AvField label="Emails" name="emails" type="text" errorMessage="alo" />
            </Col>
          </Row>
          <Row form className="d-flex align-items-center ">
            <Col>
              <ButtonToolbar>
                <Button color="primary" type="submit" size="lg" className="mr-2"
                        onClick={handleSendTest}>
                  Send test
                </Button>
                <Button color="primary" size="lg" type="submit" onClick={handleSendMails}>
                  Send mails
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>


        </AvForm>
      ) : null}
    </CardBody>

    <ConfirmationModal
      ref={confirmStartNewsletterCampaign}
      title={isTestNewsletter ? 'Start test newsletter campaign' : 'Start newsletter campaign'}
      modalBody={() => isTestNewsletter ? 'Do you really want to start a test newsletter campaign?' : 'Do you really want to start a newsletter campaign?'}
      confirmButtonLabel={'Start'}
      confirmButtonDisabled={isLoadingStartNewsletterCampaign}
      cancelButtonDisabled={isLoadingStartNewsletterCampaign}
      onConfirm={handleConfirm}
    />
  </>;
};
