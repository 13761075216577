import { AxiosInstance } from "axios";
import { PagedRequestDTO, createRequestQuery } from "../requests";
import { PagedResponseDTO } from "../responses";
import { CustomPage } from "../../../domain/CustomPage";

type SaveCustomPageRequestDTO = Pick<CustomPage, 'title' | 'content'>;

export class CustomPageService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchCustomPagesPage(pagedRequestDTO: PagedRequestDTO<CustomPage>) {
    const fields: (keyof CustomPage)[] = [
      'id',
      'title',
      'updated_at',
    ];

    if (!pagedRequestDTO.sortOptions) {
      pagedRequestDTO.sortOptions = [{ field: 'title', order: 'asc' }];
    }

    const requestQuery = createRequestQuery({ ...pagedRequestDTO, fields });

    return this.axios.get<PagedResponseDTO<CustomPage>>(`/page?${requestQuery}`).then(res => res.data);
  }

  fetchCustomPageById(customPageId: number) {
    const fields: (keyof CustomPage)[] = [
      'id',
      'title',
      'content',
    ];

    const requestQuery = createRequestQuery({ fields });

    return this.axios.get<CustomPage>(`/page/${customPageId}?${requestQuery}`).then(res => res.data);
  }

  createCustomPage(customPage: SaveCustomPageRequestDTO) {
    return this.axios.post('/page', customPage);
  }

  updateCustomPage(customPageId: number, customPage: SaveCustomPageRequestDTO) {
    return this.axios.patch(`/page/${customPageId}`, customPage);
  }

  deleteCustomPage(customPageId: number) {
    return this.axios.delete(`/page/${customPageId}`);
  }

}
