import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { AxiosError } from "axios";

import {
  Button,
  Card,
  CardBody,
} from "reactstrap";

import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation";

import { useApiService } from "../../hooks/useApiService";

import { userSignIn } from "../../redux/actions/userActions";

import { SignInRequestDTO } from "../../services/api/auth/authRequestTypes";
import { useSafeRedirect } from "../../hooks/useSafeRedirect";

type SignInForm = {
  email_username: string;
  password: string;
  // rememberMe: boolean; // TODO: wait for backend to implement it
};

const SignInPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const redirectTo = useSafeRedirect();

  const [signIn, { isLoading }] = useApiService('auth', authService => (signInReq: SignInRequestDTO) => (
    authService.signIn(signInReq).then(({ access_token }) => {
      dispatchAccessToken(access_token);
      redirectTo('/');
    }).catch(error => handleError(error))
  ));

  const dispatchAccessToken = (access_token: string) => dispatch(userSignIn({ access_token }));

  const handleError = (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401) {
        toastr.error('Error', 'Wrong email or password');
      } else {
        toastr.error('Error', (error.response.data && error.response.data.message) || 'Unknown error while authenticating user');
      }
    } else {
      toastr.error('Error', 'Unknown error while authenticating user');
    }
  }

  const onSubmit = (_: React.FormEvent<HTMLFormElement>, values: SignInForm) => {
    const signInReq = { ...values };
    signIn(signInReq);
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Welcome back!</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <AvForm onValidSubmit={onSubmit}>

              <AvField label="Email" name="email_username" type="email" bsSize="lg" validate={{
                required: { value: true, errorMessage: 'The email is required' },
                email: { value: true, errorMessage: 'Please enter a valid email' },
              }} />

              <AvField label="Password" name="password" type="password" bsSize="lg"
                required errorMessage="The password is required"
                helpMessage={(
                  <Link to="/auth/reset-password">
                    Forgot your password?
                  </Link>
                )}
              />

              {/*<AvField label="Remember me next time" name="rememberMe" type="checkbox" tag={CustomInput} />*/}

              <div className="text-center mt-3">
                <Button color="primary" size="lg" disabled={isLoading}>
                  {isLoading ? 'Signing in...' : 'Sign in' }
                </Button>
              </div>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
