import React, { FunctionComponent, useState } from 'react';
import { CustomInput, FormText, Label } from 'reactstrap';

import Papa from 'papaparse';

type Props = {
  setFile: Function;
};

const ImportDataSelectCsvFile: FunctionComponent<Props> = ({ setFile }) => {

  const [preview, setPreview] = useState<string>();

  const onChangeFile = (fileEvent: any) => {
    const file = fileEvent.target.files[0];
    parseFile(file);
    setFile(file);
  };

  const parseFile = (file: File) => {
    let preview = '';
    setPreview(preview);
    Papa.parse(file, {
      worker: true,
      preview: 10,
      skipEmptyLines: true,
      step: (results) => {
        preview += results.data.join(',') + '\n';
        setPreview(preview);
      },
      error: (error) => {
        console.log('error', error);
      }
    });
  }

  return (
    <React.Fragment>
      <div>
        <CustomInput type="file" id="import-data-csv-file" name="csvFile" accept=".csv, text/csv" label="Choose CSV File" onChange={onChangeFile} />
        <FormText color="muted">Please select a CSV file</FormText>
      </div>
      {preview && (
        <div className="mt-2">
          <Label>Preview of first 10 rows</Label>
          <pre style={{ border: '1px solid #cacaca', padding: '0.8rem 1rem', maxHeight: '50vh', overflowY: 'scroll', marginBottom: 0 }}>{preview}</pre>
        </div>
      )}
    </React.Fragment>
  )
};

export default ImportDataSelectCsvFile;
