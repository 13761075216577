import { AxiosInstance } from "axios";
import { createRequestQuery, PagedRequestDTO, PagedRequestJoinOption } from "../requests";
import { PagedResponseDTO } from "../responses";

import { App } from "../../../domain/App";

import { RecursivePartial } from "../../../helpers/recursivePartialType";

export type SaveAppRequestDTO = RecursivePartial<Omit<App, 'icon' | 'featuredIcon' | 'screenshots' | 'slug' | 'installed' | 'computed' | 'created_at' | 'updated_at'> & { icon: File, featuredIcon: File, screenshots: File[], featured_icon_removed?: Boolean }>;

export class AppService {

  constructor(
    private axios: AxiosInstance,
  ) { }

  fetchAppsPage(pagedRequestDTO: PagedRequestDTO<App>): Promise<PagedResponseDTO<App>> {
    const fields: (keyof App)[] = [
      'id',
      'name',
      'status',
      'slug',
    ];

    const join: PagedRequestJoinOption<App>[] = [{ relation: 'developer' }, { relation: 'categories', fields: ['slug'] }];

    const requestQuery = createRequestQuery({ ...pagedRequestDTO, fields, join });

    return this.axios.get<PagedResponseDTO<App>>(`/app?${requestQuery}`).then(res => res.data);
  }

  fetchAppStatus(): Promise<string[]> {
    return this.axios.get<string[]>(`/app/status`).then(res => res.data);
  }

  fetchAppById(appId: number): Promise<App> {
    const join: PagedRequestJoinOption<App>[] = [
      { relation: 'developer' },
      { relation: 'categories' },
      { relation: 'tags' },
      { relation: 'screenshots' },
      { relation: 'featuredIcon' },
    ];

    const requestQuery = createRequestQuery({ join });

    return this.axios.get<App>(`/app/${appId}?${requestQuery}`).then(res => res.data);
  }

  createApp(app: SaveAppRequestDTO) {
    const { icon, featuredIcon, screenshots, ...appValues } = app;

    const formData = new FormData();
    Object.entries(appValues).forEach(([ key, value ]) => {
      if (value) {
        if (typeof value === 'object' || Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value as string | File);
        }
      }
    });

    if (icon) {
      formData.append('icon', icon as File);
    }

    if (featuredIcon) {
      formData.append('featuredIcon', featuredIcon as File);
    }

    if (screenshots) {
      screenshots.forEach(s => formData.append('screenshots', s as File));
    }

    return this.axios.post('/app', formData);
  }

  updateApp(appId: number, app: SaveAppRequestDTO) {
    const { icon, featuredIcon, screenshots, ...appValues } = app;

    const formData = new FormData();
    Object.entries(appValues).forEach(([ key, value ]) => {
      if (value) {
        if (typeof value === 'object' || Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value as string | File);
        }
      }
    });

    if (icon) {
      formData.append('icon', icon as File);
    }

    if (featuredIcon) {
      formData.append('featuredIcon', featuredIcon as File);
    }

    if (screenshots) {
      screenshots.forEach(s => formData.append('screenshots', s as File));
    }

    return this.axios.patch(`/app/${appId}`, formData);
  }

  deleteApp(appId: number) {
    return this.axios.delete(`/app/${appId}`);
  }

  startSearchSync() {
    return this.axios.post(`/app/searchSync`, { skip: 0, take: 100, limit: 0 });
  }

}
